import { useLocation } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// routes
// components
// eslint-disable-next-line import/no-unresolved, import/extensions
import Page from '../../components/Page';
// eslint-disable-next-line import/no-unresolved, import/extensions
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
// eslint-disable-next-line import/no-unresolved, import/extensions
import ProfileNewEditForm from '../../sections/@dashboard/profile/ProfileNewEditForm';

// ----------------------------------------------------------------------

export default function EditProfile() {
  // const { themeStretch } = useSettings();

  const { pathname } = useLocation();
  const isEdit = pathname.includes('edit');
  const { state } = useLocation();

  return (
    <Page title="Profile: Edit Profile">
      <Container maxWidth={true ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={'Edit profile'}
          links={[{ name: 'Dashboard', href: '/dashboard' }, { name: 'Edit Profile' }]}
        />

        <ProfileNewEditForm isEdit={isEdit} currentUser={state?.currentUser} />
      </Container>
    </Page>
  );
}
