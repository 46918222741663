import { getToken } from 'firebase/messaging';
import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

// @mui
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
// eslint-disable-next-line import/no-unresolved
import { useDispatch, useSelector } from 'react-redux';
import { setCount } from 'src/redux/actions/reviewCount';
import PrivateRoutes from 'src/routes/private';
import AxiosAll from 'src/services/axiosAll';
import { FetchProjects } from 'src/services/service';
import { messaging } from '../../firebase';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useResponsive from '../../hooks/useResponsive';
import useSettings from '../../hooks/useSettings';
// config
import { HEADER, NAVBAR } from '../../config';
// components
import DashboardHeader from './header';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import NavbarVertical from './navbar/NavbarVertical';
// eslint-disable-next-line import/order
import AdminRoutes from 'src/routes/admin';


// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 0,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout({ role }) {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const location = useLocation();
  const { themeLayout } = useSettings();

  const isDesktop = useResponsive('up', 'lg');
  // get redux state
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const verticalLayout = themeLayout === 'vertical';

  const getTasks = async () => {
    const response = await AxiosAll('get', '/counts', {}, token);
    const { data } = response;
    dispatch(setCount(data));
  };

  const getProjects = async () => {
    const data = await FetchProjects(token);
    setTableData(data);
    console.log('data', data);
  };

  useEffect(() => {
    getTasks();
    getProjects();
  }, []);

  const requestPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: 'BHz7dlP3fbaRRpa3XVcqEqQimEh-qyQgZlI-VoEuJLVDjTgPz1e9pEVKhN8YY-jaqmDzgd2NzwFeBmMAhR78yRU',
      });
      sendFCMToken(token);
    } else if (permission === 'denied') {
      // alert('You denied for the notification');
    }
  };

  const sendFCMToken = async (FCM_Token) => {
    const response = await AxiosAll('post', `/device-token`, { device_token: FCM_Token }, token);
    if (response?.data?.statusCode === 200) {
      // Token sent successfully
    } else {
      console.log('token error', response);
    }
  };

  useEffect(() => {
    requestPermission();
  }, [Notification.permission]);

  if (verticalLayout) {
    return (
      <>
        <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />
        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}
        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />
      {!(
        location?.pathname === '/tasks/minuteofMeeting' || location?.pathname.startsWith('/tasks/minuteofMeeting/')
      ) && <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />}
      <MainStyle collapseClick={collapseClick}>
        {role === 2 ? <PrivateRoutes role={role} /> : <AdminRoutes role={role} />}
      </MainStyle>
    </Box>
  );
}
