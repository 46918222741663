import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { useCallback, useEffect, useState } from 'react';
// @mui
import { Button, Container, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
// components
import { KanbanColumnSkeleton } from 'src/sections/@dashboard/kanban/kanban-skeleton';
// sections
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Iconify from 'src/components/Iconify';
import Page from 'src/components/Page';
import FilterKanbanModal from 'src/components/filterModal/filterKanbanModal';
import { SkeletonKanbanColumn } from 'src/components/skeleton';
import { setCount } from 'src/redux/actions/reviewCount';
import { LogTableToolbar } from 'src/sections/@dashboard/logs/list';
import AxiosAll from 'src/services/axiosAll';
import { KanbanColumn } from '../../sections/@dashboard/kanban';
import { useGetBoard } from './moveData';
import SideAddTask from '../taskCard/AddTaskSideCard';

// ----------------------------------------------------------------------

export default function Kanban() {
  const { userDetails, userList, projectList, priorityList } = useSelector((state) => state.loginReducer);
  const { token, user } = userDetails;
  const dispatch = useDispatch();
  const [board, setBoard] = useState();
  const { enqueueSnackbar } = useSnackbar();
  // const { boardLoading, boardEmpty } = useGetBoard();
  const [page, setPage] = useState(1);
  const [project, setProject] = useState();
  const [users, setUser] = useState();
  const [userID, setUserID] = useState('');
  const [projectId, setProjectId] = useState('');
  const [isLogShow, setIsLogShow] = useState('team lead');
  const [dateFilter, setDate] = useState();
  const [priorities, setPriorities] = useState();
  const [prioritieId, setPrioritieId] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState('');
  const [userData, setUserData] = useState({});
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const getKanbanTasks = async (type) => {
    const response = await AxiosAll(
      'get',
      `/tasks/kanban?type=${type || ''}&project_id=${
        searchParams.get('project_id') ? searchParams.get('project_id') : ''
      }&query=${query}&user_id=${
        user?.roles[0]?.id === 2 ? '' : userID || (searchParams.get('user') ? searchParams.get('user') : '')
      }&priority=${searchParams.get('priority') ? searchParams.get('priority') : ''}&due_date=${
        searchParams.get('due_date') ? searchParams.get('due_date') : ''
      }`,
      {},
      token
    );
    if (response?.data?.statusCode === 200) {
      setBoard(response?.data?.board);
    } else {
      console.log('error', response);
    }
  };

  const handleToggle = (e, val) => {
    if (val === 'team lead') {
      setIsLogShow('team lead');
      setUserID('');
      getKanbanTasks('team_tasks');
      console.log('task');
    } else if (val === 'developer') {
      setIsLogShow('developer');
      setUserID(user.id);
      getKanbanTasks('my_tasks');
      console.log('my_task');
    }
  };

  useEffect(() => {
    if (user?.roles[0]?.id === 2) {
      setUserID(user.id);
      getKanbanTasks('my_tasks');
    } else {
      getKanbanTasks('team_tasks');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, dateFilter, projectId, query, userID, prioritieId, projectId]);

  const statusChange = async (destination, source, draggableId) => {
    try {
      const data1 = {
        task_status: destination.droppableId,
      };
      const response = await AxiosAll('post', `/tasks/status-change/new/${draggableId}`, data1, token);
      const { data } = response;

      if (data.statusCode === 200) {
        enqueueSnackbar(data?.message, { variant: 'success' });

        const sourceColumn = board?.columns[source.droppableId];
        const destinationColumn = board?.columns[destination.droppableId];

        // Condition: Moving task to a different column
        const sourceTaskIds = [...sourceColumn.taskIds];
        const destinationTaskIds = [...destinationColumn.taskIds];

        // Remove from the source column
        sourceTaskIds.splice(source.index, 1);

        // Insert into the destination column
        destinationTaskIds.splice(destination.index, 0, draggableId);

        // Move the task to a different column
        setBoard({
          ...board,
          columns: {
            ...board?.columns,
            [sourceColumn.id]: {
              ...sourceColumn,
              taskIds: sourceTaskIds,
            },
            [destinationColumn.id]: {
              ...destinationColumn,
              taskIds: destinationTaskIds,
            },
          },
        });
        getCounts();
        if (user?.roles[0]?.id === 2) {
          setUserID(user.id);
          getKanbanTasks('my_tasks');
        } else {
          getKanbanTasks('team_tasks');
        }

        console.info('Moving to a different list!');
      } else {
        enqueueSnackbar(data?.message, { variant: 'error' });
        if (user?.roles[0]?.id === 2) {
          getKanbanTasks('my_tasks');
        } else {
          getKanbanTasks('team_tasks');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onDragEnd = useCallback(
    async ({ destination, source, draggableId, type }) => {
      try {
        // Condition: If there is no destination, do nothing
        if (!destination) {
          enqueueSnackbar('You cannot perform this action.', { variant: 'error' });
          return;
        }

        // Condition: If the item was dropped in the same position or the same column, do nothing
        if (
          (destination.droppableId === source.droppableId && destination.index === source.index) ||
          source.droppableId === destination.droppableId
        ) {
          return;
        }

        if (user?.roles[0]?.id === 2) {
          // Conditions for role id 2
          if (board?.columns[2]?.taskIds.length > 0 && destination.droppableId === '2') {
            enqueueSnackbar('Only a single task can be in IN PROGRESS.', { variant: 'error' });
            return;
          }
          if (
            (source.droppableId === '1' && destination.droppableId === '2') ||
            (source.droppableId === '1' && destination.droppableId === '3')
          ) {
            statusChange(destination, source, draggableId, type);
          } else if (
            (source.droppableId === '2' && destination.droppableId === '4') ||
            (source.droppableId === '2' && destination.droppableId === '3')
          ) {
            statusChange(destination, source, draggableId, type);
          } else if (source.droppableId === '3' && destination.droppableId === '2') {
            statusChange(destination, source, draggableId, type);
          } else {
            enqueueSnackbar('You cannot perform this action.', { variant: 'error' });
            return;
          }
        } else if (user?.roles[0]?.id === 4) {
          if (
            (source.droppableId === '1' && destination.droppableId === '2') ||
            (source.droppableId === '1' && destination.droppableId === '3')
          ) {
            statusChange(destination, source, draggableId, type);
          } else if (
            (source.droppableId === '2' && destination.droppableId === '4') ||
            (source.droppableId === '2' && destination.droppableId === '3') ||
            (source.droppableId === '2' && destination.droppableId === '5') ||
            (source.droppableId === '2' && destination.droppableId === '7')
          ) {
            statusChange(destination, source, draggableId, type);
          } else if (source.droppableId === '3' && destination.droppableId === '2') {
            statusChange(destination, source, draggableId, type);
          } else if (
            (source.droppableId === '4' && destination.droppableId === '5') ||
            (source.droppableId === '4' && destination.droppableId === '1') ||
            (source.droppableId === '4' && destination.droppableId === '6')
          ) {
            statusChange(destination, source, draggableId, type);
          } else if (
            (source.droppableId === '6' && destination.droppableId === '5') ||
            (source.droppableId === '6' && destination.droppableId === '1')
          ) {
            statusChange(destination, source, draggableId, type);
          } else {
            enqueueSnackbar('You cannot perform this action.', { variant: 'error' });
            return;
          }
        } else if (
          (source.droppableId === '1' && destination.droppableId === '2') ||
          (source.droppableId === '1' && destination.droppableId === '3')
        ) {
          statusChange(destination, source, draggableId, type);
        } else if (
          (source.droppableId === '2' && destination.droppableId === '4') ||
          (source.droppableId === '2' && destination.droppableId === '3')
        ) {
          statusChange(destination, source, draggableId, type);
        } else if (source.droppableId === '3' && destination.droppableId === '2') {
          if (board?.tasks[draggableId]?.is_blocked === false) {
            statusChange(destination, source, draggableId, type);
          }
        } else if (
          (source.droppableId === '4' && destination.droppableId === '5') ||
          (source.droppableId === '4' && destination.droppableId === '1') ||
          (source.droppableId === '4' && destination.droppableId === '6')
        ) {
          statusChange(destination, source, draggableId, type);
        } else if (
          (source.droppableId === '6' && destination.droppableId === '5') ||
          (source.droppableId === '6' && destination.droppableId === '1')
        ) {
          statusChange(destination, source, draggableId, type);
        } else {
          enqueueSnackbar('You cannot perform this action.', { variant: 'error' });
          return;
        }
      } catch (error) {
        console.error(error);
      }
    },
    [board?.columns]
  );

  const renderSkeleton = (
    <Stack direction="row" alignItems="flex-start" spacing={3}>
      {[...Array(4)].map((_, index) => (
        <KanbanColumnSkeleton key={index} index={index} />
      ))}
    </Stack>
  );

  const getCounts = async () => {
    const response = await AxiosAll('get', '/counts', {}, token);
    const { data } = response;
    dispatch(setCount(data));
  };

  const handleAddOpen = () => {
    setOpen(true);
  };
  return (
    <Page title="Kanban View">
      <Container maxWidth={false} sx={{ height: 1 }}>
        <HeaderBreadcrumbs
          heading="Tasks List"
          links={[{ name: 'Tasks', href: '/kanban' }, { name: 'Task List' }]}
          // action={
          //   user?.roles[0]?.id !== 2 ? (
          //     <Button
          //       variant="contained"
          //       onClick={() => setSidebarOpen(true)}
          //       startIcon={<Iconify icon={'eva:plus-fill'} />}
          //     >
          //       Add Tasks
          //     </Button>

          //   ) : null

          // }

          action={
            <>
              {(user?.roles[0]?.id === 3 || user?.roles[0]?.id === 4) && (
                <>
                  <ToggleButtonGroup
                    color="primary"
                    value={isLogShow}
                    exclusive
                    onChange={handleToggle}
                    aria-label="Platform"
                  >
                    <ToggleButton value="team lead">Lead View</ToggleButton>
                    <ToggleButton value="developer">Developer View</ToggleButton>
                  </ToggleButtonGroup>

                  <Button
                    variant="contained"
                    onClick={() => setSidebarOpen(true)}
                    startIcon={<Iconify icon={'eva:plus-fill'} />}
                  >
                    Add Tasks
                  </Button>
                </>
              )}
            </>
          }
        />

        <SideAddTask userData={userData} setOpen={setSidebarOpen} open={sidebarOpen} getUserWiseData={getKanbanTasks} />
        <LogTableToolbar search="tasks" setQuery={setQuery} />
        <FilterKanbanModal
          users={userList}
          setUserID={setUserID}
          project={projectList}
          setProjectID={setProjectId}
          priorities={priorityList}
          setPrioritieId={setPrioritieId}
          setDate={setDate}
          dateFilter={dateFilter}
          setPage={setPage}
        />

        {board?.ordered.length ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="board" type="COLUMN" direction="horizontal">
              {(provided) => (
                <Stack
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  spacing={3}
                  direction="row"
                  alignItems="flex-start"
                  sx={{
                    p: 0.25,
                    height: 1,
                    overflowY: 'hidden',
                    // ...hideScroll.x,
                  }}
                >
                  {console.log('providert----->', provided)}
                  {board?.ordered.map((columnId, index) => (
                    <KanbanColumn
                      callBack={() => getKanbanTasks('my_tasks')}
                      index={index}
                      key={columnId}
                      column={board?.columns[columnId]}
                      tasks={board?.tasks}
                    />
                  ))}

                  {provided.placeholder}

                  {/* <KanbanColumnAdd /> */}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <SkeletonKanbanColumn />
        )}
      </Container>
    </Page>
  );
}
