/* eslint-disable import/order */
import { useEffect, useState } from 'react';
// @mui
import {
    Box, Button, Card,
    Container,
    Divider,
    FormControlLabel,
    Switch,
    Table,
    TableBody,
    TableContainer,
    TablePagination
} from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import { useSnackbar } from 'notistack';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/services/axiosAll';
import useSettings from '../../hooks/useSettings';
import useTable from '../../hooks/useTable';
import useTabs from '../../hooks/useTabs';
// _mock_
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { TableHeadCustom, TableNoData, TableSkeleton } from '../../components/table';
// sections
import { TaskTableToolbar } from '../../sections/@dashboard/task/list';
// eslint-disable-next-line import/no-unresolved
import { Link as RouterLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
import FilterTaskSelf from 'src/components/filterModal/FilterTaskSelf';
import ColorInfo from 'src/components/table/ColorInfo';
import { setCount } from 'src/redux/actions/reviewCount';
import DeveloperTaskRow from 'src/sections/@dashboard/task/list/DeveloperTaskRow';
// eslint-disable-next-line import/no-duplicates

// ----------------------------------------------------------------------

const ROLE_OPTIONS = [
    'all',
    'ux designer',
    'full stack designer',
    'backend developer',
    'project manager',
    'leader',
    'ui designer',
    'ui/ux designer',
    'front end developer',
    'full stack developer',
];

const TABLE_HEAD = [
    { id: 'title', label: 'Title', align: 'left' },
    { id: 'project', label: 'Project', align: 'left' },
    { id: 'dueDate', label: 'Due Date', align: 'left' },
    { id: 'priority', label: 'priority', align: 'center' },
    { id: 'status', label: 'Status', align: 'left' },
    { id: 'creator', label: 'Creator', align: 'left' },
    { id: 'time', label: 'Alloted Time', align: 'left' },
    { id: 'spend', label: 'Spend Time', align: 'left' },
    { id: 'action', label: 'Action', align: 'left' },
];

// ----------------------------------------------------------------------

export default function DeveloperTasks() {
    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        setPage,
        //
        selected,
        setSelected,
        onSelectRow,
        onSelectAllRows,
        //
        onSort,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage,
    } = useTable();

    const { themeStretch } = useSettings();

    const navigate = useNavigate();
    const { userDetails } = useSelector((state) => state.loginReducer);
    const { token } = userDetails;
    const params = useParams();
    const [tableData, setTableData] = useState([]);
    const [filterTitle, setFilterTitle] = useState('');
    const [filterDeadline, setFilterDeadline] = useState('all');
    const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');
    const [loading, setLoading] = useState(false);

    const [project, setProject] = useState();
    const [userID, setUserID] = useState('');
    const [projectId, setProjectId] = useState('');
    const [dateFilter, setDate] = useState();
    const [StatusId, setStatusId] = useState(params?.status ? params?.status : '');
    const [status, setStatus] = useState()
    const [priorities, setPriorities] = useState()
    const [prioritieId, setPrioritieId] = useState('')
    const [total, setTotal] = useState(0);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const handleFilterName = (filterTitle) => {
        setFilterTitle(filterTitle);
        setPage(0);
    };

    const handleFilterRole = (event) => {
        setFilterDeadline(event.target.value);
    };

    const getTasks = async () => {
        setLoading(true);
        // eslint-disable-next-line no-template-curly-in-string
        const response = await AxiosAll('get', `/tasks?page=${page + 1}&query=${filterTitle}&project_id=${searchParams.get("project_id") ? searchParams.get("project_id") : ''}&user_id=${userID}&status=${StatusId || (searchParams.get("status") ? searchParams.get("status") : '')}&priority=${searchParams.get("priority") ? searchParams.get("priority") : ''}&due_date=${searchParams.get("due_date") ? searchParams.get("due_date") : ''}`, {}, token);
        const { data } = response;

        if (data?.statusCode === 200) {
            setLoading(false);
            setTableData(data?.data?.data);
            setTotal(response?.data?.data?.total);
            getCounts();
        }
    };
    const getCounts = async () => {
        const response = await AxiosAll('get', '/counts', {}, token);
        const { data } = response;
        dispatch(setCount(data))
    };
    const getProject = async () => {
        setProject([]);
        const response = await AxiosAll('get', `/common/projects?type=add_log&user_id=${userID}`, {}, token);
        if (response?.status === 200) {
            setProject(response?.data);
        }
    };

    const getStatus = async () => {
        setStatus([]);
        const response = await AxiosAll('get', `/task-statuses`, {}, token);
        if (response?.status === 200) {
            setStatus(response?.data?.data?.data);
        }
    };

    const getPrioritie = async () => {
        setPriorities([]);
        const response = await AxiosAll('get', `/task-priorities`, {}, token);
        if (response?.status === 200) {
            setPriorities(response?.data?.data?.data);
        }
    };

    const addToFavourite = async (id) => {
        const response = await AxiosAll('post', `/mark-as-favorite/${id}`, {}, token);
        if (response?.status === 200) {
            enqueueSnackbar(response?.data?.message, { variant: 'success' });
            getTasks();
        }
    };
    useEffect(() => {
        getStatus();
        getProject();
        getPrioritie()
    }, [])

    useEffect(() => {
        getTasks();
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, filterTitle, projectId, userID, StatusId, prioritieId, dateFilter, searchParams]);


    const handleEditRow = (id) => {
        navigate(`/tasks/edit/${id}`);
    };
    const handleAddSub = (id) => {
        navigate(`/tasks/add-sub/${id}`);
    };

    const denseHeight = dense ? 52 : 72;

    const isNotFound =
        (!tableData.length && !!filterTitle) ||
        (!tableData.length && !!filterDeadline) ||
        (!tableData.length && !!filterStatus);

    // No delete on developer side final on 1/12/22 in team meeting.
    return (
        <Page title="Tasks : List">
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading="Task List"
                    links={[{ name: 'Dashboard', href: '/dashboard' }, { name: 'Tasks' }]}
                    action={
                        <Button
                            variant="contained"
                            component={RouterLink}
                            to={'/tasks/add-task'}
                            startIcon={<Iconify icon={'eva:plus-fill'} />}
                        >
                            Add Tasks
                        </Button>}
                />
                <FilterTaskSelf
                    setStatusId={setStatusId}
                    setProjectID={setProjectId}
                    setDate={setDate}
                    dateFilter={dateFilter}
                    projectId={projectId}
                    userID={userDetails?.user?.id}
                    project={project}
                    status={status}
                    StatusId={StatusId}
                    priorities={priorities}
                    setPrioritieId={setPrioritieId}
                    setPage={setPage}
                />
                <Card>
                    <Divider />
                    <TaskTableToolbar
                        filterTitle={filterTitle}
                        filterDeadline={filterDeadline}
                        onFilterName={handleFilterName}
                        onFilterRole={handleFilterRole}
                        optionsRole={ROLE_OPTIONS}
                        search="task"
                    />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                            <Table size={dense ? 'small' : 'medium'}>
                                <TableHeadCustom
                                    hideCheckbox
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={tableData.length}
                                    numSelected={selected.length}
                                    onSort={onSort}
                                    onSelectAllRows={(checked) =>
                                        onSelectAllRows(
                                            checked,
                                            tableData.map((row) => row.id)
                                        )
                                    }
                                />
                                {loading ? (
                                    <>
                                        <TableSkeleton />
                                        <TableSkeleton />
                                        <TableSkeleton />
                                        <TableSkeleton />
                                        <TableSkeleton />
                                    </>
                                ) : (
                                    <TableBody>
                                        {tableData.map((row) => (
                                            <DeveloperTaskRow
                                                key={row.id}
                                                row={row}
                                                onEditRow={() => handleEditRow(row.id)}
                                                addToFavourite={() => addToFavourite(row.id)}
                                                onAddSub={() => handleAddSub(row?.id)}
                                                getTasks={() => getTasks()}
                                            />
                                        ))}

                                        <TableNoData isNotFound={isNotFound} />
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <Box sx={{ position: 'relative' }}>
                        <TablePagination
                            rowsPerPageOptions={[20]}
                            component="div"
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={onChangePage}
                            onRowsPerPageChange={onChangeRowsPerPage}
                        />
                        <ColorInfo />

                        <FormControlLabel
                            control={<Switch checked={dense} onChange={onChangeDense} />}
                            label="Dense"
                            sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                        />
                    </Box>
                </Card>
            </Container>
        </Page>
    );
}
