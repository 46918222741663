/* eslint-disable no-debugger */
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import moment from 'moment';
import DatePickerDialog from 'src/components/dailogs/DatePicker';
import { LoadingButton } from '@mui/lab';
// eslint-disable-next-line import/no-unresolved
import ConfirmDialogue from 'src/components/dialouge/ConfirmDialogue';
import AxiosAll from 'src/services/axiosAll';
import MenuPopover from 'src/components/MenuPopover';
import ProjectReportPrintPage from './ProjectReportPrintPage';
import useSettings from '../../hooks/useSettings';
import useTable from '../../hooks/useTable';
import useTabs from '../../hooks/useTabs';
// _mock_
// components
// eslint-disable-next-line import/order
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { TableHeadCustom, TableNoData, TableSelectedActions, TableSkeleton } from '../../components/table';
// sections
import { ReportTableRow, ReportTableToolbar } from '../../sections/@dashboard/report/list';
import currentURL from '../../env';

// ----------------------------------------------------------------------
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}
const TABLE_HEAD = [
  { id: 'project', label: 'Project Name', align: 'left' },
  { id: 'startDate', label: 'Start Date', align: 'left' },
  { id: 'dueDate', label: 'Due Date', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
];

// ----------------------------------------------------------------------

export default function ProjectList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();
  const baseURL = currentURL?.currentEnv;
  const [tableData, setTableData] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [multipleDeleteMode, setMultipleDeleteMode] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [btnshow, setBtnShow] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionIds, setSelectedOptionIds] = useState([])
  const [startupProject, setStartupProject] = useState([{}])
  const [showDropdown, setShowDropdown] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    type: 'report',
    message: '',
  });
  const [stateError, setStateError] = useState(false);
  const [startup_id, setStartup_id] = useState("");
  const [UserId, setUserId] = useState(0);
  const [detailReport, setDetailReport] = useState(0);
  const [currentProjectUser, setCurrentProjectUser] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [temploading, setTempLoading] = useState(false);
  const [startup, setStartup] = useState([])
  const [triggerReport, setTriggerReport] = useState(true);
  const [startDate, setStartDate] = useState(
    moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [selectedDate, setSelectedDate] = useState([
    {
      startDate: new Date().setDate(new Date().getDate() - 7),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');

  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const buttonRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleChange = (event) => {
    setStartup_id(event.target.value);
    setSelectedOptions([])
    setStateError(false);
  };
  const handleChangeProject = (event, value) => {
    setSelectedOptions(value);
    // Store selected option IDs in another state
    const selectedIds = value.map((option) => option.id);
    setSelectedOptionIds(selectedIds);
  };


  const handleReportChange = (event) => {
    setDetailReport(event.target.value);
    setUserId(event.target.value === 2 ? 0 : UserId);
    setStateError(false);
  };

  const handleUserChange = (event) => {
    setUserId(event.target.value);
    setStateError(false);
  };
  const getProject = async () => {
    const res = await AxiosAll('get', `/common/projects`, {}, token);
    if (res?.status === 200) {
      const newData = res?.data;
      setProjectData(newData);
    }
  };

  const handleStartDateChange = (e) => {

    setStartDate(e.target.value);
  };
  const handleEndDateChange = (e) => {

    setEndDate(e.target.value);
  };

  const getStartups = async () => {
    const response = await AxiosAll('get', `/startups?page=${page + 1}&query=${filterName}`, {}, token);
    const { data } = response;
    window.scrollTo(0, 0);

    if (data?.statusCode === 200) {
      setStartup(data?.data?.data);
    }
  };

  const getStartupProject = async () => {
    const response = await AxiosAll('get', `/startups/projects?startup_ids=${startup_id}?page=${page + 1}&query=${filterName}`, {}, token);
    const { data } = response;
    window.scrollTo(0, 0);

    if (data?.statusCode === 200) {
      setStartupProject(data?.data);
    }
  };


  useEffect(() => {
    getStartups()
  }, [selectedOptionIds?.length]);

  useEffect(() => {
    getStartupProject()
  }, [startup_id]);

  const handleOpenDatePicker = () => {
    setOpenDatePicker(!openDatePicker);
  };

  useEffect(() => {

    getProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filterName]);


  const handleReport = async (report) => {

    if (startup_id) {
      setTempLoading(true);
      try {
        window.open(
          `${baseURL}/download-project-report?file_name=${report
            ? `project_detail_report_${startup
              .find((user) => user.id === startup_id)
              ?.name.replace(/ /g, '_').replace(/\./g, '')}_${moment(startDate).format('YYYY-MM-DD')}_to_${moment(
                endDate
              ).format('YYYY-MM-DD')}`
            : `project_overview_report_${moment(startDate).format('YYYY-MM-DD')}_to_${moment(
              endDate
            ).format('YYYY-MM-DD')}`
          }&startup_id=${startup_id}&project_ids=${selectedOptionIds}&detailed_report=${report}&user_id=${UserId === 0 ? '' : UserId
          }&start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(
            endDate
          ).format('YYYY-MM-DD')}`
        )


        console.log("test", `${baseURL}/download-project-report?file_name=${report
          ? `project_detail_report_${startup
            .find((user) => user.id === startup_id)
            ?.name.replace(/ /g, '_').replace(/\./g, '')}_${moment(startDate).format('YYYY-MM-DD')}_to_${moment(
              endDate
            ).format('YYYY-MM-DD')}`
          : `project_overview_report_${moment(startDate).format('YYYY-MM-DD')}_to_${moment(
            endDate
          ).format('YYYY-MM-DD')}`
          }&startup_id=${startup_id}&project_ids=${selectedOptionIds}&detailed_report=${report}&user_id=${UserId === 0 ? '' : UserId
          }&start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(
            endDate
          ).format('YYYY-MM-DD')}`
        )


        enqueueSnackbar(
          `Exporting ${report ? 'overview' : ' detail'}  Timesheet Data for ${startup.find((user) => user.id === startup_id)?.name
          } `,
          {
            variant: 'success',
          }
        );

        setTempLoading(false);

        // console.error('response:', response);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };



  const denseHeight = dense ? 52 : 72;
  console.log("projectIds", selectedOptionIds)
  console.log("startup_id", startup_id)

  const isNotFound =
    (!tableData.length && !!filterName) || (!tableData.length && !!filterRole) || (!tableData.length && !!filterStatus);
  // console.log('errorMessage', errorMessage?.type);
  return (
    <Page title="Project Report">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Project Report"
          links={[{}]}
        />
        {/* <Card
          sx={{
            p: 5,
            boxShadow: 'none',
            border: '1px solid #DFE3E8',
            borderRadius: '0.625rem',
            mb: 10,
            overflow: 'inherit',
          }}
        > */}
          {/* <Typography variant="h6" sx={{ mb: 4, color: 'text.secondary' }}>
            Generate an Automatic Report
          </Typography> */}
          {/* </div> */}
          {/* <Typography variant="body1" sx={{ mb: 5, color: 'text.secondary' }}>
            Select your date range, and the type of report you want (you can select multiple types) to generate a
            standard report. It will automatically appear in your Report List below.
          </Typography> */}

          {/* <FormControl component="fieldset" className="required-email"> */}
          {/* <Typography variant="h6" sx={{ mb: 3, mt: 6, color: 'text.secondary' }}>
            Select report type
          </Typography> */}
          <Stack
            // sx={{
            //   flexDirection: {
            //     xs: 'column',
            //     md: 'row',
            //     lg: 'row',
            //   },
            //   gap: 2,
            // }}
            // direction={{ xs: 'column', sm: 'column' , md:'row' }}
            // spacing={2}
            className=" marginbtm"
            direction={'row'}
            flexWrap={'wrap'}
            gap={1.5}
          >

            <Stack direction={'column'} spacing={2} sx={{ width: { xs: '100%', sm: '100%', md:'200px' } }}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="demo-simple-select-label">StartUp</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={startup_id}
                  label="StartUp"
                  onChange={handleChange}
                  
                >
                  {startup?.map((data, index) => (
                    <MenuItem key={index} value={data?.id}>
                      {data?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>{' '}
              {errorMessage?.type === 'Startup' && stateError && (
                <p className="error-message profile_error_message required-email-error">{errorMessage?.message}</p>
              )}
            </Stack>
            <Stack direction={'column'} spacing={2} sx={{ width: { xs: '100%',  sm: '100%', md:'200px' } }}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              {/* <InputLabel id="demo-simple-select-label">Project</InputLabel> */}
              <Autocomplete
                multiple
                aria-label='Project'
                id="checkboxes-tags-demo"
                options={startupProject}
                disableCloseOnSelect
                getOptionLabel={(option) => option?.title}
                onChange={handleChangeProject}
                value={selectedOptions}
                defaultChecked={selectedOptions}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <FormControlLabel
                      style={{ width: '100%', color: option?.color }}
                      control={<Checkbox checked={selected} />}
                      label={option.title}
                    />
                  </li>
                )}
                sx={{
                  // maxWidth: { sm: 240 },
                  textTransform: 'capitalize',
                }}
                // style={{ margin: 0 }}
                renderInput={(params) => <TextField {...params} variant="outlined" label="Select Options" />}
              />
            </FormControl>

            </Stack>

            <TextField
              id="date1"
              type="date"
              label="Start Date"
              value={startDate}
              onChange={handleStartDateChange}
              InputProps={{ inputProps: { max: moment(new Date()).format('YYYY-MM-DD') } }}
              sx={{
                width: { xs: '100%',  sm: '100%', md:'250px' },
              }}
              // style={{ margin: 0 }}
            />
            <TextField
              id="date2"
              type="date"
              label="End Date"
              value={endDate}
              onChange={handleEndDateChange}
              // style={{ margin: 0 }}
              InputProps={{ inputProps: { max: moment(new Date()).format('YYYY-MM-DD') } }}
              sx={{
                width: { xs: '100%', sm: '100%', md:'250px' },
              }}
            />


            <Button
              size="large"
              variant="contained"
              disabled={!startup_id}
              loading={temploading}
              type="button"
              onClick={() => {
                setTriggerReport((prev) => !prev);
              }}
            >
              Apply Filter
            </Button>



            <Button size="large" disabled={!startup_id}
              variant="contained" ref={buttonRef} onClick={() => setShowDropdown(!showDropdown)}>
              Download Report
            </Button>

            <Menu
              id="fade-menu"
              className='csv-pdf-section'
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorEl={buttonRef.current}
              open={showDropdown}
              onClose={() => setShowDropdown(false)}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={() => {
                handleReport(false);
                setShowDropdown(false);
              }}>
                <Button
                  // size="large"
                  variant="contained"
                  disabled={!startup_id}
                  type="button"
                >
                  OverView Report
                </Button>
              </MenuItem>
              <MenuItem onClick={() => {
                handleReport(true);
                setShowDropdown(false);
              }}>
                <Button
                  // size="large"
                  variant="contained"
                  disabled={!startup_id}
                  type="button"
                >
                  Detail Report
                </Button>
              </MenuItem>
            </Menu>



          </Stack>

        {/* </Card> */}
        <ProjectReportPrintPage
          triggerReport={triggerReport}
          startup_id={startup_id}
          projectIds={selectedOptionIds}
          endDate={endDate}
          startDate={startDate}
        />
      </Container>
    </Page >
  );
}
