/* eslint-disable import/no-unresolved */
// import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography } from '@mui/material';
// routes
// import { PATH_AUTH } from '../../routes/paths';
// hooks
// import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
// import Logo from '../../components/Logo';
import Image from '../../components/Image';
// sections
import LoginForm from '../../sections/auth/login/LoginForm';
// eslint-disable-next-line import/order
// import { imagesAll } from 'src/assets/Images_index';
import { imagesAll } from '../../assets/Images_index';
// eslint-disable-next-line import/order
import SocialLogin from 'src/sections/auth/social-login';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  // maxWidth: 464,
  // maxWidth:'32.22vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  // maxWidth: 480,
  // maxWidth:'33.33vw',

  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  // const { method } = useAuth();

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <div className="login">
        <RootStyle>
          {/* <HeaderStyle className="boxpadding">
            <Logo />
            {smUp && (
              <Typography variant="body2" sx={{ mt: { md: -2 } }} className="account-text">
                Don’t have an account? {''}
                <Link variant="subtitle2" component={RouterLink} to={'/register'}>
                  Get started
                </Link>
              </Typography>
            )}
          </HeaderStyle> */}

          {mdUp && (
            <SectionStyle className="section-style">
              <Typography variant="h3" sx={{ px: 5, mt: 10 }} className="welcome_text">
                Welcome back to, PMS!
              </Typography>
              <Image visibleByDefault disabledEffect src={imagesAll.rsvrLogo} className="login_sectionimg" />
            </SectionStyle>
          )}

          <Container maxWidth="sm">
            <ContentStyle className="mobilepadd container-style">
              <Stack sx={{ mb: 5 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h4" gutterBottom className="signin_text">
                    Sign in to PMS
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }} className="details_text">
                    Enter your details below.
                  </Typography>
                </Box>
                {/* <SocialLogin /> */}
              </Stack>

              {/* <Alert severity="info" sx={{ mb: 3 }}>
                Use email : <strong>demo@minimals.cc</strong> / password :<strong> demo1234</strong>
              </Alert> */}

              <LoginForm />

              {!smUp && (
                <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                  Don’t have an account?{' '}
                  <Link variant="subtitle2" component={RouterLink} to={'/register'}>
                    Get started
                  </Link>
                </Typography>
              )}
            </ContentStyle>
          </Container>
        </RootStyle>
      </div>
    </Page>
  );
}
