import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Avatar, Checkbox, MenuItem, TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import Iconify from '../../../../components/Iconify';
import Label from '../../../../components/Label';
import { TableMoreMenu } from '../../../../components/table';

// ----------------------------------------------------------------------

RoleTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  // onSelectRow: PropTypes.func,
  // onDeleteRow: PropTypes.func,
  setDeleteDialog: PropTypes.func,
  setDeleteId: PropTypes.func,
};

export default function RoleTableRow({
  row,
  selected,
  onEditRow,
  // onSelectRow,
  // onDeleteRow,
  setDeleteDialog,
  setDeleteId,
}) {
  const theme = useTheme();

  const { name } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover={!row?.is_default} sx={row?.is_default && { backgroundColor: '#dddddd7a' }} selected={selected}>
      {/* <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell> */}

      <TableCell align="center">
        <Typography variant="subtitle2" noWrap>
          {name}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="subtitle2" noWrap>
          {row?.is_default ? 'Default' : '---'}
        </Typography>
      </TableCell>

      <TableCell align="center">
        {!row?.is_default && (
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              <>
                <MenuItem
                  onClick={() => {
                    // onDeleteRow();
                    setDeleteDialog(true);
                    setDeleteId(row.id);
                    handleCloseMenu();
                  }}
                  sx={{ color: 'error.main' }}
                >
                  <Iconify icon={'eva:trash-2-outline'} />
                  Delete
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onEditRow();
                    handleCloseMenu();
                  }}
                >
                  <Iconify icon={'eva:edit-fill'} />
                  Edit
                </MenuItem>
              </>
            }
          />
        )}
      </TableCell>
    </TableRow>
  );
}
