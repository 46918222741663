import PropTypes from 'prop-types';
// form
// @mui
import {
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Table,
  TableBody,
  TableContainer,
  Typography,
  TableHead,
  TableCell,
  TableRow,
} from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import { styled } from '@mui/material/styles';
// _mock
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import { TableNoData, TableSkeleton } from 'src/components/table';
import TableHeadReportView from 'src/components/table/TableHeadReportView';
import TableRowReportView from 'src/sections/@dashboard/project/list/TableRowReportView';
import AxiosAll from 'src/services/axiosAll';

const TABLE_HEAD = [
  { id: 'Task', label: 'Task', align: 'left' },
  { id: 'Name', label: 'User Name', align: 'left' },
  { id: 'Date', label: 'Date', align: 'left' },
  { id: 'SpendTime', label: 'Spend Time', align: 'left' },
];
// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

DetailReportViewModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  currentRole: PropTypes.object,
  isEdit: PropTypes.bool,
  getRoles: PropTypes.func,
};

export default function DetailReportViewModal({ open, onClose, isEdit, date, projectId, userId, currentRole, getRoles, title }) {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const getReportDetailView = async () => {
    setLoading(true);
    const response = await AxiosAll(
      'get',
      `/task-time-logs?query=&page=1&project_id=${projectId}&user_id=${userId}&type=null&date=${date}&limit=100`,
      {},
      token
    );
    const { data } = response;
    // window.scrollTo(0, 0);
    console.log('response', data);

    if (response?.status === 200) {
      setTableData(data?.data?.data);
      setLoading(false);
    }
  };

  const isNotFound = !tableData?.length;

  useEffect(() => {
    if (date && projectId && open) {
      getReportDetailView();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date,open, projectId]);

console.log("projectId", projectId , date)
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={() => {
        onClose();
        setTableData([])
      }}
    >
      <DialogTitle>{title || 'Add new role'} </DialogTitle>

      <DialogContent>
        <CardContent sx={{pl:0}}>
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
           
            <Table>
              {/* <TableHeadReportView
                headLabel={TABLE_HEAD}
                // numSelected={selected.length}
                // onSort={onSort}
                // onSelectAllRows={(checked) =>
                //     onSelectAllRows(
                //         checked,
                //         tableData.map((row) => row.id)
                //     )
                // }
              /> */}
              <TableHead>
                <TableRow>
                  <TableCell align="left" className="remove-shadow" style={{ whiteSpace: 'nowrap' }}>
                    Task
                  </TableCell>
                  <TableCell align="left" className="remove-shadow" style={{ whiteSpace: 'nowrap' }}>
                    Name
                  </TableCell>
                  <TableCell align="left" className="remove-shadow" style={{ whiteSpace: 'nowrap' }}>
                    Date
                  </TableCell>
                  <TableCell align="left" className="remove-shadow" style={{ whiteSpace: 'nowrap' }}>
                    Spend Time
                  </TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <>
                  <TableSkeleton />
                
                </>
              ) : (
                <TableBody>
                  {tableData?.map((row) => (
                    <TableRowReportView
                      key={row.id}
                      row={row}
                      // selected={selected.includes(row.id)}
                      // onSelectRow={() => onSelectRow(row.id)}
                      // setMultipleDeleteMode={setMultipleDeleteMode}
                      // setDeleteDialog={setDeleteDialog}
                      // setDeleteId={setDeleteId}
                      // onDeleteRow={() => handleDeleteRow(row.id)}
                      // onEditRow={() => handleEditRow(row.id)}
                      // onAddUser={() => handleAddUser(row.id)}
                      // onAddManager={() => handleAddManager(row.id)}
                    />
                  ))}

                  {/* <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} /> */}

                  {/* <TableNoData isNotFound={isNotFound} /> */}
                </TableBody>
              )}
            </Table> 
          </TableContainer>
        </CardContent>
      </DialogContent>

      <Divider />

      {/* <DialogActions>
        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          {isEdit ? 'Update' : 'Create'}
        </LoadingButton>
        <Button
          color="inherit"
          variant="outlined"
          onClick={() => {
            onClose();
            reset(defaultValues);
          }}
        >
          Cancel
        </Button>
      </DialogActions> */}
    </Dialog>
  );
}
