/* eslint-disable prefer-template */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
// @mui
import { Box, Button, Container, MenuItem, Stack } from '@mui/material';
// components
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import Page from '../../components/Page';
import { MotionContainer } from '../../components/animate';
import DownloadedCertificate from './downloadedCertificate';
// assets
// eslint-disable-next-line import/order

const compName = 'RSVR';

const monthOrYear = [
  {
    value: 'month',
    label: 'Month',
  },
  {
    value: 'year',
    label: 'Year',
  },
];

export default function CreateCertificate() {
  const [employeeName, setemployeeName] = useState('');

  const handleTextFieldChange = (event) => {
    setemployeeName(event.target.value);
  };
  const [currentDay, setCurrentDay] = useState('');
  const [currentMonth, setCurrentMonth] = useState('');
  const [currentYear, setCurrentYear] = useState('');

  useEffect(() => {
    const currentDate = new Date();
    const month = currentDate.toLocaleString('default', { month: 'long' });
    const year = currentDate.getFullYear();
    setCurrentDay(currentDate.getDay());
    setCurrentMonth(month);
    setCurrentYear(year);
  }, []);

  const [selectedDate, setselectedDate] = useState('month');

  const handledateChange = (event) => {
    setselectedDate(event.target.value);
  };

  const printDiv = () => {
    // Get the content of the printcard element
    const printContents = document.getElementById('Certificate').innerHTML;
    // Create a header with the file name
    const header = `certificate`;
    const printableContent = printContents;

    // Create a new window for printing
    const printWindow = window.open('', '_blank');

    // Set the content of the new window
    printWindow.document.open();
    printWindow.document.write('<html><head><title>' + header + '</title></head><body>');
    printWindow.document.write(printableContent);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    // Trigger the print dialog
    printWindow.print();
  };

  return (
    <>
      <div style={{ width: '100%' }}>
      <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="center"
          alignItems={{ xs: 'start', sm: 'center' }}
          gap={3}
          sx={{ mb: 3, pl: { xs: 3, sm: 0 }, pr: { xs: 3, sm: 0 } }}
        >
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            value={employeeName}
            onChange={handleTextFieldChange}
            // sx={{width:'100%'}}
          />
          <TextField
            id="outlined-select-currency"
            select
            label="Select"
            value={selectedDate}
            onChange={handledateChange}
          >
            {monthOrYear.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Button variant="contained" color="primary" onClick={printDiv}>
            Download
          </Button>
        </Stack>
      </div>
      <Page id="Certificate" title="Certificate">
        <Container component={MotionContainer} id="printcard" >
       
          <div >
            <DownloadedCertificate currentYear={currentYear} employeeName={employeeName} selectedDate={selectedDate} />
          </div>
        </Container>
      </Page>
    </>
  );
}
