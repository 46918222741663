import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Avatar, MenuItem, TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import moment from 'moment';
import Markdown from 'src/components/Markdown';
import { convertTo12HourFormat } from 'src/core';
import Iconify from '../../../../components/Iconify';
import Label from '../../../../components/Label';
import { TableMoreMenu } from '../../../../components/table';

// ----------------------------------------------------------------------

LogsTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
};

export default function LogsTableRow({ row, selected, onEditRow }) {
  const theme = useTheme();

  const { name, avatarUrl, company, isVerified, status, task,projects, user, comment, date, time_format, type, start_time, end_time } = row;
  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={selected}>
      {/* <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell> */}

      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt={name} src={avatarUrl || user?.profile_picture} sx={{ mr: 2 }} />
        <Typography variant="subtitle2" noWrap>
          {name || `${user?.first_name} ${user?.last_name}`}
        </Typography>
      </TableCell>

      <TableCell align="left">{type.replace("_", " ")}</TableCell>

      <TableCell align="left">{company || task?.title ?  company || task?.title  :   type === "discussion" && `discussion on ${projects?.title.replace("-", " ")}`}</TableCell>


      <TableCell align="left" className='discription_text min-width-table-log'>
        <Markdown children={comment.replace("_", " ")} />
      </TableCell>

      <TableCell align="center">
        {time_format || (
          <Iconify
            icon={isVerified ? 'eva:checkmark-circle-fill' : 'eva:clock-outline'}
            sx={{
              width: 20,
              height: 20,
              color: 'success.main',
              ...(!isVerified && { color: 'warning.main' }),
            }}
          />
        )}
      </TableCell>
      <TableCell>{start_time?convertTo12HourFormat(start_time):null || "00:00 am"}</TableCell>
      <TableCell>{end_time?convertTo12HourFormat(end_time):null|| "00:00 am"}</TableCell>

      <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={(status === 'banned' && 'error') || 'success'}
          sx={{ textTransform: 'capitalize' }}
        >
          {status || moment(date).format('DD-MM-YYYY')}
        </Label>
      </TableCell>

      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              {/* {time_format ? null : (
                <>
                  <MenuItem
                    onClick={() => {
                      onDeleteRow();
                      handleCloseMenu();
                    }}
                    sx={{ color: 'error.main' }}
                  >
                    <Iconify icon={'eva:trash-2-outline'} />
                    Delete
                  </MenuItem>
                </>
              )} */}
              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Edit
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
