import { TextField } from '@mui/material';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

export default function ResponsiveDateTimePickers({ placeholder, selectedDateTime, setSelectedDateTime, min }) {

    const handleDateTimeChange = (date) => {
        setSelectedDateTime(date);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileDateTimePicker
                minDate={min && min}
                label={placeholder}
                value={selectedDateTime}
                onChange={handleDateTimeChange}
                renderInput={(props) => <TextField {...props} />}
            />
        </LocalizationProvider>
    );
};




// import { TextField } from '@mui/material';
// import { MobileDatePicker } from '@mui/x-date-pickers';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { useState } from 'react';


// export default function ResponsiveDateTimePickers() {
//     const [datee, setDatee] = useState('');
//     console.log("date", datee)
//     return (

//         <LocalizationProvider dateAdapter={AdapterDayjs}>
//             <DemoContainer
//                 components={[
//                     'MobileDateTimePicker',
//                 ]}
//             >

//                 <DemoItem label="Mobile variant">
//                     <MobileDateTimePicker defaultValue={dayjs('2022-04-17T15:30')} onChange={(e) => setDatee(e.target)} />
//                 </DemoItem>

//             </DemoContainer>
//         </LocalizationProvider>
//     );
// }

