import { InputAdornment, Stack, TextField } from '@mui/material';
import PropTypes from 'prop-types';
// components
import { useMemo, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import debouce from "lodash.debounce";
import Iconify from '../../../../components/Iconify';
// ----------------------------------------------------------------------

UserTableToolbar.propTypes = {
  filterTitle: PropTypes.string,
  filterDeadline: PropTypes.string,
  onFilterName: PropTypes.func,
  onFilterRole: PropTypes.func,
  optionsRole: PropTypes.arrayOf(PropTypes.string),
  setProjectID: PropTypes.string,
  setTaskID: PropTypes.string,
  setDate: PropTypes.string,
  dateFilter: PropTypes.string,
  setUserID: PropTypes.string,
};

export default function UserTableToolbar({
  filterTitle,
  filterDeadline,
  onFilterName,
  onFilterRole,
  optionsRole,
  setProjectID,
  setTaskID,
  setDate,
  dateFilter,
  setUserID,
  search,
  setQuery,
}) {
  const [project, setProject] = useState();
  const [projectId, setProjectId] = useState(0);
  const [tasks, setTask] = useState();
  const [users, setUser] = useState();
  const [userId, setUserId] = useState();
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token, user } = userDetails;

  const handleUsers = (e) => {
    // eslint-disable-next-line no-debugger
    setUserId(e.target.value);
    setUserID(e.target.value);
    // setProjectID(e.target.value);
  };

  const handleProject = (e) => {
    // eslint-disable-next-line no-debugger
    setProjectId(e.target.value);
    setProjectID(e.target.value);
  };

  const handleTaskId = (e) => {
    // eslint-disable-next-line no-debugger
    setTaskID(e.target.value);
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };
  const handleChange = (e) => {
    setQuery(e.target.value.trim());
  };
  const debouncedResults = useMemo(() => debouce(handleChange, 500), []);

  return (
    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, pr: 3 }}>

      <TextField
        fullWidth
        value={filterTitle}
        onChange={debouncedResults}
        placeholder={`Search ${search ?? `user`}...`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}
