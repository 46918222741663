/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { Avatar, Button, Card, CardContent, Container, Divider, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// routes
// import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import moment from 'moment';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Markdown from 'src/components/Markdown';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import AxiosAll from 'src/services/axiosAll';
import { PopUpCommentLabel } from 'src/components/labelsTag/popUpCommentLabel';
import { PopUpAvatarAssigned } from 'src/components/labelsTag/popUpAvatarAssigned';
import palette from 'src/theme/palette';
import { Mention, MentionsInput } from 'react-mentions';
import Iconify from 'src/components/Iconify';
import KanbanWatchPopup from '../kanban/kanban-watchDetail';
import KanbanBlockPopup from '../kanban/kanban-blockDetail';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------

SingleTaskDetail.propTypes = {
  task: PropTypes.object,
  column: PropTypes.object,
};

export default function SingleTaskDetail(column) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const [addComment, setAddCoomment] = useState();
  const [taskComments, setTaskComments] = useState([]);
  const [LastUpdated, setLastUpdated] = useState('');
  const [showTaskCommnet, setShowComment] = useState(true);
  const [openWatchPopup, setOpenWatchPopup] = useState(false);
  const [userData, setUserData] = useState([]);
  const [openBlockPopup, setOpenBlockPopup] = useState(false);
  const [task, setTask] = useState([]);
  const { themeStretch } = useSettings();
  const urlImage = `https://internal-pms-dev.s3.ap-south-1.amazonaws.com/`;
  // get redux state
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token, user } = userDetails;

  const getTaskDetail = async () => {
    const response = await AxiosAll('get', `/tasks/${params?.id}`, null, userDetails.token);
    const { data, user } = response;
    setTask(data?.data);
    return {};
  };

  const getUser = async () => {
    const res = await AxiosAll('get', `/common/users`, {}, token);
    if (res?.status === 200) {
      const mentionableNames = res.data.map((person) => ({
        id: person.id,
        display: person.full_name,
      }));
      const newData = res?.data;
      setUserData(mentionableNames);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);
  const init = () => {
    if (params?.id) {
      getTaskDetail();
      getUser();
      getTaskComments();
    }
  };
  const methods = useForm({
    resolver: yupResolver(),
  });

  const {
    reset,
    watch,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();
  function handleLinkClick(link) {
    window.open(link, '_blank');
  }

  const getTaskComments = async () => {
    const res = await AxiosAll('get', `/task-comments/${params?.id}`, {}, token);
    if (res?.status === 200) {
      setTaskComments(res?.data?.data);
      setLastUpdated(res?.data?.latestTaskActivity);
      setAddCoomment();
    } else {
      console.log('error', res);
      setShowComment(false);
    }
  };

  const onChange = (e) => {
    setAddCoomment(e.target.value);
  };

  const AddTaskComments = async () => {
    const comment = {
      task_id: task?.id,
      comment: addComment,
    };
    const res = await AxiosAll('post', `/task-comments`, comment, token);
    if (res?.data?.statusCode === 201) {
      setAddCoomment('');
      getTaskComments();
    } else {
      console.log('error', res);
    }
  };

  const renderSuggestion = (suggestion, search, highlightedDisplay, index, focused) => (
    <li
      key={suggestion.id}
      className={`mention ${focused ? 'focused' : ''}`}
      style={{ borderBottom: '1px solid #000' }}
    >
      <span>{highlightedDisplay}</span>
    </li>
  );

  console.log('test', task?.task_users && task?.task_users[0]?.user?.full_name);

  const handleBlockPopupOpen = () => {
    setOpenBlockPopup(true);
  };

  const handleBlockPopupClose = () => {
    setOpenBlockPopup(false);
  };

  const handleWatchOpen = () => {
    if (user?.role_id === 1 || user?.role_id === 3 || user?.role_id === 4) {
      setOpenWatchPopup(true);
    } else {
      setOpenWatchPopup(false);
    }
  };

  const handleWatchClose = () => {
    setOpenWatchPopup(false);
  };
  return (
    <Page title="Task: Detail">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs heading="Task Details" links={[{ name: 'Tasks', href: '/kanban' }, { name: 'Details' }]} />
        <Card>
          <Stack spacing={2} direction="row" alignItems="center">
            {user?.roles[0]?.id === 2 ? (
              ''
            ) : (
              <Iconify
                onClick={() => navigate(`/tasks/edit/${task.id}`)}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 50,
                  fontSize: 40,
                  marginTop: 0,
                  cursor: 'pointer',
                  padding: 1,
                }}
                icon="mi:edit"
              />
            )}

            <Button variant="outlined" onClick={handleBlockPopupOpen}>
              {task?.is_blocked ? 'Unblock' : 'Block'}
            </Button>

            {/* {column?.name == 'Completed' && (
              <Typography onClick={handleWatchOpen}>
                {task?.watchers?.length <= 0 ? (
                  <Iconify
                    sx={{
                      // position: 'absolute',
                      // top: 20,
                      // right: 10,
                      fontSize: 40,
                      height: '35px',
                      marginTop: 0,
                      cursor: 'pointer',
                      padding: '2px 8px',
                      backgroundColor: 'rgba(32, 101, 209, 0.08)',
                    }}
                    icon="carbon:view-filled"
                  />
                ) : (
                  // <Button>Watching</Button>
                  <Iconify
                    sx={{
                      // position: 'absolute',
                      // top: 20,
                      // right: 10,
                      fontSize: 40,
                      marginTop: 0,
                      cursor: 'pointer',
                      padding: 1,
                      backgroundColor: 'light',
                    }}
                    icon="el:eye-close"
                  />
                )}
              </Typography>
            )} */}

            {/* <Typography onClick={handleWatchOpen}>
              {task?.watchers?.length <= 0 ? (
                <Iconify
                  sx={{
                    // position: 'absolute',
                    // top: 20,
                    // right: 10,
                    fontSize: 40,
                    height: '35px',
                    marginTop: 0,
                    cursor: 'pointer',
                    padding: '2px 8px',
                    backgroundColor: 'rgba(32, 101, 209, 0.08)',
                  }}
                  icon="carbon:view-filled"
                />
              ) : (
                // <Button>Watching</Button>
                <Iconify
                  sx={{
                    // position: 'absolute',
                    // top: 20,
                    // right: 10,
                    fontSize: 40,
                    marginTop: 0,
                    cursor: 'pointer',
                    padding: 1,
                    backgroundColor: 'light',
                  }}
                  icon="el:eye-close"
                />
              )}
            </Typography> */}

            {(user?.role_id === 1 || user?.role_id === 3 || user?.role_id === 4) && (
              <Typography onClick={handleWatchOpen}>
                {task?.watchers?.length <= 0 ? (
                  <Iconify
                    sx={{
                      // position: 'absolute',
                      // top: 20,
                      // right: 10,
                      fontSize: 40,
                      height: '35px',
                      marginTop: 0,
                      cursor: 'pointer',
                      padding: '2px 8px',
                      backgroundColor: 'rgba(32, 101, 209, 0.08)',
                    }}
                    icon="carbon:view-filled"
                  />
                ) : (
                  // <Button>Watching</Button>
                  <Iconify
                    sx={{
                      // position: 'absolute',
                      // top: 20,
                      // right: 10,
                      fontSize: 40,
                      marginTop: 0,
                      cursor: 'pointer',
                      padding: 1,
                      backgroundColor: 'light',
                    }}
                    icon="el:eye-close"
                  />
                )}
              </Typography>
            )}
          </Stack>
          <Typography variant="h4" sx={{ p: 3 }}>
            {task?.project?.title}
          </Typography>
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Project
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {task?.project?.title}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Priority
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {task?.task_priority?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Task
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {task?.title}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Startup visibility
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {task?.startup_visibility}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Status
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {task?.task_status?.name}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Estimated Time
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {task?.planned_time_format}
                </Typography>
              </Grid>
              {task?.spend_time_format === '00:00' ? null : (
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    Spend Time
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {task?.spend_time_format}
                  </Typography>
                </Grid>
              )}
              {/* <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Predecessor Tasks
                </Typography>

                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/tasks/details/${task?.predecessor_task_id}`)}
                >
                  {task?.predecessor_task}
                </Typography>
              </Grid> */}
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Start Date
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {moment(task?.start_date).format('D MMM')}
                </Typography>
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Successor Tasks
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/tasks/details/${task?.following_task_id}`)}
                >
                  {task?.following_task}
                </Typography>
              </Grid> */}
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Due Date
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {task?.due_date ? moment(task?.due_date).format('D MMM') : 'No due date'}{' '}
                  {task?.due_time && moment(task?.due_time, 'HH:mm').format('hh:mm A')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Description
                </Typography>
                <Markdown children={task?.description ? task?.description : 'No description'} />
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Refrence URL
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  onClick={() => handleLinkClick(task?.reference_url)}
                  style={{ cursor: 'pointer' }}
                >
                  {task?.reference_url}
                </Typography>
              </Grid> */}
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Assigned User
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {task?.task_users?.map((user) => (
                    <div key={user.id}>
                      {user?.user?.first_name} {user?.user?.last_name}
                    </div>
                  ))}
                </Typography>
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Refrence Title
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {task?.reference_title}
                </Typography>
              </Grid> */}
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Creator
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  {task?.created_by_user?.full_name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Ticket
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  {task?.ticket}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Label
                </Typography>
                <div style={{ marginTop: 20, display: 'flex', flexWrap: 'wrap', gap: 10 }}>
                  {task?.labels?.map((label) => (
                    <Typography
                      key={label?.id}
                      sx={{
                        top: 10,
                        left: 10,
                        padding: '2px 8px',
                        fontSize: 14,
                        borderRadius: '6px',
                        ...{
                          // color: label?.color,
                          backgroundColor: label?.color,
                          opacity: 4,
                        },
                      }}
                    >
                      <span style={{ color: 'white', textTransform: 'lowercase' }}> {label?.name}</span>
                    </Typography>
                  ))}
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                {task?.watchers?.length > 0 && column?.name !== 'Completed' && (
                  <>
                    <Typography variant="subtitle1" sx={{ my: 2 }}>
                      is Watched By
                    </Typography>
                    <Card sx={{ p: 2, border: '1px solid rgb(204, 204, 204)', display: 'flex', flexWrap: 'wrap' }}>
                      {task?.watchers?.map((watching, index) => (
                        <Typography
                          key={index}
                          variant="body1"
                          sx={{
                            fontSize: '15px',
                            marginRight: 2,
                            display: 'inline-block',
                            bgcolor: palette.light.background.neutral,
                            py: 1,
                            px: 2,
                            borderRadius: 1,
                          }}
                          children={`${watching?.first_name} ${watching?.last_name}`}
                        />
                      ))}
                    </Card>
                  </>
                )}
              </Grid>

              {/* <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Task Comments
                </Typography>
                {task?.task_comments?.map((item, index) => (
                  <>
                    {item?.comment && (
                      <Typography key={item?.id} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar alt={item?.user?.full_name} src={item?.user?.profile_picture} sx={{ mr: 2 }} />
                        <Typography variant="body2" color="text.secondary">
                          {item?.comment}
                        </Typography>
                      </Typography>
                    )}
                  </>
                ))}
              </Grid> */}
            </Grid>
            <Grid container spacing={3} style={{}}>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
                  Task Comments
                </Typography>
                <div style={{ display: 'flex', gap: 8, alignItems: 'center', marginTop: '1rem' }}>
                  <PopUpAvatarAssigned
                    fullName={`${user?.first_name} ${user?.last_name}`}
                    imageUrl={`${user?.profile_picture}`}
                  />
                  {/* <TextField fullWidth value={addComment} onChange={onChange} /> */}
                  <MentionsInput
                    className="mentions__suggestions__list"
                    value={addComment}
                    onChange={onChange}
                    style={{
                      suggestions: {
                        maxHeight: '300px',
                        overflowY: 'auto',
                        borderRadius: 8,
                        boxShadow: '2px 3px 5px rgba(0,0,0,0.5)',
                        padding: 12,
                        borderBottom: 1,
                      },
                    }}
                    renderSuggestion={renderSuggestion}
                  >
                    <Mention
                      data={userData}
                      style={{ overflowY: 'scroll' }}
                      renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
                        <div style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', padding: '5px 0' }}>
                          {highlightedDisplay}
                        </div>
                      )}
                    />
                    {/* {console.log('userdate', userData)} */}
                  </MentionsInput>
                  {addComment && (
                    <Button variant="contained" onClick={AddTaskComments}>
                      Save
                    </Button>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                {''}
              </Grid>
              <Grid item xs={12} md={6}>
                {showTaskCommnet &&
                  taskComments?.map((comment) => {
                    const dateTime = new Date(comment.created_at);

                    const formattedDate = new Intl.DateTimeFormat('en-GB', {
                      day: 'numeric',
                      month: 'short',
                      year: '2-digit',
                    }).format(dateTime);

                    const formattedTime = new Intl.DateTimeFormat('en-GB', {
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: false,
                    }).format(dateTime);

                    return (
                      <div
                        key={comment?.id}
                        style={{ display: 'flex', flexDirection: 'column', gap: 10, marginTop: '2rem' }}
                      >
                        {/* {console.log('date is null', comment?.start_date, 'this one', comment.created_at)} */}
                        <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                          <PopUpAvatarAssigned
                            fullName={comment.user?.full_name}
                            imageUrl={`https://internal-pms-dev.s3.ap-south-1.amazonaws.com/${comment?.user?.profile_picture}`}
                          />
                          <Typography variant="subtitle1" fontWeight="bold">
                            {comment.user?.full_name}
                          </Typography>
                          <Typography variant="subtitle2">{`${formattedDate} - ${formattedTime}`}</Typography>
                        </div>
                        <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                          <PopUpCommentLabel
                            color={palette.light.grey[200]}
                            paddX={8}
                            paddY={4}
                            labelRadius={6}
                            title={comment?.comment}
                            mentionName={comment?.user?.first_name}
                            spanColor={palette.light.grey[300]}
                            taskCommentId={comment?.id}
                            getTaskComments={getTaskComments}
                            loginUserId={comment?.user?.id}
                            auto_comment={comment?.auto_comment}
                          />
                        </div>
                      </div>
                    );
                  })}
              </Grid>
            </Grid>
            <KanbanWatchPopup open={openWatchPopup} onClose={handleWatchClose} task={task} callback={init} />
            <KanbanBlockPopup
              open={openBlockPopup}
              onClose={handleBlockPopupClose}
              task={task}
              column={column}
              reblock
              callback={init}
            />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
