import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import { NameInitialsAvatar } from 'react-name-initials-avatar';
import palette from 'src/theme/palette';

export const PopUpAvatarAssigned = ({ imageUrl, fullName }) => (
  <div>
    <Tooltip title={fullName || 'no name'} placement="top">
      <Typography variant="body2" color="text.secondary">
        {imageUrl ? (
          <img
            alt=""
            src={imageUrl}
            style={{ width: 32, height: 32, borderRadius: 50, objectFit: 'cover' }}
          />
        ) : (
          <NameInitialsAvatar
            name={fullName}
            size={32}
            textSize={10}
            bgColor={palette.light.warning.main}
            borderColor={palette.light.grey[900]}
          />
        )}
        {console.log('test', imageUrl)}
      </Typography>
    </Tooltip>
  </div>
);
