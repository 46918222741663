import Button from '@mui/material/Button';
// eslint-disable-next-line import/no-unresolved
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import AxiosAll from 'src/services/axiosAll';
import Iconify from '../Iconify';

export default function StartDialog({ task, getTasks }) {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    // eslint-disable-next-line no-debugger
    try {
      const data1 = {
        // planned_time: timeLog?.time,
      };
      const response = await AxiosAll('post', `/tasks/work-started/${task?.id}`, data1, token);
      const { data } = response;

      if (data.statusCode === 200) {
        enqueueSnackbar(data?.message, { variant: 'success' });
        getTasks();
      } else {
        enqueueSnackbar(data?.message, { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Button className="timeBTn" onClick={handleSubmit}>
        <Iconify icon={'ic:outline-not-started'} />
        Start
      </Button>
    </div>
  );
}
