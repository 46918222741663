import { Stack, Typography } from '@mui/material';
import { PopUpAvatarAssigned } from './popUpAvatarAssigned';
import { PopUpCreator } from './popUpCreator';

export const MembersAvatar = ({ fullNameCreator, creatorUrl, fullNameAssigned, assingedUrl }) => (
  <div>
    <Typography variant="caption">Members</Typography>
    <Stack direction="row" spacing={2}>
      <PopUpCreator fullName={fullNameCreator} imageUrl={creatorUrl} />
     {/* {fullNameAssigned===fullNameCreator?null:  */}
     <PopUpAvatarAssigned fullName={fullNameAssigned} imageUrl={assingedUrl} />
    {/* //  } */}
    </Stack>
  </div>
);
