import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';

// eslint-disable-next-line import/no-unresolved, import/extensions
import AxiosAll from 'src/services/axiosAll';
// components
// eslint-disable-next-line import/no-unresolved, import/extensions
import Page from '../../components/Page';
// eslint-disable-next-line import/no-unresolved, import/extensions
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
// eslint-disable-next-line import/no-unresolved, import/extensions
import UserNewEditForm from '../../sections/@dashboard/user/UserNewEditForm';

// ----------------------------------------------------------------------

export default function UserCreate() {
  // const { themeStretch } = useSettings();
  const { userDetails } = useSelector((state) => state.loginReducer);
  const params = useParams();
  const { pathname } = useLocation();
  const [currentUser, setCurrentUser] = useState({});
  const isEdit = pathname.includes('edit');

  const getUser = async () => {
    try {
      if (isEdit) {
        const res = await AxiosAll('get', `/users/${params?.id}`, null, userDetails.token);

        const data = res.data.data;
        const user = {
          id: data.id,
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          profile_picture: data.profile_picture,
          user_team_lead_id: data?.user_team_lead_id,
          user_manager_id: data?.user_manager_id,
          doj: data?.doj,
          dob: data?.dob,
          role: data.roles.map((role) => ({
            value: role.id,
            label: role.name,
          })),
          status: data.status,
          team: data.teams.map((team) => ({
            value: team.id,
            label: team.name,
          })),
          position: data.position,
        };
        setCurrentUser(user);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="User: Create a new user">
      <Container maxWidth={true ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Create a new user' : 'Edit user'}
          links={[
            { name: 'Dashboard', href: '/dashboard' },
            { name: 'User List', href: '/user/user-List' },
            { name: !isEdit ? 'New user' : currentUser?.first_name },
          ]}
        />

        <UserNewEditForm isEdit={isEdit} currentUser={isEdit && currentUser} />
      </Container>
    </Page>
  );
}
