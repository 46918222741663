import PropTypes from 'prop-types';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import { alpha, styled } from '@mui/material/styles';
// _mock
import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import Iconify from 'src/components/Iconify';
import Label from 'src/components/Label';
import AxiosAll from 'src/services/axiosAll';
import { FormProvider, RHFSelect, RHFTextField } from '../../components/hook-form';

// ----------------------------------------------------------------------
const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

CreateTaskStatus.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  currentStatus: PropTypes.object,
  isEdit: PropTypes.bool,
  getAllStatus: PropTypes.func,
};

export default function CreateTaskStatus({ open, onClose, isEdit, currentStatus, getAllStatus }) {
  const { userDetails } = useSelector((state) => state.loginReducer);

  const { enqueueSnackbar } = useSnackbar();

  const NewStatusSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    color: Yup.string().required('Color is required'),
    icon: Yup.string().required('Icon is required'),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentStatus?.name || '',
      description: currentStatus?.description || '',
      color: currentStatus?.color || '',
      icon: currentStatus?.color || ''
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStatus]
  );

  const methods = useForm({
    resolver: yupResolver(NewStatusSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    reset,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (isEdit && currentStatus) {
      reset(defaultValues);
    } else {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentStatus]);

  const onSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('name', values?.name);
      formData.append('description', values?.description);
      formData.append('color', values?.color);
      formData.append('icon', values?.icon)

      const response = await AxiosAll(
        'post',
        isEdit ? `/task-statuses/${currentStatus?.id}` : '/task-statuses',
        formData,
        userDetails.token
      );

      const { data } = response;

      if (data.statusCode === 200) {
        enqueueSnackbar(data?.message, { variant: 'success' });
        onClose();
        getAllStatus();
        reset();
      } else {
        enqueueSnackbar(data?.message, { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
    // onClose={() => {
    //   onClose();
    //   reset(defaultValues);
    // }}
    >
      <DialogTitle>Add New Task Status</DialogTitle>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={3}>
            <div>
              <LabelStyle>Name</LabelStyle>
              <RHFTextField name="name" placeholder="Task Status Name" />
            </div>
            <div>
              <LabelStyle>Description</LabelStyle>
              <RHFTextField name="description" placeholder="Task Status Description..." multiline rows={4} />
            </div>
            {values?.color && (
              <div>
                <LabelStyle>Preview</LabelStyle>
                <Label color={values?.color || 'default'} sx={{ width: '100%', height: 48, borderRadius: 2 }}>
                  {values?.name || 'sample text'}
                </Label>
              </div>
            )}
            <div>
              <LabelStyle>Color</LabelStyle>
              <RHFSelect name="color" placeholder="Choose color">
                <option value="">Select</option>
                <option value="default">Default</option>
                <option value="primary">Primary</option>
                <option value="secondary">Secondary</option>
                <option value="info">Info</option>
                <option value="success">Success</option>
                <option value="warning">Warning</option>
                <option value="error">Error</option>
              </RHFSelect>
            </div>

            {
              values?.icon && (
                <div>
                  <LabelStyle>Preview</LabelStyle>
                  <IconWrapperStyle
                    sx={{
                      color: (theme) => theme.palette[values?.color].dark,
                      backgroundImage: (theme) =>
                        `linear-gradient(135deg, ${alpha(theme.palette[values?.color].dark, 0)} 0%, ${alpha(
                          theme.palette[values?.color].dark,
                          0.24
                        )} 100%)`,
                    }}
                  >
                    <Iconify icon={values?.icon} width={24} height={24} />
                  </IconWrapperStyle>
                </div>
              )
            }
            <div>
              <LabelStyle>Icon</LabelStyle>
              <RHFSelect name="icon" placeholder="Choose Icon">
                <option value="">Select</option>
                <option value="fluent-mdl2:add-favorite">Star</option>
                <option value="fluent-mdl2:add-bookmark">Bookmark</option>
                <option value="fluent-mdl2:blocked">Block</option>
                <option value="fluent-mdl2:dislike-solid">Not approved</option>
              </RHFSelect>
            </div>
          </Stack>
        </DialogContent>

        <Divider />

        <DialogActions>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {isEdit ? 'Update' : 'Create'}
          </LoadingButton>
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => {
              onClose();
              reset(defaultValues);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
