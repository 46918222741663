import PropTypes from 'prop-types';
// @mui
import { Card, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
// eslint-disable-next-line import/no-unresolved
import Iconify from 'src/components/Iconify';
import cssStyles from '../../../../utils/cssStyles';
// components

// ----------------------------------------------------------------------

const OverlayStyle = styled('div')(({ theme }) => ({
  ...cssStyles().bgBlur({ blur: 2, color: theme.palette.primary.darker }),
  top: 0,
  zIndex: 8,
  content: "''",
  width: '100%',
  height: '100%',
  position: 'absolute',
}));

// ----------------------------------------------------------------------

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
};

export default function UserCard({ user, handleRemoveContact }) {
  const { name, cover, designation, avatarUrl, email, phone, dob, address } = user;

  return (
    <Card sx={{ textAlign: 'left', p: 2 }}>
      {/* <Box sx={{ position: 'relative' }}>
        <SvgIconStyle
          src="https://minimal-assets-api-dev.vercel.app/assets/icons/shape-avatar.svg"
          sx={{
            width: 144,
            height: 62,
            zIndex: 10,
            left: 0,
            right: 0,
            bottom: -26,
            mx: 'auto',
            position: 'absolute',
            color: 'background.paper',
          }}
        />
        <Avatar
          alt={name}
          src={avatarUrl}
          sx={{
            width: 64,
            height: 64,
            zIndex: 11,
            left: 0,
            right: 0,
            bottom: -32,
            mx: 'auto',
            position: 'absolute',
          }}
        />
        <OverlayStyle />
        <Image src={cover} alt={cover} ratio="16/9" />
      </Box> */}

      {/* add close icon */}
      <IconButton
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          zIndex: 11,
          color: 'text.disabled',
          '&:hover': {
            color: 'text.primary',
          },
        }}
        onClick={() => {
          handleRemoveContact(user.id);
        }}
      >
        <Iconify icon={'eva:close-fill'} width={20} height={20} />
      </IconButton>

      <Typography variant="body2">Name: {name}</Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        Designation: {designation}
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        Email: {email}
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        Phone No: {phone}
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        DOB: {dob}
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        Address: {address}
      </Typography>

      {/* <Stack alignItems="center">
        <SocialsButton initialColor sx={{ my: 2.5 }} />
      </Stack> */}

      {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

      {/* <Box sx={{ py: 3, display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
        <div>
          <Typography variant="caption" component="div" sx={{ mb: 0.75, color: 'text.disabled' }}>
            Follower
          </Typography>
          <Typography variant="subtitle1">{fShortenNumber(follower)}</Typography>
        </div>

        <div>
          <Typography variant="caption" component="div" sx={{ mb: 0.75, color: 'text.disabled' }}>
            Following
          </Typography>
          <Typography variant="subtitle1">{fShortenNumber(following)}</Typography>
        </div>

        <div>
          <Typography variant="caption" component="div" sx={{ mb: 0.75, color: 'text.disabled' }}>
            Total Post
          </Typography>
          <Typography variant="subtitle1">{fShortenNumber(totalPost)}</Typography>
        </div>
      </Box> */}
    </Card>
  );
}
