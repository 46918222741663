import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-duplicates
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material';
// utils
// eslint-disable-next-line import/no-duplicates
import { useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import { styled } from '@mui/material/styles';
// eslint-disable-next-line import/no-unresolved
// import AddStartupContacts from 'src/pages/startup/Dialog';
// eslint-disable-next-line import/no-unresolved
import axios from 'axios';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { newServer } from 'src/core';
import AddStartupContacts from 'src/pages/Startup/Dialog';
import { fData } from '../../../utils/formatNumber';

// routes
// components
import { FormProvider, RHFSelect, RHFTextField, RHFUploadAvatar } from '../../../components/hook-form';
import Label from '../../../components/Label';
import { UserCard } from './cards';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

StartupNewEditForm.propTypes = {
  isEdit: PropTypes.bool,
  currentStartup: PropTypes.object,
};

export default function StartupNewEditForm({ isEdit, currentStartup }) {
  const navigate = useNavigate();
  const params = useParams();

  const [open, setOpen] = useState(false);
  const [startupContacts, setStartupContacts] = useState([]);

  const { userDetails } = useSelector((state) => state.loginReducer);

  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    status: Yup.string().required('Status is required'),
    description: Yup.string().required('Description is required'),
    avatarUrl: Yup.mixed().test('required', 'Avatar is required', (value) => value !== ''),
    startDate: Yup.string().required('Start Date is required'),
  });

  const defaultValues = useMemo(
    () => ({
      title: currentStartup?.name || '',
      avatarUrl: currentStartup?.logo || '',
      status: currentStartup?.status || 'active',
      startDate: currentStartup?.start_date || '',
      description: currentStartup?.description || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStartup]
  );
console.log("startupContacts", startupContacts)
  useEffect(() => {
    if (currentStartup?.contacts_list?.length > 0) {
      setStartupContacts(currentStartup?.contacts_list);
    }
  }, [currentStartup]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (isEdit && currentStartup) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentStartup]);

  const onSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('name', values.title);
      formData.append('status', values.status);
      formData.append('description', values.description);
      formData.append('start_date', moment(values.startDate).format('YYYY-MM-DD'));
      if (typeof values.avatarUrl === 'object') {
        formData.append('logo', values.avatarUrl);
      }

      // pass object to formData
      const startupContactsObj = {};
      startupContacts.forEach((item, index) => {
        startupContactsObj[`startup_contacts[${index}][id]`] = item.id;
        startupContactsObj[`startup_contacts[${index}][name]`] = item.name;
        startupContactsObj[`startup_contacts[${index}][email]`] = item.email;
        startupContactsObj[`startup_contacts[${index}][phone]`] = item.phone;
        startupContactsObj[`startup_contacts[${index}][designation]`] = item.designation;
        startupContactsObj[`startup_contacts[${index}][dob]`] = moment(item.dob).format('YYYY-MM-DD');
        startupContactsObj[`startup_contacts[${index}][address]`] = item.address;
      });
      Object.keys(startupContactsObj).forEach((key) => {
        formData.append(key, startupContactsObj[key]);
      });

      const createStartupURL = `${newServer}/startups`;
      const updateStartupURL = `${newServer}/startups/${params.id}`;

      const response = await axios.post(isEdit ? updateStartupURL : createStartupURL, formData, {
        headers: {
          contentType: 'multipart/form-data',
          Authorization: `Bearer ${userDetails.token}`,
        },
      });

      const { data } = response;

      if (data.statusCode === 200) {
        setStartupContacts([]);
        enqueueSnackbar(data?.message, { variant: 'success' });
        navigate('/startup/startup-list', { replace: true });
      }

      reset();
    } catch (error) {
      console.error(error);
      const { data } = error?.response;
      enqueueSnackbar(data?.message, { variant: 'error' });
    }
  };

  const handleRemoveContact = (id) => {
    const newContacts = startupContacts.filter((item) => item.id !== id);
    setStartupContacts(newContacts);
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'avatarUrl',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <>
      <AddStartupContacts open={open} onClose={() => setOpen(false)} setStartupContacts={setStartupContacts} />

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card sx={{ py: 10, px: 3 }}>
              {isEdit && (
                <Label
                  color={values.status !== 'active' ? 'error' : 'success'}
                  sx={{ textTransform: 'uppercase', position: 'absolute', top: 24, right: 24 }}
                >
                  {values.status}
                </Label>
              )}

              <Box sx={{ mb: 5 }}>
                <RHFUploadAvatar
                  name="avatarUrl"
                  maxSize={3145728}
                  onDrop={handleDrop}
                  helperText={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 2,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.secondary',
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, *.gif
                      <br /> max size of {fData(3145728)}
                    </Typography>
                  }
                />
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
              >
                <div>
                  <LabelStyle>Startup Title</LabelStyle>
                  <RHFTextField name="title" placeholder="Title" />
                </div>

                <div>
                  <LabelStyle>Startup Status</LabelStyle>
                  <RHFSelect name="status" placeholder="Status">
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </RHFSelect>
                </div>

                <div>
                  <LabelStyle>Start date</LabelStyle>
                  <RHFTextField name="startDate" type="date" />
                </div>
              </Box>

              <Stack sx={{ mt: 3 }}>
                <LabelStyle>Description</LabelStyle>
                <RHFTextField name="description" placeholder="Write the description here..." multiline rows={4} />

                <Box
                  sx={{
                    mt: 3,
                    display: 'grid',
                    gap: 3,
                    gridTemplateColumns: {
                      xs: 'repeat(1, 1fr)',
                      sm: 'repeat(2, 1fr)',
                      md: 'repeat(2, 1fr)',
                    },
                  }}
                >
                  {startupContacts.map((user) => (
                    <UserCard key={user.id} user={user} handleRemoveContact={handleRemoveContact} />
                  ))}
                </Box>
              </Stack>

              <Stack alignItems="center" spacing={{ xs: 3, sm: 2,}} direction={ {xs: 'column', sm: 'row'}} justifyContent={'space-between'} sx={{ mt: 3 }} >
                <Button variant="contained" onClick={() => setOpen(true)} className='btnwidth'>
                  Add Startup Contacts
                </Button>
                <Stack direction={ {xs: 'column', sm: 'row'}} spacing={{ xs: 3, sm: 2,}} className='btnwidth' >
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting} >
                    {!isEdit ? 'Create ' : 'Save Changes'}
                  </LoadingButton>
                  {isEdit && (
                    <Button
                      sx={{ ml: 2 }}
                      variant="contained"
                      onClick={() => {
                        navigate('/startup/startup-list');
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}
