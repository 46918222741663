import { AnimatePresence, m } from 'framer-motion';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Autocomplete,
  Backdrop,
  Box,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// _mock
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import axios from 'axios';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import Iconify from 'src/components/Iconify';
import { newServer } from 'src/core';
import AxiosAll from 'src/services/axiosAll';
import cssStyles from 'src/utils/cssStyles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import MobilePicker from 'src/components/Timepicker';
import { FormProvider, RHFSelect, RHFTextField } from '../../components/hook-form';

// ----------------------------------------------------------------------

const RootStyle = styled(m.div)(({ theme }) => ({
  ...cssStyles(theme).bgBlur({ color: theme.palette.background.paper, opacity: 0.92 }),
  top: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  position: 'fixed',
  // overflowY: 'áuto',
  // maxHeight: '200',
  // width: window.innerWidth <= 450 ? '55vw' : '28vw',
  flexDirection: 'column',
  margin: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  zIndex: theme.zIndex.drawer + 3,
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  boxShadow: `-24px 12px 32px -4px ${alpha(
    theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
    0.16
  )}`,
}));

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

SideAddTask.propTypes = {
  userData: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  getUserWiseData: PropTypes.func,
};

export default function SideAddTask({ open, setOpen, userData, getUserWiseData }) {
  const { userDetails, userList, projectList, priorityList } = useSelector((state) => state?.loginReducer);
  const { token } = userDetails;
  const { enqueueSnackbar } = useSnackbar();
  const [projectData, setProjectData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [priorityData, setPriorityData] = useState([]);
  const [label, setLabels] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionIds, setSelectedOptionIds] = useState([]);
  const [usersData, setUserData] = useState([]);
  const [dueTime, setDueTime] = useState('00:00');
  const navigate = useNavigate();
  const isLead = userDetails?.user?.roles?.some((role) => role.id === 3);
  const isTester = userDetails?.user?.roles?.some((role) => role.id === 4);
  // console.log("dueTime",dueTime)
  const NewTeamSchema = Yup.object().shape({
    project: Yup.string().required('Project is required'),
    task: Yup.string().required('Task title is required'),
    // status: Yup.string().required('Status is required'),
    // predecessorTask: Yup.string().required('Predecessor task is required'),
    // successorTask: Yup.string().required('Successor task is required'),
    // description: Yup.string().required('Description is required'),
    assignUser: Yup.string().required('Assign user is required'),
    priority: Yup.string().required('Priority is required'),
    plannedHour: Yup.string()
      .min(5, 'Must be in the form of HH:MM')
      .required('Planned hour is required')
      .test('is-before-9', 'Planned time should be less than 9 hours.', (value) => {
        if (!value) return true;

        const hour = parseInt(value.split(':')[0], 10);
        const minute = parseInt(value.split(':')[1], 10);

        // Exclude '00:00'
        return !(hour === 0 && minute === 0) && hour <= 8;
      }),
    startDate: Yup.string().required('Start date is required'),
  });

  const defaultValues = useMemo(
    // eslint-disable-next-line arrow-body-style
    () => {
      return {
        task: '',
        description: '',
        plannedHour: '01:00',
        refUrl: '',
        refTitle: '',
        startDate: moment(new Date()).format('YYYY-MM-DD') || '',
        dueDate: moment(new Date()).format('YYYY-MM-DD') || '',

        project: '',
        status: '',
        predecessorTask: '',
        successorTask: '',
        assignUser: '',
        priority: '',
        visiblity: 'visible',
        siblings: '0',
        parentTask: '',
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [projectList, priorityList]
  );
  useEffect(() => {
    setValue('project', projectList[0]?.id);
    setValue('priority', priorityList[0]?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectList, priorityList]);

  const methods = useForm({
    resolver: yupResolver(NewTeamSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    control,
    reset,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Time handlle function
  const handleTimeChange = (e, onChange) => {
    const value = e.target.value;
    // increase hours by 1 if minutes are greater than 60
    if (value.split(':')[1] > 59) {
      // if hours is less than 10, add 0 before it
      const hours =
        parseInt(value.split(':')[0], 10) + 1 < 10
          ? `0${parseInt(value.split(':')[0], 10) + 1}`
          : parseInt(value.split(':')[0], 10) + 1;
      // if minutes is less than 10, add 0 before it
      const minutes =
        parseInt(value.split(':')[1], 10) - 60 < 10
          ? `0${parseInt(value.split(':')[1], 10) - 60}`
          : parseInt(value.split(':')[1], 10) - 60;
      onChange(`${hours}:${minutes}`);
    } else {
      onChange(value);
    }
  };

  const handleAddTaskClick = () => {
    // Your data to be passed to the '/kanban/add-task' route
    handleClose();
    navigate('/kanban/add-task', {
      state: { data: values, label: selectedOptions, labelsId: selectedOptionIds, dueTimeData: dueTime },
    });
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('project_id', values?.project);
      formData.append('title', values?.task);
      formData.append('status', 1);
      formData.append('description', '');
      formData.append('startup_visibility', '');
      formData.append('start_date', moment(values.startDate).format('YYYY-MM-DD'));
      formData.append('due_date', moment(values?.dueDate).format('YYYY-MM-DD'));
      formData.append('predecessor_task_id', '');
      formData.append('following_task_id', '');
      // eslint-disable-next-line no-unused-expressions
      formData.append('assigned_user_id', values?.assignUser);
      formData.append('priority', values?.priority ? values?.priority : 4);
      formData.append('planned_time_format', values?.plannedHour);
      formData.append('reference_url', '');
      formData.append('reference_title', '');
      formData.append('parent_task_id', 0);
      formData.append('has_siblings', '');
      formData.append('due_time', dueTime);
      formData.append(`labels`, selectedOptionIds);

      const createStartupURL = `${newServer}/tasks`;

      const response = await axios.post(createStartupURL, formData, {
        headers: {
          contentType: 'multipart/form-data',
          Authorization: `Bearer ${userDetails.token}`,
        },
      });
      const { data } = response;

      if (data.statusCode === 201) {
        setLoading(false);
        enqueueSnackbar(data?.message, { variant: 'success' });
        getUserWiseData('team_tasks');
        setSelectedOptionIds([]);
        setSelectedOptions([]);
        if (isLead || isTester) {
          navigate('/kanban', { replace: true });
        } else {
          navigate('/tasks/status-wise', { replace: true });
        }
        handleClose();
        reset();

        setOpen(false);
      } else {
        enqueueSnackbar(data?.message, { variant: 'error' });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      const { data } = error?.response;
      enqueueSnackbar(data?.message, { variant: 'error' });
      setLoading(false);
    }
  };

  const handleClose = () => {
    reset(defaultValues);
    setOpen(false);
  };

  // Common dropdowns
  // const getPriority = async () => {
  //   const res = await AxiosAll('get', '/common/task-priorities', {}, token);
  //   if (res?.status === 200) {
  //     const newData = res?.data;
  //     setPriorityData(newData);
  //   }
  // };
  // const getProjects = async () => {
  //   const res = await AxiosAll('get', `/common/projects`, {}, token);
  //   if (res?.status === 200) {
  //     const newData = res?.data;
  //     setProjectData(newData);
  //   }
  // };

  const getlabels = async () => {
    const res = await AxiosAll('get', `/labels`, {}, token);
    if (res?.status === 200) {
      const newData = res?.data?.data;
      setLabels(newData);
    }
  };

  // const getUser = async () => {
  //   const res = await AxiosAll('get', `/common/users`, {}, token);
  //   if (res?.status === 200) {
  //     const newData = res?.data;
  //     setUserData(newData);
  //   }
  // };

  const handleChange = (event, value) => {
    setSelectedOptions(value);
    // Store selected option IDs in another state
    const selectedIds = value.map((option) => option.id);
    setSelectedOptionIds(selectedIds);
  };

  useEffect(() => {
    if (open) {
      // getProjects();
      // getPriority();
      getlabels();
      // getUser();
    }
    // getStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <Backdrop open={open} sx={{ background: 'transparent', zIndex: (theme) => theme.zIndex.drawer + 1 }} />

      <AnimatePresence>
        {open && (
          <>
            <RootStyle
              className="sidebar-inputfield"
              sx={{
                width: '500px',
                '@media screen and (max-width: 600px)': {
                  width: '80vw',
                },
                overflow: 'auto',
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ py: 0.5, pr: 1, pl: 2.5 }}
              >
                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                  Add new task
                </Typography>

                <IconButton onClick={handleClose}>
                  <Iconify icon={'eva:close-fill'} width={20} height={20} />
                </IconButton>
              </Stack>

              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <DialogContent
                // style={{ overflowY: 'scroll', height: ' 85vh' }}
                >
                  <Stack spacing={3}>
                    {/* Project */}
                    <Stack spacing={3} direction={'row'}>
                      <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                        <LabelStyle>Project</LabelStyle>
                        <RHFSelect name="project">
                          <option value={''}>Select</option>
                          {projectList?.map((item, index) => (
                            <option value={item?.id} key={index}>
                              {item?.title}
                            </option>
                          ))}
                        </RHFSelect>
                      </Box>

                      <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                        <LabelStyle>Assign User</LabelStyle>
                        <RHFSelect name="assignUser">
                          <option value={''}>Select</option>
                          {userList?.map((item, index) => (
                            <option value={item?.id} key={index}>{`${item?.first_name} ${item?.last_name}`}</option>
                          ))}
                        </RHFSelect>
                      </Box>
                    </Stack>

                    {/* Title */}
                    <div>
                      <LabelStyle>Title</LabelStyle>
                      <RHFTextField name="task" type="text" placeholder="Enter task title" />
                    </div>

                    {/* <div >
                      <LabelStyle>Description</LabelStyle>
                      <RHFEditor simple name="description" />
                    </div> */}

                    {/* time */}
                    <div>
                      <Stack direction={{ xs: 'column', md: 'column' }}>
                        <LabelStyle>Estimate time (in hours and minutes)</LabelStyle>
                        <Controller
                          name="plannedHour"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <InputMask
                              maskChar={null}
                              mask="99:99"
                              value={field.value}
                              onChange={(e) => handleTimeChange(e, field.onChange)}
                            >
                              {(inputProps) => (
                                <TextField
                                  {...inputProps}
                                  error={!!error}
                                  fullWidth
                                  helperText={error?.message}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Typography variant="body2">HH:MM</Typography>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            </InputMask>
                          )}
                        />
                      </Stack>
                    </div>
                    <Stack spacing={3} direction={{ xs: 'column', md: 'row' }}>
                      {/* Start Date */}
                      <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                        <LabelStyle>Start Date</LabelStyle>
                        <RHFTextField min={new Date().toISOString().split('T')[0]} name="startDate" type="date" />
                      </Box>

                      {/* Due Date */}
                      <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                        <LabelStyle>Due Date</LabelStyle>
                        <RHFTextField min={values?.startDate} name="dueDate" type="date" />
                      </Box>
                    </Stack>
                    <div style={{ flexDirection: 'row' }}>
                      <LabelStyle>Due TIme</LabelStyle>
                      <MobilePicker time={dueTime} min handleTime={setDueTime} />
                      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['TimePicker']} >
                          <TimePicker time={dueTime} min handleTime={setDueTime} sx={{minWidth: '100% !important'}} />
                        </DemoContainer>
                      </LocalizationProvider> */}
                    </div>

                    {/* Priority */}
                    <Stack spacing={3} direction={'row'}>
                      <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                        <LabelStyle>Priority</LabelStyle>
                        <RHFSelect name="priority">
                          <option value={''}>Select</option>
                          {priorityList?.map((item, index) => (
                            <option value={item?.id} key={index}>
                              {item?.name}
                            </option>
                          ))}
                        </RHFSelect>
                      </Box>

                      <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                        <LabelStyle>Task Label</LabelStyle>
                        <Autocomplete
                          multiple
                          id="checkboxes-tags-demo"
                          options={label}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.name}
                          onChange={handleChange}
                          value={selectedOptions}
                          defaultChecked={selectedOptions}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <FormControlLabel
                                style={{ width: '100%', color: option?.color }}
                                control={<Checkbox checked={selected} />}
                                label={option.name}
                              />
                            </li>
                          )}
                          style={{ width: '100%' }}
                          renderInput={(params) => <TextField {...params} variant="outlined" label="Select Options" />}
                        />
                      </Box>
                    </Stack>

                    <Box display={'flex'} width={'100%'} justifyContent={'end'}>
                      <Link
                        variant="contained"
                        startIcon={<Iconify icon={'eva:plus-fill'} />}
                        //   component={RouterLink}
                        //   to={'/kanban/add-task'}
                        onClick={handleAddTaskClick}
                        style={{ cursor: 'pointer', textAlign: 'right', width: 'fit-content' }}
                      >
                        Advance Options
                      </Link>
                    </Box>
                  </Stack>
                </DialogContent>

                <DialogActions>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    style={{ cursor: 'pointer', textAlign: 'right', width: '100%' }}
                    loading={loading}
                  >
                    Create
                  </LoadingButton>
                </DialogActions>
              </FormProvider>
            </RootStyle>
          </>
        )}
      </AnimatePresence>
    </div>
  );
}
