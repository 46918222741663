import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-duplicates
import { useLocation } from 'react-router-dom';
// @mui
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  FormControlLabel,
  MenuItem,
  Stack,
  Switch,
  Table,
  TableBody,
  TablePagination,
  TextField,
} from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-duplicates

// eslint-disable-next-line import/no-unresolved
import Iconify from 'src/components/Iconify';
import { LogTableToolbar } from 'src/sections/@dashboard/logs/list';
import MoMRow from 'src/sections/@dashboard/mom';
import AxiosAll from 'src/services/axiosAll';
import useSettings from '../../hooks/useSettings';
import useTable from '../../hooks/useTable';
// _mock_
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { TableHeadCustom, TableNoData, TableSkeleton } from '../../components/table';
import AddMOM from './AddMOM';
// sections

const TABLE_HEAD = [
  { id: 'task_title', label: 'Title', align: 'left' },
  { id: 'created_by', label: 'CreatedBy', align: 'left' },
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'time', label: 'Time', align: 'center' },
  { id: 'project', label: 'Project', align: 'left' },
  { id: 'id', label: 'Developers', align: 'left' },
  { id: 'id', label: 'Export', align: 'left' },
];

// ----------------------------------------------------------------------

export default function UserMoM() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token, user } = userDetails;
  // console.log("userDetails", userDetails)
  const location = useLocation();
  const { state } = location;
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [projectId, setProjectId] = useState('');
  const [taskId, setTaskId] = useState('');
  const [filterId, setFilterId] = useState('');
  const [count, setCount] = useState(0);
  const [editTask, setEditTask] = useState();
  const [dateFilter, setDate] = useState();
  const [filterWork, setFilterWork] = useState('all');
  const [userID, setUserID] = useState('');
  const [query, setQuery] = useState();
  const [editOpen, setEditOpen] = useState(false);
  const [detail, setDetail] = useState({});
  const [project, setProject] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [momOpen, setMomOpen] = useState(false);

  const handleFilterName = (filterId) => {
    setFilterId(filterId);
    setPage(0);
  };

  const handleFilterRole = (event) => {
    setFilterWork(event.target.value);
  };

  const getMinutes = async () => {
    setLoading(true);
    setTableData([]);
    const response = await AxiosAll(
      'get',
      `/minutes-of-meeting?query=${query || ''}&page=${page + 1}&project_id=${projectId}&date=${startDate}`,
      {},
      token
    );
    if (response?.data?.statusCode === 200) {
      setLoading(false);
      setTableData(response?.data?.data);
      setCount(response?.data?.data?.length);
    } else {
      setCount();
      setLoading(false);
    }
  };

  const isNotFound = (!tableData?.length && !!filterId) || (!tableData?.length && !!filterWork) || !tableData?.length;

  useEffect(() => {
    getMinutes(dateFilter);
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editOpen, query, projectId, taskId, dateFilter, setDate, userID, page, startDate]);

  // current date logic

  const currentDate = new Date().toISOString().split('T')[0];
  const storedDate = localStorage.getItem('loginDate');
  const storedProjectList = localStorage.getItem('projectList');

  const getProject = async () => {
    if (storedDate === currentDate) {
      // If dates match and local storage has data, use it
      setProject(JSON.parse(storedProjectList));
    } else {
      const response = await AxiosAll('get', `/common/projects`, {}, token);
      const { data } = response;
      if (response?.status === 200) {
        setProject(data);
        localStorage.setItem('projectList', JSON.stringify(data));
        localStorage.setItem('loginDate', currentDate); // Save current date
      }
    }
  };

  useEffect(() => {
    getProject();
  }, []);

  const handleProject = (e) => {
    // eslint-disable-next-line no-debugger
    setProjectId(e.target.value);
  };
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  return (
    <Page title="MOM : List">
      <AddMOM setOpen={setMomOpen} open={momOpen} getProjects={getMinutes} project={project} />
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="List of M.O.M."
          links={[{ name: 'Tasks', href: '/kanban' }, { name: 'Minutes of Meetings' }]}
          action={
            (userDetails?.user?.role_id === 3 || userDetails?.user?.role_id === 4) && (
              <Button
                variant="contained"
                // component={RouterLink}
                // to={'/tasks/add-task'}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
                onClick={() => window.open('/tasks/minuteofMeeting', '_blank')}
              >
                Add MOM
              </Button>
            )
          }
        />

        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} mb={2} style={{ minWidth: '100%', width: '400px' }}>
          <TextField
            fullWidth
            select
            label="Project"
            value={projectId}
            onChange={handleProject}
            SelectProps={{
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260 } },
              },
            }}
            sx={{
              maxWidth: { sm: 240 },
              textTransform: 'capitalize',
              mr: '0.5rem',
            }}
          >
            <MenuItem
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
              key={''}
              value={''}
            >
              Select
            </MenuItem>
            {project?.map((option) => (
              <MenuItem
                key={option?.id}
                value={option?.id}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}
              >
                {option?.title}
              </MenuItem>
            ))}
          </TextField>

          <TextField id="date1" type="date" value={startDate} onChange={handleStartDateChange} />
        </Stack>

        <Card className="main_container">
          <Divider />
          <LogTableToolbar
            filterId={filterId}
            filterWork={filterWork}
            onFilterName={handleFilterName}
            onFilterRole={handleFilterRole}
            search="minutes"
            setQuery={setQuery}
          />

          <Scrollbar>
            <Table size={dense ? 'small' : 'medium'}>
              <TableHeadCustom
                hideCheckbox
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length}
                numSelected={selected?.length}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id)
                  )
                }
              />
              {loading ? (
                <>
                  <TableSkeleton />
                  <TableSkeleton />
                  <TableSkeleton />
                  <TableSkeleton />
                  <TableSkeleton />
                </>
              ) : (
                <TableBody>
                  {tableData?.map((row) => (
                    <MoMRow key={row.id} row={row} Userid={user?.id} />
                  ))}
                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              )}
            </Table>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
