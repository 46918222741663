import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import { Box, Button, Card, CardContent, Container, Divider, Grid, Typography } from '@mui/material';
// hooks
// eslint-disable-next-line import/no-unresolved
import moment from 'moment';
import { useSelector } from 'react-redux';
import AxiosAll from 'src/services/axiosAll';
import useSettings from '../../hooks/useSettings';
import useTabs from '../../hooks/useTabs';
// _mock_
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
// sections

// ----------------------------------------------------------------------

export default function ProjectDetails() {
  const { themeStretch } = useSettings();

  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [projectDetails, setProjectDetails] = useState([]);

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');

  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;

  const getProjectDetail = async () => {
    setLoading(true);
    const response = await AxiosAll('get', `/projects/${params?.id}`, {}, token);
    const { data } = response;

    if (data?.statusCode === 200) {
      setProjectDetails(data?.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    getProjectDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="Project: Detail">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Project Details"
          links={[{ name: 'Dashboard', href: '/dashboard' }, { name: 'Projects' }, { name: 'Details' }]}
        />
        <Card>
          <Typography variant="h4" sx={{ p: 3 }}>
            {projectDetails?.title}
          </Typography>
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Description
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {projectDetails?.description}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Start Date
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {moment(projectDetails?.start_date).format("D MMM YYYY")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Due Date
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {moment(projectDetails?.due_date).format("D MMM YYYY")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Project Managers
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {projectDetails?.project_managers?.map((user) => (
                    <div key={user.id}>
                      {user.first_name} {user.last_name}
                    </div>
                  ))}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Status
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {projectDetails?.project_status?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Members
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {projectDetails?.users?.map((user) => (
                    <div key={user.id}>
                      {user.first_name} {user.last_name}
                    </div>
                  ))}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Visibility
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {projectDetails?.visibility}
                </Typography>
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Created By
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {projectDetails?.created_by}
                </Typography>
              </Grid> */}
            </Grid>
          </CardContent>
          <Divider />
          <Box sx={{ p: 3 }}>
            <Button
              variant="contained"
              onClick={() => {
                navigate(`/project/edit/${params?.id}`);
              }}
            >
              Edit Project
            </Button>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
