/* eslint-disable prefer-template */
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Box, Button, Card, CardContent, Container, Divider, Grid, Stack, Typography } from '@mui/material';
// hooks
// eslint-disable-next-line import/no-unresolved
import { jsPDF } from 'jspdf';
import moment from 'moment';
import { useSelector } from 'react-redux';
import LoadingScreen from 'src/components/LoadingScreen';
import AxiosAll from 'src/services/axiosAll';
import useSettings from '../../hooks/useSettings';
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import EmployeeProfileCard from './EmployeeProfileCard';

const Profile = () => {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const charRef = useRef();
  const [profileData, setProfileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const { state } = useLocation();
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setLoading(true);
        const response = await AxiosAll('get', `/profile`, null, token);
        const { data } = response;
        setProfileData(data?.data);
        setLoading(false);
      } catch (err) {
        console.log('err', err);
        setLoading(false);
      }
    };
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // loading skeleton
  if (loading) {
    return <LoadingScreen />;
  }

  const printDiv = () => {
    // Get the content of the printcard element
    const printContents = document.getElementById('printcard').innerHTML;

    // Create a header with the file name
    const header = `${profileData?.first_name} ${profileData?.last_name}__${profileData?.employee_id}`;
    const printableContent = 'Employee Card' + printContents;

    // Create a new window for printing
    const printWindow = window.open('', '_blank');

    // Set the content of the new window
    printWindow.document.open();
    printWindow.document.write('<html><head><title>' + header + '</title></head><body>');
    printWindow.document.write(printableContent);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    // Trigger the print dialog
    printWindow.print();
  };

  return (
    <Page title="Profile">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs heading="Profile" links={[{ name: 'Dashboard', href: '/dashboard' }, { name: 'Profile' }]} />
        <Card>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: '1rem',
              padding: '0 1rem',
              alignItems: 'center',
            }}
          >
            <Typography variant="h4" sx={{ p: 3 }}>
              Profile
            </Typography>
            {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}> */}
            {/* <Button
              onClick={() => navigate('/employee-card', { state: { currentUser: profileData } })}
              variant="contained"
              sx={{ display: 'flex', justifyContent: 'flex-end', height: 'áuto', width: 'fit-content' }}
            >
              Employee Card
            </Button> */}
          </div>
          {/* </Box> */}
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    py: 3,
                  }}
                >
                  <Box
                    component="img"
                    alt="profile"
                    src={profileData?.profile_picture}
                    sx={{
                      width: 128,
                      height: 128,
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                  />
                  <Typography variant="h5" sx={{ mt: 3, mb: 0.5 }}>
                    {profileData?.first_name} {profileData?.last_name}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                    {profileData?.position}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {profileData?.email}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
                  <Button
                    onClick={() => navigate('/profile/edit', { state: { currentUser: profileData } })}
                    variant="contained"
                  >
                    Edit Profile
                  </Button>
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      First Name
                    </Typography>
                    <Typography variant="body2">{profileData?.first_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      Last Name
                    </Typography>
                    <Typography variant="body2">{profileData?.last_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      Email
                    </Typography>
                    <Typography variant="body2" style={{ wordWrap: 'break-word' }}>
                      {profileData?.email}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      Position
                    </Typography>
                    <Typography variant="body2">{profileData?.position}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      Status
                    </Typography>
                    <Typography variant="body2">{profileData?.status}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      Required Working Hrs
                    </Typography>
                    <Typography variant="body2">{profileData?.working_hours}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      Date of joining
                    </Typography>
                    <Typography variant="body2">
                      {profileData?.doj ? moment(profileData?.doj).format('yyyy-MM-DD') : 'N/A'}
                    </Typography>
                  </Grid>{' '}
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      Date of birth
                    </Typography>
                    <Typography variant="body2">
                      {profileData?.dob ? moment(profileData?.dob)?.format('yyyy-MM-DD') : 'N/A'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6}>
            <Card>
              <Typography variant="h4" sx={{ p: 3 }}>
                Teams
              </Typography>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  {profileData?.teams?.map((team) => (
                    <Grid item xs={12} md={6} key={team.id}>
                      <Typography variant="body2">{team.name}</Typography>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <Typography variant="h4" sx={{ p: 3 }}>
                Roles
              </Typography>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  {profileData?.roles?.map((role) => (
                    <Grid item xs={12} md={6} key={role.id}>
                      <Typography variant="body2">{role.name}</Typography>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <Typography variant="h4" sx={{ p: 3 }}>
                Projects
              </Typography>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  {profileData?.projects?.map((project) => (
                    <Grid item xs={12} md={6} key={project.id}>
                      <Typography
                        variant="body2"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => navigate(`/project/details/${project?.id}`)}
                      >
                        {project.title}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
                <Typography variant="h4" sx={{ p: 3 }}>
                  Employee Card
                </Typography>
                <Button sx={{ mr: 3 }} onClick={printDiv}>
                  Download
                </Button>
              </Stack>
              <Divider />
              <div id="printcard" style={{ margin: '25px auto' }}>
                <EmployeeProfileCard
                  charRef={charRef}
                  userName={profileData?.first_name}
                  employeeId={profileData?.employee_id}
                  userSurname={profileData?.last_name}
                  userRole={profileData?.position}
                  userEmail={profileData?.email}
                  userProfile={profileData?.profile_picture}
                />
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Profile;
