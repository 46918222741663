import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// @mui
import { Box, MenuItem, TextField } from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/services/axiosAll';
// import { Calendar, momentLocalizer } from 'react-big-calendar';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
//
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import listGridPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from 'moment';
// eslint-disable-next-line import/no-unresolved, import/extensions
import 'react-big-calendar/lib/sass/styles.scss';
// eslint-disable-next-line import/no-unresolved, import/extensions
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { Stack } from '@mui/system';
import LogList from 'src/sections/@dashboard/time-Sheet/logList';
import DateInputFelid from 'src/components/dateInputFelid';
import SinglieLogDetail from 'src/sections/@dashboard/time-Sheet/singlelogdetail';
import useSettings from '../../hooks/useSettings';
import useTable from '../../hooks/useTable';
import useTabs from '../../hooks/useTabs';
// _mock_
// components
import Page from '../../components/Page';
import AddLogsForm from '../logs/addLogs';

// sections

const ColoredDateCellWrapper = ({ children }) =>
  // eslint-disable-next-line no-undef
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'lightblue',
    },
  });

export default function TimeSheet({openNew,setOpenNew}) {
  const { page, order, orderBy } = useTable();

  const { themeStretch } = useSettings();

  const { userDetails ,userList } = useSelector((state) => state.loginReducer);
  const location = useLocation();
  const { token, user } = userDetails;
  const navigate = useNavigate();
  // const localizer = momentLocalizer(moment);
  const { state } = location;
  const [tableData, setTableData] = useState([]);
  const [allTask, setAllTask] = useState([]);
  const [filterId, setFilterId] = useState('');
  const [myEvents, setMyEvents] = useState([]);
  const [filterWork, setFilterWork] = useState('all');
  const [search, setSearch] = useSearchParams();

  const [users, setUser] = useState();
  const [project, setProject] = useState();

  const [userId, setUserId] = useState(search.get('user_id') || '');
  const [projectId, setProjectId] = useState(search.get('project_id') || '');
  const [startDate, setStartDate] = useState(
    search.get('start_date') || moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(search.get('end_date') || moment(new Date()).format('YYYY-MM-DD'));

  // Dialogue boxes
  const [open, setOpen] = useState(false);
  const [listopen, setListopen] = useState(false);

  // id or dates for dialogue
  const [idToOpen, setIdToOpen] = useState();
  const [clickedDate, setClickedDate] = useState('');
  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');

  // date click
  const handleDateClick = (info) => {
    if (startDate <= info.dateStr && info.dateStr <= endDate) {
      setListopen(true);
      setClickedDate(info.dateStr);
    }
  };
  // Chnage in id bcz we fetch particular log data.
  const getLoges = async () => {
    // setLoading(true);
    const response = await AxiosAll(
      'get',
      `/calendar-logs?start_date=${startDate}&end_date=${endDate}&user_id=${userId || ''}&project_id=${
        projectId || ''
      }`,
      {},
      token
    );
    if (response?.data?.statusCode === 200 && response.data.data?.length) {
      // eslint-disable-next-line no-debugger
      const afterMap = response.data.data.map((value, index) => ({
        title: value?.task_name || value?.type,
        date: value?.date,
        groupId: value?.id,
        type: value?.type,
        extendedProps: {
          time_text: `${Math.floor(value?.time / 60)} hour ${Math.round(
            (value?.time / 60 - Math.floor(value?.time / 60)) * 60
          )} minute`,
          currentDate: new Date(value.date),
        },
      }));
      // console.log("fgdh", afterMap)
      setMyEvents(afterMap);
    } else {
      setMyEvents([]);
    }
  };
  const EditTimeSheet = (data) => {
    // eslint-disable-next-line no-debugger
    if (data?.event?.groupId !== 'null') {
      setOpen(true);
      setIdToOpen(data?.event?.groupId);
      // navigate(`/logs?task_id=${data?.event?.groupId}&date=${moment(data?.event?.extendedProps.currentDate).format('YYYY-MM-DD')}`)
    } else {
      navigate(
        `/logs?date=${moment(data?.event?.extendedProps.currentDate).format('YYYY-MM-DD')}&type=${
          data?.event?.extendedProps?.type
        }`
      );
    }
  };

  // const getUser = async () => {
  //   const response = await AxiosAll('get', '/common/users', {}, token);
  //   if (response?.status === 200) {
  //     setUser(response?.data);
  //   }
  // };

  const getProject = async () => {
    const response = await AxiosAll('get', `/common/projects?user_id=${userId || ''}`, {}, token);
    if (response?.status === 200) {
      setProject(response?.data);
    }
  };

  useEffect(() => {
    getProject();
  }, [userId, user]);

  // useEffect(() => {
  //   getUser();
  // }, []);

  useEffect(() => {
    getLoges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    setTableData(allTask);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTask, page]);

  const handleUsers = (e) => {
    // eslint-disable-next-line no-debugger
    navigate(
      `${location.pathname}?user_id=${e?.target?.value}&project_id=${''}&start_date=${startDate}&end_date=${endDate}`
    );
    setUserId(e.target.value);
    setProjectId('');
  };
  const handleProject = (e) => {
    // eslint-disable-next-line no-debugger
    navigate(
      `${location.pathname}?user_id=${userId}&project_id=${e?.target?.value}&start_date=${startDate}&end_date=${endDate}`
    );
    setProjectId(e.target.value);
  };
  const handleStartDateChange = (e) => {
    navigate(
      `${location.pathname}?user_id=${userId}&project_id=${projectId}&start_date=${e.target.value}&end_date=${endDate}`
    );
    setStartDate(e.target.value);
  };
  const handleEndDateChange = (e) => {
    navigate(
      `${location.pathname}?user_id=${userId}&project_id=${projectId}&start_date=${startDate}&end_date=${e.target.value}`
    );
    setEndDate(e.target.value);
  };

  return (
    <Page title="Timesheet">
      {/* <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Time Sheet"
          links={[
            { name: 'Dashboard', href: '/dashboard' },
            { name: 'Time Sheet', href: '/time-sheet' },
          ]}
        /> */}
              <AddLogsForm open={openNew} onClose={() => setOpenNew(false)} onNextStep={() => setOpenNew(false)} />

      <SinglieLogDetail setOpen={setOpen} open={open} idToOpen={idToOpen} />
      <LogList
        setListopen={setListopen}
        listopen={listopen}
        clickedDate={clickedDate}
        user={userId}
        project={projectId}
      />

      <Stack spacing={2} className="stack-center" flexWrap="wrap" gap={1} direction="row" sx={{mb: 2}}>
        {user?.roles[0]?.id === 1 ? (
          <TextField
            fullWidth
            select
            label="User"
            value={userId}
            onChange={handleUsers}
            SelectProps={{
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260 } },
              },
            }}
            sx={{
              maxWidth: { sm: 240 },
              textTransform: 'capitalize',
              ml: 0,
            }}
          >
            <MenuItem
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
              key={''}
              value={''}
            >
              Select
            </MenuItem>
            {userList?.map((option) => (
              <MenuItem
                key={option?.id}
                value={option?.id}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}
              >
                {option?.first_name} {option?.last_name}
              </MenuItem>
            ))}
          </TextField>
        ) : null}
        <TextField
          fullWidth
          select
          label="Project"
          value={projectId}
          onChange={handleProject}
          SelectProps={{
            MenuProps: {
              sx: { '& .MuiPaper-root': { maxHeight: 260 } },
            },
          }}
          sx={{
            maxWidth: { sm: 240 },
            textTransform: 'capitalize',
          }}
          style={{ marginLeft: 0 }}
        >
          <MenuItem
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
            key={''}
            value={''}
          >
            Select
          </MenuItem>
          {project?.map((option) => (
            <MenuItem
              key={option?.id}
              value={option?.id}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option?.title}
            </MenuItem>
          ))}
        </TextField>
        {/* <Box sx={{ width: { xs: '100%', sm: 'fit-content' } }} style={{marginLeft: 0}}>
          <DateInputFelid idName="date1" inputType="date" inputLabel="Start Date" />
        </Box>
        <Box sx={{ width: { xs: '100%', sm: 'fit-content' } }} style={{marginLeft: 0}}>
          <DateInputFelid idName="date2" inputType="date" inputLabel="End Date" />
        </Box> */}
        <Box sx={{ width: { xs: '100%', sm: 'fit-content' } }} style={{ marginLeft: 0 }}>
          <TextField
            fullWidth
            id="date1"
            type="date"
            label="Start Date"
            value={startDate}
            onChange={handleStartDateChange}
          />
        </Box>
        <Box sx={{ width: { xs: '100%', sm: 'fit-content' } }} style={{ marginLeft: 0 }}>
          <TextField fullWidth id="date2" type="date" label="End Date" value={endDate} onChange={handleEndDateChange} />
        </Box>
        {/* <div>
            <Iconify
              onClick={handleReset}
              icon={'system-uicons:reset-alt'}
              className="Filter"
              width={30}
              height={30}
              sx={{ mb: 2 }}
            />
          </div> */}
      </Stack>
      <div style={{ height: 700 }}>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, listGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          eventContent={renderEventContent}
          events={myEvents}
          eventClick={EditTimeSheet}
          headerToolbar={{
            left: 'prev,next',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
          }}
          dateClick={handleDateClick}
        />
      </div>
      {/* </Container> */}
    </Page>
  );
}

// ----------------------------------------------------------------------

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.event.title.replace('_', ' ')}</b>
      <br />
      <b>{eventInfo.event.extendedProps.time_text}</b>
    </>
  );
}
