import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import { NameInitialsAvatar } from 'react-name-initials-avatar';
import palette from 'src/theme/palette';

export const PopUpCreator = ({ imageUrl, fullName }) => (
  <div>
    <Tooltip title={fullName} placement="top">
      <Typography variant="body2" color="text.secondary">
        {imageUrl ? (
          <img
            alt=""
            src={imageUrl}
            style={{
              width: 32,
              height: 32,
              borderRadius: 50,
              objectFit: 'cover',
              border: `2px solid ${palette.light.grey[300]}`,
            }}
          />
        ) : (
          <NameInitialsAvatar
            name={fullName}
            size={32}
            textSize={10}
            bgColor={palette.light.warning.main}
            borderColor={palette.light.grey[900]}
          />
        )}
      </Typography>
    </Tooltip>
  </div>
);
