/* eslint-disable no-debugger */
/* eslint-disable react/jsx-boolean-value */
import { useEffect, useState } from 'react';
// @mui
import {
  Box,
  Button,
  Card,
  Container,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Tooltip,
} from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import TaskLableTableRow from 'src/sections/@dashboard/task_lables/list/TaskPLabelTableRow';
// import { TABLE_HEAD_COLOR } from 'src/core';
import AxiosAll from 'src/services/axiosAll';
import useSettings from '../../hooks/useSettings';
import useTable from '../../hooks/useTable';
import useTabs from '../../hooks/useTabs';
// _mock_
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { TableHeadCustom, TableNoData, TableSelectedActions, TableSkeleton } from '../../components/table';
// sections
import { TaskPrioritiesTableToolbar } from '../../sections/@dashboard/task_priorities/list';
import CreateTaskLabel from './Create';

// ----------------------------------------------------------------------
export const TABLE_HEAD_COLOR = [
  { id: 'name', label: 'Name', align: 'center' },
  { id: 'color', label: 'Color', align: 'center' },
  { id: 'icon', label: 'Action', align: 'center' },
];
export default function TaskLabels() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  // const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  const [currentLabel, setCurrentLabel] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [multipleDeleteMode, setMultipleDeleteMode] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;

  const { enqueueSnackbar } = useSnackbar();

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterRole = (event) => {
    setFilterRole(event.target.value);
  };

  const handleDeleteRow = async (id) => {
    const response = await AxiosAll('delete', `/labels/${id}`, {}, token);
    const { data } = response;
    if (data?.statusCode === 200) {
      enqueueSnackbar(data?.message, { variant: 'success' });
      if (selected.length > 0) {
        setSelected([]);
      }
      getAllLabel();
    } else {
      enqueueSnackbar(data?.message, { variant: 'error' });
    }
  };

  const getAllLabel = async () => {
    setLoading(true);
    const response = await AxiosAll('get', `/labels?page=${page + 1}&query=${filterName}`, {}, token);
    const { data } = response;
    window.scrollTo(0, 0);

    if (data?.statusCode === 200) {
      // setRowsPerPage(data?.data?.per_page);
      setTableData(data?.data);
      // setCount(data?.data?.total);
      setLoading(false);
    }
  };

  const getSinglePriority = async (id) => {
    const response = await AxiosAll('get', `/labels/${id}`, {}, token);
    const { data } = response;

    if (data?.statusCode === 200) {
      setCurrentLabel(data?.data);
    }
  };

  useEffect(() => {
    getAllLabel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filterName]);

  const handleEditRow = (id) => {
    getSinglePriority(id);
    setIsEdit(true);
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
    if (isEdit) {
      setIsEdit(false);
      setCurrentLabel([]);
    }
  };

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!tableData?.length && !!filterName) ||
    (!tableData?.length && !!filterRole) ||
    (!tableData?.length && !!filterStatus);

  return (
    <Page title="Task-Priority: List">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Task-Label List"
          links={[{ name: 'Dashboard', href: '/dashboard' }, { name: 'Label List' }]}
          action={
            <Button variant="contained" onClick={() => setOpen(true)} startIcon={<Iconify icon={'eva:plus-fill'} />}>
              New Labels
            </Button>
          }
        />

        <Card>
          <CreateTaskLabel
            open={open}
            onClose={closeDialog}
            currentLabel={currentLabel}
            getAllLabel={getAllLabel}
            isEdit={isEdit}
          />

          <TaskPrioritiesTableToolbar
            filterName={filterName}
            filterRole={filterRole}
            onFilterName={handleFilterName}
            onFilterRole={handleFilterRole}
            search="priorities"
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData?.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData?.map((row) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setDeleteDialog(true);
                          setMultipleDeleteMode(true);
                        }}
                      >
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  hideCheckbox
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD_COLOR}
                  rowCount={tableData?.length}
                  numSelected={selected?.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData?.map((row) => row.id)
                    )
                  }
                />

                {loading ? (
                  <>
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                  </>
                ) : (
                  <TableBody>
                    {tableData?.map((row) => (
                      <TaskLableTableRow
                        key={row?.id}
                        row={row}
                        lable={true}
                        setDeleteDialog={setDeleteDialog}
                        setMultipleDeleteMode={setMultipleDeleteMode}
                        setDeleteId={setDeleteId}
                        selected={selected?.includes(row?.id)}
                        onSelectRow={() => onSelectRow(row?.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                        onEditRow={() => handleEditRow(row?.id)}
                      />
                    ))}

                    {/* <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} /> */}

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

// function applySortFilter({ tableData, comparator, filterName, filterStatus, filterRole }) {
//   const stabilizedThis = tableData.map((el, index) => [el, index]);

//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });

//   tableData = stabilizedThis.map((el) => el[0]);

//   if (filterName) {
//     tableData = tableData.filter((item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
//   }

//   if (filterStatus !== 'all') {
//     tableData = tableData.filter((item) => item.status === filterStatus);
//   }

//   if (filterRole !== 'all') {
//     tableData = tableData.filter((item) => item.role === filterRole);
//   }

//   return tableData;
// }
