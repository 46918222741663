import html2canvas from 'html2canvas';
import currentURL from '../env';

export const TABLE_HEAD_ADMIN = [
  // { id: 'id', label: 'Id', align: 'left' },
  { id: 'title', label: 'Title', align: 'left' },
  // { id: 'project', label: 'Project', align: 'left' },
  { id: 'dueDate', label: 'Due Date', align: 'left' },
  { id: 'priority', label: 'priority', align: 'center' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'dev', label: 'Developer', align: 'left' },
  // { id: 'creator', label: 'Creator', align: 'left' },
  { id: 'time', label: 'Alloted Time', align: 'left' },
  { id: 'spent', label: 'Spend Time', align: 'left' },
  // { id: 'parent', label: 'Parent task', align: 'left' },
  { id: 'action', label: 'Action', align: 'left' },
];
export const TABLE_HEAD = [
  // { id: 'id', label: 'Id', align: 'left' },
  { id: 'title', label: 'Title', align: 'left' },
  { id: 'project', label: 'Project', align: 'left' },
  { id: 'dueDate', label: 'Due Date', align: 'left' },
  { id: 'priority', label: 'priority', align: 'center' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'dev', label: 'Developer', align: 'left' },
  { id: 'creator', label: 'Creator', align: 'left' },
  { id: 'time', label: 'Alloted Time', align: 'left' },
  { id: 'spent', label: 'Spend Time', align: 'left' },
  // { id: 'parent', label: 'Parent task', align: 'left' },
  { id: 'action', label: 'Action', align: 'left' },
];

export const TABLE_HEAD_COLOR = [
  { id: 'name', label: 'Name', align: 'center' },
  { id: 'color', label: 'Color', align: 'center' },
  { id: 'icon', label: 'Icon', align: 'center' },
];

export const returnLocalEnd = (e) => {
  const endDate = new Date(e);

  // Convert the end date to the desired timezone (your local timezone)
  const endDateLocal = endDate.toLocaleString('en-IN', {
    timeZone: 'Asia/Kolkata',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  });
  return endDateLocal;
};
export const isObjectEmpty = (objectName) => Object.keys(objectName).length === 0;

export const findTimeDifference = ({ start, end }) => {
  const time1String = end;
  const time2String = start;

  const isNegative = start > end ? '-' : '';

  const time1Parts = time1String?.split(':');
  const time2Parts = time2String?.split(':');
  const time1 = new Date();
  time1.setHours(parseInt(time1Parts[0], 10), parseInt(time1Parts[1], 10), 0); // Set time1 to 19:00

  const time2 = new Date();
  time2.setHours(parseInt(time2Parts[0], 10), parseInt(time2Parts[1], 10), 0); // Set time2 to 10:00

  const differenceInMilliseconds = time1 - time2;
  let differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));
  differenceInMinutes = Math.abs(differenceInMinutes);
  const differenceInHours = Math.floor(differenceInMinutes / 60);

  return `${isNegative}${differenceInHours}:${
    differenceInMinutes % 60 < 10 ? `0${differenceInMinutes % 60}` : differenceInMinutes % 60
  }`;
};

export const truncateText = (msg, length) => {
  if (msg?.length <= length) {
    return msg;
  }
  const truncated = msg?.slice(0, length);
  // const lastSpaceIndex = truncated?.lastIndexOf(' ');
  // if (lastSpaceIndex !== -1) {
  //   truncated = truncated?.slice(0, lastSpaceIndex);
  // }
  return `${truncated}...`;
};

// export const newServer = 'https://api.dev.node.pms.skunktest.work/api';
export const newServer = currentURL?.currentEnv;

export const colorData = [
  {
    name: 'Pending Task',
    color: '#1890FF',
  },
  {
    name: 'Pending and Urgent',
    color: '#B72136',
  },
];

export function convertTo12HourFormat(time24) {
  // Split the time string into hours and minutes
  const [hours, minutes] = time24.split(':');

  // Convert hours and minutes to numbers
  const hoursNum = parseInt(hours, 10);
  const minutesNum = parseInt(minutes, 10);

  // Determine AM or PM
  const period = hoursNum >= 12 ? 'pm' : 'am';

  // Convert hours to 12-hour format
  const hours12 = hoursNum % 12 || 12;

  // Format the result
  const time12 = `${hours12}:${minutesNum < 10 ? '0' : ''}${minutesNum} ${period}`;

  return time12;
}

// Example usage:
const time24 = '14:30';
const time12 = convertTo12HourFormat(time24);
// console.log(time12); // Output: 02:30 pm

export const exportChart = (chartRef) => {
  html2canvas(chartRef.current, { scale: 2 }).then((canvas) => {
    const dataUrl = canvas?.toDataURL();
    const link = document?.createElement('a');
    link.href = dataUrl;
    link.download = 'chart.png';
    document?.body?.appendChild(link);
    link?.click();
    document?.body?.removeChild(link);
  });
};
