/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
// @mui
import { Avatar, Box, Button, Divider, MenuItem, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from 'src/components/Iconify';

// components
import { useNavigate } from 'react-router';
import SvgIconStyle from 'src/components/SvgIconStyle';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/services/axiosAll';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { deleteLogin } from '../../../redux/actions/login';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    icon: 'grommet-icons:document-user',
    label: 'User Manual',
    linkTo: 'https://docs.google.com/document/d/1woTLM5DG2FZZpJiGC6uhmMO3GmFReHcFZ7FID4yOGh4/edit',
  },
  {
    icon: 'healthicons:group-discussion-meeting',
    label: 'Daily Office Zoom Link',
    linkTo: 'https://us02web.zoom.us/j/6790281683?pwd=cG9vVTUxNnppYmU2alJ2ZHJyS2Mxdz09#success',
  },
  {
    icon: 'bxl:zoom',
    label: 'Client Meeting Zoom Link',
    linkTo: 'https://us02web.zoom.us/j/2135060782?pwd=Z2VLMGhlSStCOFJ1OWZzVHFnckFnUT09#success',
  },
  // {
  //   label: 'Settings',
  //   linkTo: '/',
  // },
];

// ----------------------------------------------------------------------

export default function UseFullLinkPopover() {
  const [open, setOpen] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogOut = async () => {
    await AxiosAll('post', '/logout', null, token);
    dispatch(deleteLogin());
    navigate('/login');
    localStorage.removeItem('user');
    // localStorage.removeItem('projectList');
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleOpen}
        fullWidth
        sx={{ all: 'unset', marginLeft: '6px' }}
        className="usefull_btn"
      >
        Useful Links
      </Button>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          minWidth: 250,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <Stack direction="row" alignItems="center">
              <Iconify icon={option.icon} width={20} height={20} />
              <MenuItem
                key={option.label}
                onClick={() => {
                  window.open(option.linkTo);
                  handleClose();
                }}
              >
                {option.label}
              </MenuItem>
            </Stack>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
