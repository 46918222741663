import PropTypes from 'prop-types';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import { styled } from '@mui/material/styles';
// _mock
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import axios from 'axios';
import { newServer } from 'src/core';
import Iconify from '../Iconify';
import { FormProvider, RHFSelect, RHFTextField } from '../hook-form';


// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

CompleteDialog.propTypes = {
  task: PropTypes.object,
  getTasks: PropTypes.func,
};



// We'll only send task id to backend, finalised on 1/12/22 by gajendra .
export default function CompleteDialog({ task, getTasks }) {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const [userData, setUserData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const NewTeamSchema = Yup.object().shape({
    // time: Yup.string().required('Time is required'),
    // description: Yup.string().required('Description is required'),
    // status: Yup.string().required('Status is required'),
  });

  const handleClickOpen = () => {
    onSubmit();
    // setOpen(true);
  };
  const defaultValues = useMemo(
    () => ({
      assign: "0",
      userId: task?.id || '',
      time: '',
      comment: '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userData]
  );

  const methods = useForm({
    resolver: yupResolver(NewTeamSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  // const getUser = async () => {
  //   const res = await AxiosAll('get', '/common/users', {}, token);
  //   if (res?.status === 200) {
  //     const newData = res?.data;
  //     setUserData(newData);
  //   }
  // };
  // useEffect(() => {
  //   // eslint-disable-next-line no-unused-expressions
  //   userData && getUser();
  // }, [])

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const assignUser = async () => {
  //   if (task?.task_users?.length !== 0) {
  //     if (task) {
  //       const assignedUser = await task?.task_users?.map((item) => item?.user);
  //       console.log("assignedUser", assignedUser);
  //       await setValue('userId', `${assignedUser[0]?.id}`);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   assignUser();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [task]);

  const onSubmit = () => {
    const config = {
      method: 'post',
      url: `${newServer}/tasks/mark-as-finished/${task?.id}`,
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      data: {}
    };

    axios(config)
      .then((response) => {
        const { data } = response;

        if (data.statusCode === 200) {
          enqueueSnackbar(data?.message, { variant: 'success' });
          setOpen(false);
          getTasks();
          reset();
        } else {
          enqueueSnackbar(data?.message, { variant: 'error' });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      });
  };

  return (
    <div>

      <Button className="timeBTn" onClick={handleClickOpen}>
        <Iconify icon={'fluent-mdl2:completed'} />
        Complete
      </Button>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={() => {
          setOpen(false);
          reset(defaultValues);
        }}
      >
        <DialogTitle>Mark as complete</DialogTitle>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Stack spacing={3}>
              <div>
                <LabelStyle>Do you want to assign it to others?</LabelStyle>
                <RHFSelect name="assign" placeholder="User">
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </RHFSelect>
              </div>
              {values?.assign === '1' &&
                <div>
                  <LabelStyle>Assign User</LabelStyle>
                  <RHFSelect name="userId">
                    {userData?.map((item, index) => (<option value={item?.id} key={index}>{`${item?.first_name} ${item?.last_name}`}</option>))}
                  </RHFSelect>
                </div>
              }
              <div>
                <LabelStyle>Time to complete</LabelStyle>
                <RHFTextField name="time" type="number" placeholder="Time (in min)" />
              </div>
              <div>
                <LabelStyle>Comments</LabelStyle>
                <RHFTextField name="comment" placeholder="Team Description..." multiline rows={4} />
              </div>
            </Stack>
          </DialogContent>

          <Divider />

          <DialogActions>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              Submit
            </LoadingButton>
            <Button
              color="inherit"
              variant="outlined"
              onClick={() => {
                setOpen(false);
                reset(defaultValues);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </div>

  );
}
