// eslint-disable-next-line import/no-unresolved
import axios from 'axios';
import { useSnackbar } from 'notistack';
// config
// eslint-disable-next-line import/named
import currentURL from '../env';
import { deleteLogin } from '../redux/actions/login';
import { store } from '../redux/store';

// ----------------------------------------------------------------------
const baseURL = currentURL?.currentEnv;
// eslint-disable-next-line react-hooks/rules-of-hooks
const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data.statusCode === 401) {
      store.dispatch(deleteLogin());
    }
    return response;
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
