import PropTypes, { checkPropTypes } from 'prop-types';
import { useSnackbar } from 'notistack';
// form
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from '@mui/material';
// components
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import AxiosAll from 'src/services/axiosAll';
import { imagesAll } from 'src/assets/Images_index';
import moment from 'moment';
import EmptyContent from 'src/components/EmptyContent';
import Leaves from 'src/pages/leaves';

export default function LeaveListDetail() {
  const { state } = useLocation();
  const { endDate, startDate } = state;
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const params = useParams();
  const { themeStretch } = useSettings();

  // Calculate the current year's January 1st date
  const currentYear = new Date().getFullYear();
  const date = `${currentYear}-01-01`;

  const [startdate, setstartdate] = useState(startDate || date);
  const [enddate, setenddate] = useState(endDate);
  const [isLogShow, setIsLogShow] = useState('leaves');
  const [isListShow, setIsListShow] = useState('list');
  const [leaves, setLeaves] = useState([]);
  const [compensations, setCompensations] = useState([]);
  const [overView, setoverView] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const navigate = useNavigate();

  // Function to strip HTML tags
  const stripHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  };

  // Function to count words in a string
  const countWords = (text) => {
    return text.split(/\s+/).filter(Boolean).length;
  };

  const getLeaveData = async () => {
    try {
      const response = await AxiosAll(
        'get',
        `/leave/${params?.id}?startDate=${startdate}&endDate=${enddate}`,
        {},
        token
      );
      if (response?.data?.statusCode === 200) {
        setLeaves(response?.data?.data?.leaves || []);
        setCompensations(response?.data?.data?.compensations || []);
        setoverView(response?.data?.data?.overview || []);
      }
    } catch (error) {
      console.error('Error fetching leave data', error);
    }
  };

  const handlestartdateChange = (e) => {
    setstartdate(e.target.value);
  };
  const handleenddateChange = (e) => {
    setenddate(e.target.value);
  };

  useEffect(() => {
    getLeaveData();
  }, [startdate, enddate]);

  const handleToggle = (e, val) => {
    if (val === 'leaves') {
      setIsLogShow('leaves');
    } else if (val === 'compensations') {
      setIsLogShow('compensations');
    }
  };

  const handlecalender = (e, val) => {
    if (val === 'calender') {
      navigate(`/leaves`);
    } else {
      setIsListShow('list');
    }
  };

  const toggleDescription = (id) => {
    setExpandedDescriptions((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <Page title="Leave Action">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs heading="Leave Details" links={[{}]} />
        <Grid container spacing={2} mt={3}>
          {userDetails?.user?.role_id === 3 || userDetails?.user?.role_id === 1 ? (
            <Grid item xs={12}>
              <Typography sx={{ margin: '0.5rem 1rem' }}>
                <Button onClick={() => navigate('/leaves', { state: { isBackButtonClicked: true } })}>Back</Button>
              </Typography>
            </Grid>
          ) : null}

          <Grid item xs={9} sm={9} md={9}>
            <Typography sx={{ margin: '0.5rem 1rem' }}>{overView.name}'s Leave Details</Typography>
          </Grid>
          <Grid item xs={3} sm={3} md={3}>
            <Typography>
              <ToggleButtonGroup
                color="primary"
                value={isListShow}
                exclusive
                onChange={handlecalender}
                aria-label="Platform"
              >
                <ToggleButton value="list" style={{ whiteSpace: 'nowrap' }}>
                  List View
                </ToggleButton>
                <ToggleButton value="calender">Calender</ToggleButton>
              </ToggleButtonGroup>
            </Typography>
          </Grid>
        </Grid>

        {isListShow === 'list' ? (
          <Card>
            <Stack spacing={3} mt={5} sx={{ margin: '0.5rem 1rem', fontWeight: 'bold' }}>
              <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar alt={overView.name} src={overView.profile_picture || imagesAll.mainWhiteLogo} sx={{ mr: 2 }} />
                <Typography variant="body2" color="text.secondary">
                  {overView.name}
                </Typography>
              </Typography>
            </Stack>

            <Grid container spacing={2} mt={3}>
              <Grid item xs={4}>
                <Typography sx={{ margin: '0.5rem 1rem', fontWeight: 'bold' }} align="center">
                  Compensated Days
                </Typography>
                <Typography sx={{ margin: '0.5rem 1rem' }} align="center">
                  {overView.compensated}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ margin: '0.5rem 1rem', fontWeight: 'bold' }} align="center">
                  Half Day
                </Typography>
                <Typography sx={{ margin: '0.5rem 1rem' }} align="center">
                  {overView.halfDay}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ margin: '0.5rem 1rem', fontWeight: 'bold' }} align="center">
                  Full Day
                </Typography>
                <Typography sx={{ margin: '0.5rem 1rem' }} align="center">
                  {overView.fullDay}
                </Typography>
              </Grid>
            </Grid>
            <Divider />

            <CardContent>
              <Grid container spacing={2} mt={3}>
                <Grid item xs={12} sm={3.5} md={4}>
                  <TextField
                    id="start-date"
                    label="Start Date"
                    type="date"
                    value={startdate}
                    onChange={handlestartdateChange}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={3.5} md={4}>
                  <TextField
                    id="end-date"
                    label="End Date"
                    type="date"
                    value={enddate}
                    onChange={handleenddateChange}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={1}>
                  {''}
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <ToggleButtonGroup
                    color="primary"
                    value={isLogShow}
                    exclusive
                    onChange={handleToggle}
                    aria-label="Platform"
                  >
                    <ToggleButton value="leaves">Leaves</ToggleButton>
                    <ToggleButton value="compensations">Compensations</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>

              {isLogShow === 'leaves' ? (
                <>
                  <Typography sx={{ mt: 4, fontWeight: 'bold' }}>List of Leaves</Typography>
                  <TableContainer component={Paper} sx={{ mt: 4 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Title</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>Leave Type</TableCell>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>Start Date</TableCell>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>End Date</TableCell>
                          <TableCell>Description</TableCell>
                        </TableRow>
                      </TableHead>
                      {leaves?.length > 0 ? (
                        <>
                          <TableBody>
                            {leaves?.map((leave) => {
                              const descriptionText = stripHTML(leave?.description);
                              const wordCount = countWords(descriptionText);

                              return (
                                <TableRow key={leave?.id}>
                                  <TableCell>{leave?.name}</TableCell>
                                  <TableCell>{leave?.status}</TableCell>
                                  <TableCell>{leave?.leave_type}</TableCell>
                                  <TableCell style={{ whiteSpace: 'nowrap' }}>
                                    {moment(leave?.start_date).format('DD-MM-YYYY')}
                                  </TableCell>
                                  <TableCell style={{ whiteSpace: 'nowrap' }}>
                                    {moment(leave?.end_date).format('DD-MM-YYYY')}
                                  </TableCell>
                                  <TableCell>
                                    {wordCount > 10 ? (
                                      <>
                                        <Typography variant="body2">
                                          {expandedDescriptions[leave?.id]
                                            ? descriptionText
                                            : `${descriptionText.split(' ').slice(0, 10).join(' ')}...`}
                                        </Typography>
                                        <Button onClick={() => toggleDescription(leave?.id)}>
                                          {expandedDescriptions[leave?.id] ? 'Show less' : 'Read more'}
                                        </Button>
                                      </>
                                    ) : (
                                      <Typography variant="body2">{descriptionText}</Typography>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </>
                      ) : (
                        <EmptyContent title="No leaves available" />
                      )}
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <>
                  <Typography sx={{ mt: 4, fontWeight: 'bold' }}>List of Compensations</Typography>
                  <TableContainer component={Paper} sx={{ mt: 4 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Title</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Date</TableCell>
                        </TableRow>
                      </TableHead>
                      {compensations?.length > 0 ? (
                        <TableBody>
                          {compensations?.map((compensation) => (
                            <TableRow key={compensation?.id}>
                              <TableCell>{compensation?.title}</TableCell>
                              <TableCell>{compensation?.status}</TableCell>
                              <TableCell>{compensation?.type}</TableCell>
                              <TableCell>{moment(compensation?.date).format('DD-MM-YYYY')}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : (
                        <EmptyContent title="No compensations available" />
                      )}
                    </Table>
                  </TableContainer>
                </>
              )}
            </CardContent>
          </Card>
        ) : (
          <EmptyContent title="Calendar view is not implemented yet." />
        )}
      </Container>
    </Page>
  );
}
