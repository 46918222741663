// @mui
import { TableRow, TableCell, Skeleton, Stack, Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function AvailabilityCardSkeleton({ ...other }) {
    return (
        <>
        <Box sx={{
            mb: 1,
            display: 'flex',
            flexDirection: 'row',
            // alignItems: 'center',
            justifyContent: 'space-between',
            padding: 2.5,
            marginTop: 2,
            borderRadius: 1,
            boxShadow: '0 1px 1px 0 rgba(66, 66, 66, 0.08), 0 1px 3px 1px rgba(66, 66, 66, 0.16)',
        }}

        >
            <Stack direction="row" spacing={2}>
                <Skeleton variant="circular" width={50} height={50} />
                <div>
                    <Skeleton variant="rectangular" width={210} height={20} />
                    <Skeleton variant="rectangular" width={210} height={20} sx={{ mt: 2 }} />
                </div>

            </Stack>
            <Skeleton variant="rectangular" width={80} height={20} />
        </Box>
         <Box sx={{
            mb: 1,
            display: 'flex',
            flexDirection: 'row',
            // alignItems: 'center',
            justifyContent: 'space-between',
            padding: 2.5,
            marginTop: 2,
            borderRadius: 1,
            boxShadow: '0 1px 1px 0 rgba(66, 66, 66, 0.08), 0 1px 3px 1px rgba(66, 66, 66, 0.16)',
        }}

        >
            <Stack direction="row" spacing={2}>
                <Skeleton variant="circular" width={50} height={50} />
                <div>
                    <Skeleton variant="rectangular" width={210} height={20} />
                    <Skeleton variant="rectangular" width={210} height={20} sx={{ mt: 2 }} />
                </div>

            </Stack>
            <Skeleton variant="rectangular" width={80} height={20} />
        </Box>
        <Box sx={{
            mb: 1,
            display: 'flex',
            flexDirection: 'row',
            // alignItems: 'center',
            justifyContent: 'space-between',
            padding: 2.5,
            marginTop: 2,
            borderRadius: 1,
            boxShadow: '0 1px 1px 0 rgba(66, 66, 66, 0.08), 0 1px 3px 1px rgba(66, 66, 66, 0.16)',
        }}

        >
            <Stack direction="row" spacing={2}>
                <Skeleton variant="circular" width={50} height={50} />
                <div>
                    <Skeleton variant="rectangular" width={210} height={20} />
                    <Skeleton variant="rectangular" width={210} height={20} sx={{ mt: 2 }} />
                </div>

            </Stack>
            <Skeleton variant="rectangular" width={80} height={20} />
        </Box>
        <Box sx={{
            mb: 1,
            display: 'flex',
            flexDirection: 'row',
            // alignItems: 'center',
            justifyContent: 'space-between',
            padding: 2.5,
            marginTop: 2,
            borderRadius: 1,
            boxShadow: '0 1px 1px 0 rgba(66, 66, 66, 0.08), 0 1px 3px 1px rgba(66, 66, 66, 0.16)',
        }}

        >
            <Stack direction="row" spacing={2}>
                <Skeleton variant="circular" width={50} height={50} />
                <div>
                    <Skeleton variant="rectangular" width={210} height={20} />
                    <Skeleton variant="rectangular" width={210} height={20} sx={{ mt: 2 }} />
                </div>

            </Stack>
            <Skeleton variant="rectangular" width={80} height={20} />
        </Box>
        </>
    );
}
