import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

export const PopUpNormalLabel = ({ title, color, paddY, paddX, labelRadius, labelColor, priority }) => {
  const mainPadding = `${paddY}px ${paddX}px`;
  const [currentLabelColor, setCurrentLabelColor] = useState(labelColor);

  useEffect(() => {
    // Function to get the corresponding color based on the priority
    const getPriorityColor = () => {
      switch (priority) {
        case 1:
          return '#cf3c10'; // urgent
        case 2:
          return 'red'; //
        case 3:
          return '#1a66d4'; // High
        case 4:
          return '#7ae30e'; //
        default:
          return '#ffdc00'; // onhold
      }
    };
    setCurrentLabelColor(getPriorityColor());
  }, [priority]);

  return (
    <Box
      sx={{ bgcolor: 'background.neutral' }}
      style={{
        // backgroundColor: color,
        padding: mainPadding,
        borderRadius: labelRadius,
        display: 'flex',
        gap: 4,
        alignItems: 'center',
      }}
    >
      <Typography variant="subtitle1">{title}</Typography>
      <div style={{ width: 12, height: 12, backgroundColor: currentLabelColor, borderRadius: 20 }} />
    </Box>
  );
};
