import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
// @mui
import { Stack, Tooltip, Typography } from '@mui/material';
import Iconify from 'src/components/Iconify';

// hooks
import useToggle from '../../../hooks/useToggle';

KanbanColumnToolBar.propTypes = {
  column: PropTypes.object,
  columnName: PropTypes.string,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};

//  We are only showing column name
export default function KanbanColumnToolBar({ columnName, onDelete, onUpdate, addStyle, column }) {
  const renameRef = useRef(null);

  const { toggle: openConfirm, onOpen: onOpenConfirm, onClose: onCloseConfirm } = useToggle();

  const [value, setValue] = useState(columnName);

  const [open, setOpen] = useState(null);

  useEffect(() => {
    if (open) {
      if (renameRef.current) {
        renameRef.current.focus();
      }
    }
  }, [open]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleClickRename = () => {
    handleClose();
  };

  const handleChangeColumnName = (event) => {
    setValue(event.target.value);
  };

  const handleUpdateColumn = (event) => {
    if (event.key === 'Enter' && renameRef.current) {
      renameRef.current.blur();
      // onUpdate(value);
    }
  };

  const addClass = value === 'In Progress' ? 'progressActive' : '';
  const progressBoolen = value === 'In Progress';
  // console.log("🚀 ~ KanbanColumnToolBar ~ progressBoolen:", progressBoolen);
  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={{ pt: 3 }}>
        <Typography
          color={progressBoolen && '#212B36'}
          sx={{
            typography: 'h6',
            fontWeight: 'fontWeightBold',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
          }}
          className={addClass}
        >
          {value} ({column?.taskIds?.length || 0})
        </Typography>
        {column?.name === 'Not started' || column?.name === 'In Progress' || column?.name === 'On Hold' ? (
          <Tooltip title={`Total estimated time : ${column?.totalPlannedTime}`} placement="top">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              backgroundColor="#fff"
              padding="4px"
              // padding="5px 4px 5px 4px"
              borderRadius="5px"
            >
              <Iconify width={30} height={20} icon="mingcute:time-line" sx={{ mr: 0.25, color: '#000' }} />
              <Typography sx={{ color: '#000' }}>{column?.totalPlannedTime}</Typography>
            </Stack>
          </Tooltip>
        ) : null}
      </Stack>

      {/* <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          width: 'auto',
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <MenuItem onClick={onOpenConfirm} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ width: 20, height: 20, flexShrink: 0, mr: 1 }} />
          Delete section
        </MenuItem>

        <MenuItem onClick={handleClickRename}>
          <Iconify icon={'eva:edit-fill'} sx={{ width: 20, height: 20, flexShrink: 0, mr: 1 }} />
          Rename section
        </MenuItem>
      </MenuPopover>

      <KanbanConfirmDialog
        open={openConfirm}
        onClose={onCloseConfirm}
        title={
          <Typography gutterBottom>
            Are you sure you want to delete column <strong>{columnName}</strong>?
          </Typography>
        }
        subheader={
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            <strong>NOTE:</strong> All tasks related to this category will also be deleted.
          </Typography>
        }
        actions={
          <>
            <Button variant="outlined" color="inherit" onClick={onCloseConfirm}>
              Cancel
            </Button>
            <Button variant="contained" color="error" onClick={onDelete}>
              Delete
            </Button>
          </>
        }
      /> */}
    </>
  );
}
