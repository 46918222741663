import PropTypes from 'prop-types';
// form
// @mui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

ConfirmDialogue.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
  loading: PropTypes.bool,
};

export default function ConfirmDialogue({ open, onClose, onConfirm, title, content, loading }) {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      //   onClose={() => {
      //     onClose();
      //     reset(defaultValues);
      //   }}
    >
      <DialogTitle>{title ?? 'Are you sure you want to delete this field?'}</DialogTitle>

      <DialogContent>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {content ?? 'This action cannot be undone. Please confirm to proceed.'}
        </Typography>
      </DialogContent>

      <Divider />

      <DialogActions>
        <LoadingButton
          variant="contained"
          onClick={() => {
            onConfirm();
          }}
          loading={loading}
        >
          Confirm
        </LoadingButton>
        <Button
          variant="contained"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
