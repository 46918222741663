/* eslint-disable import/extensions */
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, ImageList } from '@mui/material';
import Image from './Image';
import { imagesAll } from '../assets/Images_index';
import '../scss/main.scss';
// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const setting = localStorage.getItem('settings');
  const mode = JSON.parse(setting);

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    // <Box sx={{ width: 99, height: 99, ...sx }}>
    <Image src={imagesAll.rsvrLogo} className="logo-img " />

    // </Box>
  );

  const whiteLogo = <Image src={imagesAll.mainWhiteLogo} className="logo-img " />;

  if (disabledLink) {
    return <>{mode?.themeMode === 'dark' ? whiteLogo : logo}</>;
  }

  return <RouterLink to="/dashboard">{mode?.themeMode === 'dark' ? whiteLogo : logo}</RouterLink>;
}
