/* eslint-disable no-unused-expressions */
import axios from 'axios';
import currentURL from '../env';
import { deleteLogin } from '../redux/actions/login';
import { store } from '../redux/store';

const AxiosAll = async (method, url, params, userToken, contentType) => {
  const baseURL = currentURL?.currentEnv;
  return axios({
    method,
    url: `${baseURL}${url}`,
    data: params,
    headers: {
      'Content-Type': contentType ?? 'application/json',
      Authorization: `Bearer ${userToken}`,
    },
  })
    .then((response) => {
      if (response.data.statusCode === 401) {
        store.dispatch(deleteLogin());
      }
      return response;
    })
    .catch((error) => {
      if (
        error?.response?.status === 401 ||
        error?.response?.data?.error === 'Unauthenticated' ||
        error?.response?.data?.Comments === 'Unauthenticated'
      ) {
        store.dispatch(deleteLogin());
      }
      return error?.response;
    });
};
export default AxiosAll;
