// components
// eslint-disable-next-line import/no-duplicates
import ForCountOnly from '.';
import SvgIconStyle from '../../../components/SvgIconStyle';
// ----------------------------------------------------------------------
const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
const ICONS = {
  analytics: getIcon('ic_analytics'),
  activity: getIcon('ic_activity'),
  banking: getIcon('ic_banking'),
  blog: getIcon('ic_blog'),
  watch: getIcon('ic_watch'),
  cart: getIcon('ic_cart'),
  chart: getIcon('ic_charts'),
  dashboard: getIcon('ic_dashboard'),
  ecommerce: getIcon('ic_ecommerce'),
  invoice: getIcon('ic_invoice'),
  kanban: getIcon('ic_kanban'),
  calendar: getIcon('ic_calendar'),
  timesheet: getIcon('time-sheet'),
  user: getIcon('ic_user'),
  fav: getIcon('star_RN'),
};
const firstPro = JSON.parse(localStorage.getItem('projectList'));
const mappedData = firstPro?.map((data, index) => ({ title: data?.title, path: `/project-view/${data?.id}` }));

export const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Tasks', path: '/kanban', icon: ICONS.kanban },
      // { title: 'Tasks', path: '/tasks', icon: ICONS.chart, info: <ForCountOnly type="task" /> },
      // { title: 'Dashboard', path: '/dashboard', icon: ICONS.dashboard },
      // { title: 'Favourite Tasks', path: '/favourite-tasks', icon: ICONS.fav, info: <ForCountOnly type="favourite_task" /> },
      // { title: 'Time Sheet', path: '/time-sheet', icon: ICONS.timesheet },
      { title: 'Time Logs', path: '/logs', icon: ICONS.invoice },
      // { title: 'Review', path: '/review', icon: ICONS.blog, info: <ForCountOnly type="review" /> },
      // { title: 'Performance', path: '/performance', icon: ICONS.analytics },
      // // { title: 'M. O. M.', path: '/minutes-of-meeting', icon: ICONS.activity },
      // { title: 'Watch', path: '/watch', icon: ICONS.blog, info: <ForCountOnly type="watch" /> },
      { title: 'Calendar', path: '/leaves', icon: ICONS.calendar, info: <ForCountOnly type="holiday" /> },
    ],
  },
];

export const navConfigLead = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: '/dashboard', icon: ICONS.dashboard },
      { title: 'Tasks', path: '/kanban', icon: ICONS.kanban, info: <ForCountOnly type="team_task" /> },
      // { title: 'Team Tasks', path: '/team-tasks', icon: ICONS.analytics, info: <ForCountOnly type="team_task" /> },
      // { title: 'My Tasks', path: '/tasks', icon: ICONS.chart, info: <ForCountOnly type="task" /> },
      // { title: 'Favourite Tasks', path: '/favourite-tasks', icon: ICONS.fav, info: <ForCountOnly type="favourite_task" /> },
      // { title: 'Time Sheet', path: '/time-sheet', icon: ICONS.timesheet },
      { title: 'Time Logs', path: '/logs', icon: ICONS.invoice },
      { title: 'Review', path: '/review', icon: ICONS.blog, info: <ForCountOnly type="review" /> },
      { title: 'Watch', path: '/watch', icon: ICONS.watch, info: <ForCountOnly type="watch" /> },
      { title: 'Tracker', path: '/tracker', icon: ICONS.kanban, info: <ForCountOnly type="tracker" /> },
      // { title: 'Performance', path: '/performance', icon: ICONS.analytics },
      { title: 'M. O. M.', path: '/minutes-of-meeting', icon: ICONS.activity },
      { title: 'Calendar', path: '/leaves', icon: ICONS.calendar, info: <ForCountOnly type="holiday" /> },
    ],
  },
];

export const navConfigTester = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: '/dashboard', icon: ICONS.dashboard },
      { title: 'Tasks', path: '/kanban', icon: ICONS.kanban, info: <ForCountOnly type="team_task" /> },
      // { title: 'Team Tasks', path: '/team-tasks', icon: ICONS.analytics, info: <ForCountOnly type="team_task" /> },
      // { title: 'My Tasks', path: '/tasks', icon: ICONS.chart, info: <ForCountOnly type="task" /> },
      // { title: 'Favourite Tasks', path: '/favourite-tasks', icon: ICONS.fav, info: <ForCountOnly type="favourite_task" /> },
      // { title: 'Time Sheet', path: '/time-sheet', icon: ICONS.timesheet },
      { title: 'Time Logs', path: '/logs', icon: ICONS.invoice },
      { title: 'Review', path: '/review', icon: ICONS.blog, info: <ForCountOnly type="review" /> },
      { title: 'Watch', path: '/watch', icon: ICONS.watch, info: <ForCountOnly type="watch" /> },
      // { title: 'In Testing', path: '/testing', icon: ICONS.chart, info: <ForCountOnly type="testing" /> },
      { title: 'Tracker', path: '/tracker', icon: ICONS.kanban, info: <ForCountOnly type="tracker" /> },
      // { title: 'Performance', path: '/performance', icon: ICONS.analytics },
      { title: 'M. O. M.', path: '/minutes-of-meeting', icon: ICONS.activity },
      { title: 'Calendar', path: '/leaves', icon: ICONS.calendar, info: <ForCountOnly type="holiday" /> },
    ],
  },
];

export const navConfigAdmin = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: '/dashboard', icon: ICONS.dashboard },
      { title: 'Tracker', path: '/tracker', icon: ICONS.chart, info: <ForCountOnly type="tracker" /> },
      {
        title: 'Tasks',
        path: '/tasks',
        icon: ICONS.kanban,
        children: [
          { title: 'Status Wise', path: 'tasks/status-wise' },
          { title: 'Project Wise', path: 'tasks/project-wise' },
          { title: 'User Wise', path: 'tasks/user-wise' },
          { title: 'Progress', path: 'tasks/progress' },
        ],
      },
      // { title: 'Favourite Tasks', path: '/favourite-tasks', icon: ICONS.fav },
      // { title: 'Time Sheet', path: '/time-sheet', icon: ICONS.timesheet },
      { title: 'Time Logs', path: '/logs', icon: ICONS.invoice },
      { title: 'Review', path: '/review', icon: ICONS.blog, info: <ForCountOnly type="review" /> },
      { title: 'Watch', path: '/watch', icon: ICONS.watch, info: <ForCountOnly type="watch" /> },
      // { title: "Today's task", path: '/today-task', icon: ICONS.kanban },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      {
        title: 'Reports',
        path: 'report',
        info: <strong style={{ color: 'red' }}>New</strong>,
        icon: ICONS.chart,
        children: [
          { title: "User's Report", path: 'report/user-reports' },
          { title: 'Project Report', path: 'report/project-reports' },
        ],
      },
      {
        title: 'Startups',
        path: 'startup',
        icon: ICONS.user,
        children: [
          { title: 'Create Startup', path: 'startup/create-startup' },
          { title: 'StartupList', path: 'startup/startup-list' },
          // { title: 'Six', path: '/dashboard/user/six' },
        ],
      },
      {
        title: 'Projects',
        path: 'project',
        icon: ICONS.ecommerce,
        children: [
          { title: 'Create Project', path: 'project/create-project' },
          { title: 'Project List', path: 'project/project-list' },
        ],
      },
      {
        title: 'Calendar',
        path: 'leaves',
        icon: ICONS.calendar,
      },
      {
        title: 'Activities',
        path: 'activities',
        icon: ICONS.activity,
      },
      {
        title: 'user',
        path: 'user',
        icon: ICONS.user,
        children: [
          { title: 'create user', path: '/manage-user/create-user' },
          { title: 'UserList', path: '/user/user-List' },
        ],
      },
    ].filter(Boolean),
  },

  // SETTINGS
  // ----------------------------------------------------------------------
  {
    subheader: 'Settings',
    items: [
      {
        title: 'Teams',
        path: 'settings/team-list',
        icon: ICONS.user,
      },
      // {
      //   title: 'Tags',
      //   path: 'settings/tag-list',
      //   icon: ICONS.invoice,
      // },
      {
        title: 'Roles',
        path: 'settings/role-list',
        icon: ICONS.user,
      },
      {
        title: 'Project Status',
        path: 'settings/project-status',
        icon: ICONS.analytics,
      },
      {
        title: 'Task Status',
        path: 'settings/task-status',
        icon: ICONS.ecommerce,
      },
      // {
      //   title: 'Task Assigned Status',
      //   path: 'settings/task-assigned-status',
      //   icon: ICONS.dashboard,
      // },
      {
        title: 'Task Priorities',
        path: 'settings/task-priorities',
        icon: ICONS.invoice,
      },
      {
        title: 'Task Labels',
        path: 'settings/task-labels',
        icon: ICONS.invoice,
      },
      {
        title: 'Employee Certificate',
        path: 'settings/certificate',
        icon: ICONS.fav,
        info: <strong style={{ color: 'red' }}>New</strong>,
      },
    ],
  },
];
