/* eslint-disable no-unneeded-ternary */
import { useEffect, useState } from 'react';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// @mui
import { Button, Container, MenuItem, TextField } from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/services/axiosAll';
// import { Calendar, momentLocalizer } from 'react-big-calendar';
//
import moment from 'moment';
import { useSnackbar } from 'notistack';
// eslint-disable-next-line import/no-unresolved, import/extensions
import 'react-big-calendar/lib/sass/styles.scss';
// eslint-disable-next-line import/no-unresolved, import/extensions
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { Stack } from '@mui/system';
import { imagesAll } from 'src/assets/Images_index';
import { AppWidget } from 'src/sections/@dashboard/general/app';
// _mock_
// components
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import DateInputFelid from 'src/components/dateInputFelid';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import currentURL from '../../env';
import useSettings from '../../hooks/useSettings';
import BurndownChart from '../today/burndown';
import ReportPrintPage from '../reports/ReportPrintPage';

export default function UserReport() {
  const { themeStretch } = useSettings();
  const baseURL = currentURL?.currentEnv;

  const { userDetails, userList } = useSelector((state) => state.loginReducer);
  const { token, user } = userDetails;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  // const localizer = momentLocalizer(moment);
  const [loading, setLoading] = useState(false);
  const [allTask, setAllTask] = useState({});
  const location = useLocation();
  const [profilePic, setProfilePic] = useState('');
  const [totalTask, setTotalTask] = useState('');
  const [plannnedTime, setPlannnedTime] = useState([]);
  const [spendTime, setSpendTime] = useState([]);
  const [tasktitle, setTaskTitle] = useState([]);
  const [triggerReport, setTriggerReport] = useState(false);

  const [search, setSearch] = useSearchParams();

  // eslint-disable-next-line no-nested-ternary
  const [userID, setUserID] = useState(search.get('user_id') || '');

  const [projectId, setProjectId] = useState(search.get('project_id') || '');
  const [startDate, setStartDate] = useState(
    moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

  const [users, setUser] = useState([]);
  const [project, setProject] = useState([]);



  const getProject = async () => {
    const response = await AxiosAll('get', `/common/projects?user_id=${userID || ''}`, {}, token);
    if (response?.status === 200) {
      setProject(response?.data);
    }
  };

  const limit = 1000;

  const getTasks = async () => {
    setLoading(true);
    // eslint-disable-next-line no-template-curly-in-string
    const response = await AxiosAll(
      'get',
      `/userReport?project_id=${projectId}&user_id=${userID}&start_date=${startDate}&due_date=${endDate}&limit=${limit}`,
      {},
      token
    );
    const { data } = response;

    if (data?.statusCode === 200) {
      setLoading(false);
      setTotalTask(data?.data?.total);
      setAllTask(data?.data);
      setPlannnedTime(
        data?.data?.data?.filter((person) => person.planned_time)?.map((person) => Number(person?.planned_time))
      );
      setSpendTime(
        data?.data?.data?.filter((person) => person.spend_time)?.map((person) => Number(person?.spend_time))
      );
      const title1 = data?.data?.data
        ?.filter((person) => person.title)
        ?.map((person) => `${person?.id} - ${person?.title}`);
      const id1 = data?.data?.data?.filter((person) => person.id)?.map((person) => person?.id);
      setTaskTitle(title1);
    }

  };

  const handleReport = async () => {
    if (userID) {
      try {

        window.open(
          `${baseURL}/download-project-report?file_name=${`user_report_${userList
            .find((user) => user.id === userID)
            ?.full_name.replace(/ /g, '_')}_${projectId ? project.find((user) => user.id === projectId)?.title.replace(/ /g, '_') : 'All_Project'
          }_${moment(startDate).format('YYYY-MM-DD')}_to_${moment(endDate).format('YYYY-MM-DD')}`}&user_report=true
        &project_ids=${projectId ? projectId : ''}&detailed_report=false&user_id=${userID}&start_date=${moment(
            startDate
          ).format('YYYY-MM-DD')}&end_date=${moment(endDate).format('YYYY-MM-DD')}`
        );
        enqueueSnackbar(`Exporting Timesheet Data for ${userList.find((user) => user.id === userID)?.full_name}`, {
          variant: 'success',
        });
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  useEffect(() => {
    getProject();
  }, [userID, user]);

  // useEffect(() => {
  //   getUser();
  // }, []);

  useEffect(() => {
    getTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID, startDate, endDate, projectId]);

  // Handle changes
  const handleUsers = (e) => {
    navigate(
      `${location.pathname}?user_id=${e?.target?.value}&project_id=${''}&start_date=${startDate}&end_date=${endDate}`
    );
    setUserID(e.target.value);
    setProjectId('');
  };
  const handleProject = (e) => {
    navigate(
      `${location.pathname}?user_id=${userID}&project_id=${e?.target?.value}&start_date=${startDate}&end_date=${endDate}`
    );
    setProjectId(e.target.value);
  };

  const handleStartDateChange = (e) => {
    navigate(
      `${location.pathname}?user_id=${userID}&project_id=${projectId}&start_date=${e.target.value}&end_date=${endDate}`
    );
    setStartDate(e.target.value);
  };
  const handleEndDateChange = (e) => {
    navigate(
      `${location.pathname}?user_id=${userID}&project_id=${projectId}&start_date=${startDate}&end_date=${e.target.value}`
    );
    setEndDate(e.target.value);
  };
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    userList && setProfilePic(userList?.find((item) => item?.id === userID));
  }, [userID, userList]);

  const widthWindow = window.innerWidth <= 450;

  return (
    // <Page title="User Report">
    <Container maxWidth={themeStretch ? false : 'lg'}>
      <HeaderBreadcrumbs heading="User's Performance" links={[{}]} />
      <Stack
        // spacing={2}
        className="stack-center marginbtm"
        direction={'row'}
        flexWrap={'wrap'}
        gap={1.5}
      // sx={{ px: 1.5 }}
      >
        <TextField
          fullWidth
          select
          label="User"
          value={userID}
          onChange={handleUsers}
          SelectProps={{
            MenuProps: {
              sx: { '& .MuiPaper-root': { maxHeight: 260 } },
            },
          }}
          sx={{
            maxWidth: { sm: 240 },
            textTransform: 'capitalize',
          }}
          style={{ margin: 0 }}
        >
          <MenuItem
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
            key={''}
            value={''}
          >
            Select
          </MenuItem>
          {userList?.map((option) => (
            <MenuItem
              key={option?.id}
              value={option?.id}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option?.first_name} {option?.last_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          select
          label="Project"
          value={projectId}
          onChange={handleProject}
          SelectProps={{
            MenuProps: {
              sx: { '& .MuiPaper-root': { maxHeight: 260 } },
            },
          }}
          sx={{
            maxWidth: { sm: 240 },
            textTransform: 'capitalize',
          }}
          style={{ margin: 0 }}
        >
          <MenuItem
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
            key={''}
            value={''}
          >
            Select
          </MenuItem>
          {project?.map((option) => (
            <MenuItem
              key={option?.id}
              value={option?.id}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option?.title}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="date1"
          type="date"
          label="Start Date"
          value={startDate}
          onChange={handleStartDateChange}
          InputProps={{ inputProps: { max: moment(new Date()).format('YYYY-MM-DD') } }}
          sx={{
            width: { xs: '100%', sm: 'fit-content' },
          }}
          style={{ margin: 0 }}
        />
        <TextField
          id="date2"
          type="date"
          label="End Date"
          value={endDate}
          onChange={handleEndDateChange}
          style={{ margin: 0 }}
          InputProps={{ inputProps: { max: moment(new Date()).format('YYYY-MM-DD') } }}
          sx={{
            width: { xs: '100%', sm: 'fit-content' },
          }}
        />

        <Button
          size="large"
          variant="contained"
          loading={false}
          disabled={!userID}
          type="button"
          sx={{marginLeft:0}}
          onClick={() => {
            setTriggerReport((prev) => !prev);
          }}
        >
          Apply Filter
        </Button>

        <Button
          size="large"
          variant="contained"
          loading={false}
          disabled={!userID}
          type="button"
          onClick={handleReport}
          sx={{marginLeft:0}}
        >
          Export Report
        </Button>
      </Stack>{' '}
      {!location?.pathname?.includes('/report') && (
        <div style={{ height: 700, marginTop: 15 }}>
          <BurndownChart
            // hideChart={location?.pathname?.includes('/report')}
            plannnedTime={plannnedTime}
            spendTime={spendTime}
            tasktitle={tasktitle}
          />
        </div>
      )}
      {location?.pathname?.includes('/report') && (
        <ReportPrintPage
          triggerReport={triggerReport}
          endDate={endDate}
          startDate={startDate}
          projectId={projectId}
          userID={userID}

        />
      )}
    </Container>
    // </Page>
  );
}
