/* eslint-disable import/order */
import { useEffect, useState } from 'react';
// @mui
import {
    Box, Card,
    Container,
    Divider,
    FormControlLabel, Switch,
    Tab,
    Table,
    TableBody,
    TableContainer,
    TablePagination
} from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/services/axiosAll';
import useSettings from '../../hooks/useSettings';
import useTable from '../../hooks/useTable';
// _mock_
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { TableHeadCustom, TableSkeleton } from '../../components/table';
// sections
// eslint-disable-next-line import/no-unresolved
import { TabContext, TabList, TabPanel } from '@mui/lab';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import FilterTaskToday from 'src/components/filterModal/FilterTaskToday';
import TodayTaskRow from 'src/sections/@dashboard/task/list/TodayTaskRow';

// ----------------------------------------------------------------------

const STATUS_OPTIONS = [
    { id: 0, value: 'In Progress' },
    { id: 1, value: 'Not Started' },
];

const TABLE_HEAD = [
    { id: 'developer', label: 'Developer', align: 'left' },
    { id: 'title', label: 'Title', align: 'left' },
    { id: 'project', label: 'Project', align: 'left' },
    { id: 'priority', label: 'priority', align: 'center' },
    { id: 'status', label: 'Status', align: 'left' },
    { id: 'creator', label: 'Creator', align: 'left' },
    { id: 'time', label: 'Alloted Time', align: 'left' },
    { id: 'spend', label: 'Spend Time', align: 'left' },
    // { id: 'parent', label: 'Parent task', align: 'left' },
    // { id: 'action', label: 'Action', align: 'left' },
];

// ----------------------------------------------------------------------

export default function Today() {
    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        setPage,
        selected,
        onSelectAllRows,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage,
    } = useTable();

    const { themeStretch } = useSettings();

    // get redux state
    const { userDetails ,userList  , priorityList  } = useSelector((state) => state.loginReducer);
    const { token } = userDetails;
    const [tableData, setTableData] = useState([]);
    const [filterTitle, setFilterTitle] = useState('');
    const [loading, setLoading] = useState(false);

    // Default values
    let startdate = moment();
    startdate = startdate.format("YYYY-MM-DD");
    const [StatusId, setStatusId] = useState(2);

    const [search, setSearch] = useSearchParams();

    const [userID, setUserID] = useState(search.get("user_id") || '');
    const [projectId, setProjectId] = useState(search.get("project_id") || '');
    const [prioritieId, setPrioritieId] = useState(search.get("priority") || '')

    const [count, setCount] = useState(0);

    const [project, setProject] = useState([]);
    const [priority, setPriority] = useState([]);
    const [users, setUser] = useState([]);

    // const getUser = async () => {
    //     setUser([]);
    //     const response = await AxiosAll('get', '/common/users', {}, token);
    //     if (response?.status === 200) {
    //         setUser(response?.data);
    //     }
    // };

    const getProject = async () => {
        setProject([]);
        const response = await AxiosAll('get', `/common/projects?type=add_log&user_id=${userID}`, {}, token);
        if (response?.status === 200) {
            setProject(response?.data);
        }
    };

    // const geTPriority = async () => {
    //     setPriority([]);
    //     const response = await AxiosAll('get', `/task-priorities`, {}, token);
    //     if (response?.status === 200) {
    //         await setPriority(response?.data?.data?.data);
    //     }
    // };
    // useEffect(() => {
    //     getUser();
    // }, []);

    useEffect(() => {
        getProject();
    }, [userID]);

    // useEffect(() => {
    //     geTPriority();
    // }, []);


    const getTasks = async () => {
        setLoading(true);
        // eslint-disable-next-line no-template-curly-in-string
        const response = await AxiosAll('get', `/tasks?page=${page + 1}&query=${filterTitle}&project_id=${projectId}&user_id=${userID}&priority=${prioritieId}&status=${StatusId}&due_date=${startdate}`, {}, token);
        const { data } = response;

        if (data?.statusCode === 200) {
            setLoading(false);
            setTableData(data?.data?.data);
            setCount(response?.data?.data?.total);
        }
    };

    useEffect(() => {
        getTasks();
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, filterTitle, projectId, userID, StatusId, prioritieId, search]);

    const denseHeight = dense ? 52 : 72;

    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === "2") {
            setStatusId(1)
        } else if (newValue === "1") {
            setStatusId(2);
        }
    };
    return (
        <Page title="Tasks : List">
            <Container maxWidth={themeStretch ? false : 'lg'}>

                <HeaderBreadcrumbs
                    heading="Today's Task List"
                    links={[{ name: 'Dashboard', href: '/dashboard' }, { name: 'Today Tasks' }]}
                />
                <FilterTaskToday
                    userID={userID}
                    projectId={projectId}
                    prioritieId={prioritieId}

                    setUserID={setUserID}
                    setProjectID={setProjectId}
                    setPrioritieId={setPrioritieId}
                    setPage={setPage}

                    users={userList}
                    priorities={priorityList}
                    project={project}
                />
                <Card>
                    {/* <Tabs
                        allowScrollButtonsMobile
                        variant="scrollable"
                        scrollButtons="auto"
                        sx={{ px: 2, bgcolor: 'background.neutral' }}
                        value={StatusId} onChange={handleChange} aria-label="basic tabs example"
                    > */}
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example" sx={{ px: 2, bgcolor: 'background.neutral' }}>
                                    <Tab label="In Progress" value="1" />
                                    <Tab label="Not Started" value="2" />
                                </TabList>
                            </Box>
                            <TabPanel value="1"><>
                                <Divider />

                                <Scrollbar>
                                    <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                                        <Table size={dense ? 'small' : 'medium'}>
                                            <TableHeadCustom
                                                hideCheckbox
                                                order={order}
                                                // orderBy={orderBy}
                                                headLabel={TABLE_HEAD}
                                                rowCount={tableData.length}
                                                numSelected={selected.length}
                                                onSelectAllRows={(checked) =>
                                                    onSelectAllRows(
                                                        checked,
                                                        tableData.map((row) => row.id)
                                                    )
                                                }
                                            />
                                            {loading ? (
                                                <>
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                </>
                                            ) : (
                                                <TableBody>
                                                    {tableData.map((row) => (
                                                        <TodayTaskRow
                                                            key={row.id}
                                                            row={row}
                                                            getTasks={() => getTasks()}
                                                        />
                                                    ))}

                                                    {/* <TableNoData isNotFound={isNotFound} /> */}
                                                </TableBody>
                                            )}
                                        </Table>
                                    </TableContainer>
                                </Scrollbar>

                                <Box sx={{ position: 'relative' }}>
                                    <TablePagination
                                        rowsPerPageOptions={[20]}
                                        component="div"
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={onChangePage}
                                        onRowsPerPageChange={onChangeRowsPerPage}
                                    />

                                    <FormControlLabel
                                        control={<Switch checked={dense} onChange={onChangeDense} />}
                                        label="Dense"
                                        sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                                    />
                                </Box>
                            </></TabPanel>
                            <TabPanel value="2"><>
                                <Divider />

                                <Scrollbar>
                                    <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                                        <Table size={dense ? 'small' : 'medium'}>
                                            <TableHeadCustom
                                                hideCheckbox
                                                order={order}
                                                // orderBy={orderBy}
                                                headLabel={TABLE_HEAD}
                                                rowCount={tableData.length}
                                                numSelected={selected.length}
                                                onSelectAllRows={(checked) =>
                                                    onSelectAllRows(
                                                        checked,
                                                        tableData.map((row) => row.id)
                                                    )
                                                }
                                            />
                                            {loading ? (
                                                <>
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                </>
                                            ) : (
                                                <TableBody>
                                                    {tableData.map((row) => (
                                                        <TodayTaskRow
                                                            key={row.id}
                                                            row={row}
                                                            getTasks={() => getTasks()}
                                                        />
                                                    ))}

                                                    {/* <TableNoData isNotFound={isNotFound} /> */}
                                                </TableBody>
                                            )}
                                        </Table>
                                    </TableContainer>
                                </Scrollbar>

                                <Box sx={{ position: 'relative' }}>
                                    <TablePagination
                                        rowsPerPageOptions={[20]}
                                        component="div"
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={onChangePage}
                                        onRowsPerPageChange={onChangeRowsPerPage}
                                    />

                                    <FormControlLabel
                                        control={<Switch checked={dense} onChange={onChangeDense} />}
                                        label="Dense"
                                        sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                                    />
                                </Box>
                            </></TabPanel>
                        </TabContext>
                    </Box>

                    {/* </Tabs> */}

                </Card>
            </Container>
        </Page>
    );
}
