/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

import { CardContent, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// _mock
import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
import Markdown from 'src/components/Markdown';
import AxiosAll from 'src/services/axiosAll';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------

SinglieLogDetail.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    idToOpen: PropTypes.number,
};

export default function SinglieLogDetail({ open, setOpen, idToOpen }) {
    const { userDetails } = useSelector((state) => state.loginReducer);
    const [log, setLog] = useState([]);
    const [loading, setLoading] = useState(false);
    const handleOnClose = () => {
        setOpen(false)
    }

    const getLogDetail = async () => {
        const response = await AxiosAll('get', `/task-time-logs/${idToOpen}`, null, userDetails.token);
        const { data } = response;
        await setLog(data?.data);
        await setLoading(true);
        return {};
    };

    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        open && getLogDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idToOpen, open]);

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleOnClose}>

            {loading && <DialogContent>
                <CardContent>
                    <Grid container spacing={3}>

                        <Grid item xs={12} md={6}>
                            {/* <Avatar alt={log?.user?.first_name} src={log?.user?.profile_picture} sx={{ mr: 2 }} /> */}
                            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                Developer
                            </Typography>
                            <Typography variant="subtitle2" noWrap>
                                {`${log?.user?.first_name} ${log?.user?.last_name}` || "Pseudo User"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                Task Title
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {log?.task?.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                Project
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {log?.project?.title}
                            </Typography>
                        </Grid>
                        {log?.task && <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                Description
                            </Typography>
                            <Markdown children={log?.task?.description} />
                        </Grid>}
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                Start Time
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {log?.start_time}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                End Time
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {log?.end_time}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                Comment
                            </Typography>
                            <Markdown children={log?.comment} />
                        </Grid>
                    </Grid>
                </CardContent>
            </DialogContent>}

        </Dialog>
    );
}
