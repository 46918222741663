import PropTypes from 'prop-types';
// @mui
import { Avatar, Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  justifyContent: 'center',
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { rcount } = useSelector((state) => state.reviewReducer);

  return (
    <Link underline="none" color="inherit" >
      <RootStyle

        className='main_profile_box'
        sx={{
          justifyContent: 'flex-start',
          ...(isCollapse && {
            bgcolor: 'transparent',
            justifyContent: 'center'

          }),
        }}
      >
        <Avatar src={`${userDetails?.user?.profile_picture}`} alt="Rayan Moran" />

        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="subtitle2" noWrap>
            {`${userDetails?.user?.first_name} ${userDetails?.user?.last_name}`}
          </Typography>
          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {`${userDetails?.user?.roles[0]?.name}`}
          </Typography>
        </Box>
      </RootStyle>
      {userDetails?.user?.roles[0]?.id !== 1 &&
        <Box style={{ display: 'flex', marginTop: '10px', }}
          sx={{
            ...(isCollapse && {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: "62%",
              margin: 'auto',

            }),

          }}>
          <Typography variant="body2" noWrap sx={{
            color: 'text.secondary',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            ...(isCollapse && {
              overflow: ' hidden',
              textOverflow: 'initial',
              whiteSpace: 'inherit',
            })
          }} className='spend-time-text'>
            Today's Spend Time&nbsp; : &nbsp;
          </Typography>
          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {rcount?.total_time_spent}
          </Typography>
        </Box>}
    </Link>
  );
}
