/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import InputMask from 'react-input-mask';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// routes
// import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import axios from 'axios';
import moment from 'moment';
import { newServer } from 'src/core';
import AxiosAll from 'src/services/axiosAll';
import { FormProvider, RHFEditor, RHFSelect, RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/Iconify';
import InputTable from 'src/components/table/InputTable';
import LoadingScreen from 'src/components/LoadingScreen';
// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------

TaskNewAddForm.propTypes = {
  task: PropTypes.object,
};

export default function TaskNewAddForm({ task }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const [userData, setUserData] = useState({});
  const [updateMemberRow, SetUpdateMemberRow] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const { userDetails, projectList } = useSelector((state) => state.loginReducer);
  const [loading, setLoading] = useState(true);
  const [userDropdown, setUserDropdown] = useState([]);
  const [row, setRow] = useState(0);
  const isLead = userDetails?.user?.roles?.some((role) => role.id === 3);
  const currentPath = window.location.pathname !== '/tasks/minuteofMeeting' ? window.location.pathname : '';
  const pathParts = currentPath.split('/');
  const MomMid = pathParts[pathParts.length - 1];
  const { token } = userDetails;

  const NewProductSchema = Yup.object().shape({
    project: Yup.string().required('Project is required'),
    title: Yup.string().required(' title is required'),
    startDate: Yup.string().required('Start date is required'),
  });

  // console.log('MomMid', MomMid);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('momPojectDetail'));
    if (storedData) {
      setValue('project', storedData?.project);
      setValue('title', storedData?.title);
      setValue('startDate', storedData?.startDate || moment(new Date()).format('yyyy-MM-DD'));
    } else if (window.location.pathname === '/tasks/minuteofMeeting') {
      localStorage.setItem('momPojectDetail', JSON.stringify(values));
    }
  }, []);

  const defaultValues = useMemo(
    () => ({
      title: '',
      project: '',
      startDate: moment(new Date()).format('yyyy-MM-DD'),
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewProductSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    localStorage.setItem('momPojectDetail', JSON.stringify(values));
  }, [values]);

  // Common dropdowns
  // const getProjects = async () => {
  //   const res = await AxiosAll('get', '/common/projects', {}, token);
  //   if (res?.status === 200) {
  //     const newData = res?.data;
  //     setProjectData(newData);
  //   }
  // };

  const getUser = async () => {
    const response = await AxiosAll('get', `/common/users`, null, token);
    if (response?.status === 200) {
      const { data } = response;
      const newData = data?.map((item) => {
        const { first_name, last_name, ...rest } = item;
        return { ...rest, label: `${first_name} ${last_name}` };
      });
      setUserData(newData);
      setLoading(false);
    }
  };

  const getMOMById = async () => {
    const response = await AxiosAll('get', `/minutes-of-meeting/${MomMid}`, null, token);
    if (response?.status === 200) {
      const { data } = response?.data;
      // console.log(data?.data);
      setValue('project', data?.project_id);
      setValue('title', data?.title);
      setValue('startDate', data?.date);

      const updatedMomActions = data?.mom_actions.map((item) => {
        return {
          ...item,
          LocalId: item?.id /* generate or assign a LocalId value here */,
        };
      });
      SetUpdateMemberRow(updatedMomActions);
      setLoading(false);
    }
  };

  useEffect(() => {
    // getProjects();
    getUser();
    if (MomMid) {
      getMOMById();
    }
  }, [MomMid]);

  // useEffect(() => {
  //     // remove users from dropdown if already selected and add if unselected
  //     const newData = userData?.filter((item) => {
  //       const found = values?.users?.find((user) => user.id === item.id);
  //       if (found) {
  //         return false;
  //       }
  //       return true;
  //     });
  //     setUserData(newData);
  //     // setUsers(values?.users);
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [values.users, userData]);

  const onSubmit = () => {};
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Stack sx={{ width: '100%' }}>
                  <LabelStyle>Project</LabelStyle>

                  <RHFSelect name="project">
                    <option value={''}>Select</option>
                    {projectList?.map((item, index) => (
                      <option value={item?.id} key={index}>
                        {item?.title}
                      </option>
                    ))}
                  </RHFSelect>
                </Stack>

                <Stack sx={{ width: '100%' }}>
                  <LabelStyle>Meeting Date</LabelStyle>
                  <RHFTextField name="startDate" type="date" />
                </Stack>
              </Stack>

              <Stack sx={{ display: 'flex', flex: 1, flexDirection: 'column', mt: 2 }}>
                <LabelStyle>Agenda</LabelStyle>
                <RHFTextField name="title" />
              </Stack>
            </Card>

            <InputTable
              path={window.location.pathname === '/tasks/minuteofMeeting'}
              userData={userData}
              values={values}
              updateMemberRow={updateMemberRow}
              MomMId={MomMid}
            />
          </Grid>
        </Grid>
      )}
    </FormProvider>
  );
}
