import { useSelector } from 'react-redux';
import Label from 'src/components/Label';

export default function ForCountOnly({ type }) {
  const { rcount } = useSelector((state) => state.reviewReducer);
  const checkTYpe = (type) => {
    // eslint-disable-next-line no-unused-expressions
    if (type === 'review' && rcount?.task_review_count > 0) {
      return <Label color="error">{rcount?.task_review_count}</Label>;
    }
    if (type === 'testing' && rcount?.in_testing_tasks_count > 0) {
      return <Label color="error">{rcount?.in_testing_tasks_count}</Label>;
    }
    if (type === 'team_task' && rcount?.team_tasks_count > 0) {
      return <Label color="error">{rcount?.team_tasks_count}</Label>;
    }
    if (type === 'task' && rcount?.total_tasks_count > 0) {
      return <Label color="error">{rcount?.total_tasks_count}</Label>;
    }
    if (type === 'holiday' && rcount?.holidays_count > 0) {
      return <Label color="error">{rcount?.holidays_count}</Label>;
    }
    // <div>
    //     {rcount?.holidays_count > 0 && <Label color="error">{rcount?.holidays_count}</Label>}
    // </div>
    if (type === 'favourite_task' && rcount?.favorite_tasks_count > 0) {
      return <Label color="error">{rcount?.favorite_tasks_count}</Label>;
    }
    if (type === 'tracker' && rcount?.tracker_count > 0) {
      return <Label color="error">{rcount?.tracker_count}</Label>;
    }
  };

  return checkTYpe(type);
}
