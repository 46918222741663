import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import AxiosAll from 'src/services/axiosAll';
import { useSnackbar } from 'notistack';
import { CircularProgress } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const LogoutModal = ({ logsCompleted, setLogOut, logOut, setLogin }) => {
  const [open, setOpen] = React.useState(false);
  const { rcount } = useSelector((state) => state.reviewReducer);
  const { enqueueSnackbar } = useSnackbar();
  const { userDetails } = useSelector((state) => state.loginReducer);
  const [loading, setLoading] = React.useState(false);
  const timeString = '02:30';
  const [hours, minutes] = rcount?.total_time_spent.split(':').map(Number);
  const totalMinutes = hours * 60 + minutes;
  console.log('rcount', totalMinutes);

  const handleClickOpen = () => {
    setOpen(true);
  };
  //   const handleClose = () => {
  //     setOpen(false);
  //   };
  const handleClose = () => {
    setLogOut(false);
  };

  const handleCloseLogOut = async () => {
    setLoading(true);
    try {
      const res = await AxiosAll(
        'post',
        `/availability`,
        {
          availability_status_id: 3,
        },
        userDetails?.token
      );
      const data = res.data.data.data;
      // setActivityList(data);
      console.log(res);
      if (res?.status === 201) {
        setLogOut(false);
        setLogin(false);
        enqueueSnackbar(`${res?.data?.data?.title} successfully!`, { variant: 'success' });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={logOut}
        className="logout_dialogs"
        fullWidth
        maxWidth="sm"
      >
        {true ? (
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            LogOut
          </DialogTitle>
        ) : (
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            LogOut
          </DialogTitle>
        )}

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {totalMinutes >= 8 * 60 ? (
            <Typography variant="h6">Hope you have completed all the assigned tasks for the day.</Typography>
          ) : (
            <Typography variant="h6">Please complete the logs.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          {totalMinutes >= 8 * 60 ? (
            <Button variant="contained" onClick={handleCloseLogOut}>
              {loading ? 'Loading...' : 'Logout'}
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  backgroundColor: logOut ? 'green' : 'green',
                  '&:hover': {
                    backgroundColor: logOut ? 'green' : 'green',
                  },
                }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                onClick={handleCloseLogOut}
                sx={{
                  backgroundColor: logOut ? 'red' : 'red',
                  '&:hover': {
                    backgroundColor: logOut ? 'red' : 'red',
                  },
                }}
              >
                {loading ? 'Loading...' : 'Logout'}
              </Button>
            </>
          )}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default LogoutModal;
