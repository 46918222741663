import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DashboardLayout from '../layouts/dashboard/index';
import PublicRoutes from './public';


// It is a main file for routing, from here we choose public or private route(based on role id also)
const Routes = () => {
  const [role, setRole] = useState('');
  const { userDetails } = useSelector((state) => state.loginReducer);
  // useSelector Allows you to extract data from the Redux store state, using a selector function.


  useEffect(() => {
    // Now we store the role id in state and pass it to routes
    setRole(userDetails?.user?.roles[0]?.id);
  }, [userDetails]);
  // If user has token or logged in then DashboardLayout route otherwise public routes
  return <>{userDetails?.token ? <DashboardLayout role={role} /> : <PublicRoutes />}</>;
};

export default Routes;
