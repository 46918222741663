// import { paramCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Button,
  Card,
  Container,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Tooltip
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/services/axiosAll';

// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import ConfirmDialogue from 'src/components/dialouge/ConfirmDialogue';
import useSettings from '../../hooks/useSettings';
import useTable from '../../hooks/useTable';
import useTabs from '../../hooks/useTabs';
// _mock_
// import { _userList } from '../../_mock';
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { TableHeadCustom, TableNoData, TableSelectedActions, TableSkeleton } from '../../components/table';
// sections
import { UserTableRow, UserTableToolbar } from '../../sections/@dashboard/user/list';
import ChangePassword from './dialogue/ChangePassword';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'role', label: 'Role', align: 'center' },
  { id: 'team', label: 'Team', align: 'center' },
  { id: 'wHours', label: 'Working Hours', align: 'center' },
  { id: 'action', label: 'Action', align: 'center' },
];

// ----------------------------------------------------------------------

export default function UserList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();
  const { userDetails } = useSelector((state) => state.loginReducer);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [tableData, setTableData] = useState([]);
  const [currentUserId, setCurrentUserId] = useState('');
  const [count, setCount] = useState(10);
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  // const [page, setPage] = useState(1)

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');

  const { enqueueSnackbar } = useSnackbar();

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
  };

  const handleFilterRole = (event) => {
    setFilterRole(event.target.value);
    setPage(0);
  };

  const handleDeleteRows = (selected) => {
    const deleteRows = tableData.filter((row) => !selected.includes(row.id));
    setSelected([]);
    setTableData(deleteRows);
  };

  const handleEditRow = (id) => {
    navigate(`/manage-user/edit-user/${id}`);
  };

  const getUsers = async () => {
    try {
      setLoading(true);
      const res = await AxiosAll(
        'get',
        `/users?page=${page + 1}&page_count=${10}&query=${filterName}`,
        null,
        userDetails.token
      );

      const data = res.data.data.data;
      setRowsPerPage(res?.data?.data?.per_page);
      setTableData(data);
      setCount(res.data.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleConfirmDelete = () => {
    handleDeleteRow(deleteId);

    setDeleteDialog(false);
  };

  useEffect(() => {
    getUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filterName]);

  const handleDeleteRow = async (id) => {
    try {
      const res = await AxiosAll('delete', `/users/${id}`, {}, userDetails.token);
      if (res.data.statusCode === 200) {
        enqueueSnackbar('User deleted successfully', {
          variant: 'success',
        });
        getUsers();
      } else {
        enqueueSnackbar('Something went wrong', {
          variant: 'error',
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePasswordChange = async (id) => {
    setOpen(true);
    setCurrentUserId(id);
  };

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!tableData?.length && !!filterName) ||
    (!tableData?.length && !!filterRole) ||
    (!tableData?.length && !!filterStatus);

  return (
    <Page title="User: List">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="User List"
          links={[{ name: 'Dashboard', href: '/dashboard' }, { name: 'User List' }]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={'/manage-user/create-user'}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
            >
              New User
            </Button>
          }
        />

        <Card>
          <UserTableToolbar
            filterName={filterName}
            filterRole={filterRole}
            onFilterName={handleFilterName}
            onFilterRole={handleFilterRole}
          />
          <ConfirmDialogue
            open={deleteDialog}
            loading={loading}
            onClose={() => setDeleteDialog(false)}
            onConfirm={handleConfirmDelete}
            title={`Are you sure you want to delete this user?`}
            content="This action cannot be undone. Please confirm to proceed."
          />

          <ChangePassword open={open} onClose={() => setOpen(false)} id={currentUserId} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  hideCheckbox
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                {loading ? (
                  <TableSkeleton />
                ) : (
                  <TableBody>
                    {tableData?.map((row) => (
                      <UserTableRow
                        key={row.id}
                        row={row}
                        setDeleteDialog={setDeleteDialog}
                        setDeleteId={setDeleteId}
                        handlePasswordChange={handlePasswordChange}
                        selected={selected.includes(row.id)}
                        onSelectRow={() => onSelectRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                        onEditRow={() => handleEditRow(row.id)}
                      />
                    ))}

                    {/* <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} /> */}

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterName, filterStatus, filterRole }) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter((item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
  }

  if (filterStatus !== 'all') {
    tableData = tableData.filter((item) => item.status === filterStatus);
  }

  if (filterRole !== 'all') {
    tableData = tableData.filter((item) => item.role === filterRole);
  }

  return tableData;
}
