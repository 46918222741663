import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import { Alert, Button, FormControlLabel, Stack, Switch, Tooltip } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import AxiosAll from 'src/services/axiosAll';
import { RHFTextField } from '../hook-form';
import Iconify from '../Iconify';
import MobilePicker from '../Timepicker';

// import { FormControlLabel, FormGroup, Switch } from '@mui/material';


function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number,
) {
    return { name, calories, fat, carbs, protein };
}


export default function MoMTable({ userData, values, path, MomMId, updateMemberRow }) {
    const { userDetails } = useSelector((state) => state.loginReducer);
    const { token, user } = userDetails;
    const [message, setMessage] = useState('')
    const [deleteminute, setDeleteMinute] = useState([])
    const navigate = useNavigate()
    const [meeting, setMeetingRows] = React.useState([])

    React.useEffect(() => {

        setMeetingRows(updateMemberRow);

    }, [updateMemberRow])
    // console.log("check aray or not ", Array.isArray(userData)); // Output should be true if userData is an array


    const getAssignUser = (id) => {
        if (id !== 0) {
            const result = userData && userData?.find(e => e?.id === id);
            return result?.full_name;
        }
        // Handle the case when id is 0 or undefined
        return null;
    };


    // console.log("meeting", meeting)
    return (
        <TableContainer component={Paper} sx={{ mt: 4 }}>
            {message && <Alert severity="error">{message}</Alert>}

            {userData && userData?.length > 0 ? <>         <Table sx={{ minWidth: 650 }} aria-label="simple table" className='addmom_table'>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Subject</TableCell>
                        <TableCell align="center">Description</TableCell>
                        <TableCell align="center">Action Point</TableCell>
                        <TableCell align="center">Assign User</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody >
                    {meeting?.map((row, index) => (
                        <>

                            <TableRow
                                key={row.id}
                                sx={{ verticalAlign: 'top', cursor: row?.id && row?.create_task ? 'pointer' : "" }}
                            // onClick={() => row?.id && row?.create_task && navigate(`/tasks/edit/${row?.task_id}`)}
                            >

                                <TableCell scope="row">
                                    {row?.subject}
                                </TableCell>
                                <TableCell align="center">
                                    {row?.description}

                                </TableCell>
                                <TableCell scope="row">
                                    {row?.action_point}

                                </TableCell>
                                <TableCell sx={{ display: 'flex', justifyContent: 'center', }} className='auto_select_box'>
                                    {getAssignUser(row?.assigned_user)
                                    }
                                </TableCell>




                            </TableRow>

                        </>
                    ))}


                </TableBody>


            </Table>
            </> : null}

        </TableContainer>
    );
}
