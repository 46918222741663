import { useRef } from 'react';
// @mui
import { Button, Card, Container, Grid, Stack, Typography } from '@mui/material';
// hooks
// eslint-disable-next-line import/no-unresolved
import ChartMenu from 'src/components/dailogs/CardDownload';
// components
import { useLocation } from 'react-router';
import Page from '../../components/Page';
import EmployeeProfileCard from './EmployeeProfileCard';

const EmployeeCard = () => {
  const cardRef = useRef();
  const { state } = useLocation();

  const printDiv = () => {
    const printContents = cardRef.currentTarget.innerHTML;
    const w = window.open();
    w.document.write(printContents);
    w.document.title = `${state?.currentUser.first_name}_${state?.currentUser.last_name}_Employee_Card`;
    w.print();
  };
  return (
    <Page title="Employee Card">
      <Container maxWidth="lg">
        <Card>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack
                direction={{ xs: 'column', sm: 'column' }}
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
                className="active-users-sec"
              >
                <Stack
                  direction={{ xs: 'row', sm: 'row' }}
                  justifyContent="space-around"
                  style={{ width: '100%' }}
                  sx={{ mt: 2 }}
                >
                  <Typography variant="h3">Employee card</Typography>
                  <ChartMenu />
                </Stack>
                <div style={{ marginBottom: 20 }}>
                  <div ref={cardRef}>
                    <EmployeeProfileCard
                      userName={state?.currentUser.first_name}
                      userSurname={state?.currentUser.last_name}
                      userRole={state?.currentUser.position}
                      userEmail={state?.currentUser.email}
                      userProfile={state?.currentUser.profile_picture}
                      userExDate={'30/06/2024'}
                      userEMPID={state?.currentUser.employee_id}
                    />
                  </div>
                </div>
              </Stack>
            </Grid>
          </Grid>
        </Card>
        <Button onClick={printDiv}>EmployeeCard</Button>
      </Container>
    </Page>
  );
};

export default EmployeeCard;
