import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Button,
  Card,
  Container, FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  // TablePagination,
  Tooltip
} from '@mui/material';
// routes
// hooks
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import ConfirmDialogue from 'src/components/dialouge/ConfirmDialogue';
// import { TABLE_HEAD_COLOR } from 'src/core'
import AxiosAll from 'src/services/axiosAll';
import useSettings from '../../hooks/useSettings';
import useTable from '../../hooks/useTable';
import useTabs from '../../hooks/useTabs';
// _mock_
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import {
  TableHeadCustom,
  TableNoData,
  TableSelectedActions,
  TableSkeleton
} from '../../components/table';
// sections
import { ProjectStatusTableRow, ProjectStatusTableToolbar } from '../../sections/@dashboard/project_status/list';
import CreateProjectStatus from './Create';

// ----------------------------------------------------------------------
export const TABLE_HEAD_COLOR = [
  { id: 'name', label: 'Name', align: 'center' },
  { id: 'color', label: 'Color', align: 'center' },
  { id: 'icon', label: '', align: 'center' },
];
export default function ProjectStatus() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  const [currentStatus, setCurrentStatus] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [multipleDeleteMode, setMultipleDeleteMode] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;

  const { enqueueSnackbar } = useSnackbar();

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterRole = (event) => {
    setFilterRole(event.target.value);
  };

  const handleDeleteRow = async (id) => {
    const response = await AxiosAll('delete', `/project-statuses/${id}`, {}, token);
    const { data } = response;
    if (data?.statusCode === 200) {
      enqueueSnackbar(data?.message, { variant: 'success' });
      if (selected.length > 0) {
        setSelected([]);
      }
      getAllStatus();
    } else {
      enqueueSnackbar(data?.message, { variant: 'error' });
    }
  };

  const handleDeleteRows = async (selected) => {
    const response = await AxiosAll('delete', `/project-statuses`, { ids: selected }, token);
    const { data } = response;
    if (data?.statusCode === 200) {
      enqueueSnackbar(data?.message, { variant: 'success' });
      setSelected([]);
      getAllStatus();
    } else {
      enqueueSnackbar(data?.message, { variant: 'error' });
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? '#ebebeb' : 'white',
    ...draggableStyle,
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const movedItems = reorder(tableData, result.source.index, result.destination.index);
    setTableData(movedItems);
  };

  const getAllStatus = async () => {
    setLoading(true);
    const response = await AxiosAll('get', `/project-statuses?page=${page + 1}&query=${filterName}`, {}, token);
    const { data } = response;
    window.scrollTo(0, 0);

    if (data?.statusCode === 200) {
      setRowsPerPage(data?.data?.per_page);
      setTableData(data?.data?.data);
      setCount(data?.data?.total);
      setLoading(false);
    }
  };

  const getSingleStatus = async (id) => {
    const response = await AxiosAll('get', `/project-statuses/${id}`, {}, token);
    const { data } = response;

    if (data?.statusCode === 200) {
      setCurrentStatus(data?.data);
    }
  };

  useEffect(() => {
    getAllStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filterName]);

  const handleEditRow = (id) => {
    getSingleStatus(id);
    setIsEdit(true);
    setOpen(true);
  };

  const handleConfirmDelete = () => {
    if (selected.length > 0 && multipleDeleteMode) {
      handleDeleteRows(selected);
    } else {
      handleDeleteRow(deleteId);
    }
    setDeleteDialog(false);
  };

  const closeDialog = () => {
    setOpen(false);
    if (isEdit) {
      setIsEdit(false);
      setCurrentStatus([]);
    }
  };

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!tableData?.length && !!filterName) ||
    (!tableData?.length && !!filterRole) ||
    (!tableData?.length && !!filterStatus);

  return (
    <Page title="Project Status: List">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Project Status List"
          links={[{ name: 'Dashboard', href: '/dashboard' }, { name: 'Project Status List' }]}
          action={
            <Button variant="contained" onClick={() => setOpen(true)} 
            sx={{mt:{xs: 2, sm: 0}}}
            startIcon={<Iconify icon={'eva:plus-fill'} />}>
              New Status
            </Button>
          }
        />

        <Card>
          <CreateProjectStatus
            open={open}
            onClose={closeDialog}
            currentStatus={currentStatus}
            getAllStatus={getAllStatus}
            isEdit={isEdit}
          />

          <ConfirmDialogue
            open={deleteDialog}
            loading={loading}
            onClose={() => setDeleteDialog(false)}
            onConfirm={handleConfirmDelete}
            title={`Are you sure you want to delete ${multipleDeleteMode && selected.length > 1 ? 'these' : 'this'
              } status?`}
            content="This action cannot be undone. Please confirm to proceed."
          />

          <ProjectStatusTableToolbar
            filterName={filterName}
            filterRole={filterRole}
            onFilterName={handleFilterName}
            onFilterRole={handleFilterRole}
            search="status"
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setDeleteDialog(true);
                          setMultipleDeleteMode(true);
                        }}
                      >
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  hideCheckbox
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD_COLOR}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                {loading ? (
                  <>
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                  </>
                ) : (
                  <TableBody>
                    {tableData?.map((row) => (
                      <ProjectStatusTableRow
                        key={row.id}
                        row={row}
                        setDeleteDialog={setDeleteDialog}
                        setMultipleDeleteMode={setMultipleDeleteMode}
                        setDeleteId={setDeleteId}
                        getItemStyle={getItemStyle}
                        selected={selected.includes(row.id)}
                        onSelectRow={() => onSelectRow(row.id)}
                        // onDeleteRow={() => handleDeleteRow(row.id)}
                        onEditRow={() => handleEditRow(row.id)}
                      />
                    ))}

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}