/* eslint-disable react/prop-types */
import { Typography } from '@mui/material';
import { imagesAll } from 'src/assets/Images_index';
import Image from 'src/components/Image';
import BarcodeGenerator from './BarcodeGenrator';

const EmployeeProfileCard = ({
  userName,
  userSurname,
  userRole,
  userBarCode,
  userProfile,
  userDOB,
  userEmail,
  userEMPID,
  userNumber,
  userExDate,
  charRef,
  employeeId,
}) => {
  const barcodeNumber = '123456789012';

  return (
    <div ref={charRef}>
      <table
        id="employee-card"
        className="employee-card"
        style={{ width: '350px', height: 'auto', margin: 'auto', boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.1)' }}
      >
        <tr>
          <td className="top-bar" style={{ display: 'flex', width: '100%' }}>
            <img src={imagesAll.topBannerRSVR} alt="Company Logo" style={{ width: '350px' }} />
          </td>
        </tr>
        <tr>
          <td
            className="profile-flex"
            style={{ display: 'flex', width: '100%', gap: '2.2mm', alignItems: 'center', justifyContent: 'center' }}
          >
            <div
              className="main-profile-alignment"
              style={{ width: '32mm', height: 'auto', position: 'relative', marginTop: '15px' }}
            >
              {/* <BarcodeGenerator
                number={userBarCode || barcodeNumber}
              /> */}
              <img
                src={userProfile || imagesAll.noImages}
                alt="Company Logo"
                className="profile-photo"
                style={{
                  width: '22mm',
                  height: '22mm',
                  marginLeft: '10mm',
                  objectFit: 'cover',
                  border: '1px solid #5FA7B3',
                }}
              />
            </div>
            <div className="user-profile-name" style={{ display: 'flex', flexDirection: 'column', gap: '0.5mm' }}>
              <p
                className="user-name"
                style={{ fontSize: 17, fontFamily: 'Poppins', fontWeight: 600, color: '#AE2F69', margin: 0 }}
              >
                {userName} <span style={{ color: '#252326' }}>{userSurname}</span>
              </p>
              <p
                className="role"
                style={{
                  fontSize: 10,
                  fontWeight: 900,
                  fontFamily: 'Poppins, sans-serif',
                  letterSpacing: 1,
                  margin: 0,
                }}
              >
                {userRole}
              </p>
            </div>
          </td>
        </tr>
        <tr>
          <td style={{ width: '100%' }}>
            <div style={{ width: '20%', height: 1, background: 'black', borderRadius: 5, margin: '10px auto' }} />
            <div style={{ width: 'fit-content', margin: 'auto' }}>
              <table style={{ marginTop: 15, marginBottom: 15 }}>
              <tr>
                  <td>
                    <p style={{ fontSize: 12 }}>ID No :</p>
                  </td>
                  <td>
                    <p style={{ fontSize: 12 }}>{`RSVR${employeeId||"1000000"}`}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p style={{ fontSize: 12 }}>Email :</p>
                  </td>
                  <td>
                    <p style={{ fontSize: 12 }}>{userEmail}</p>
                  </td>
                </tr>

               {userNumber? <tr>
                  <td>
                    <p style={{ fontSize: 12 }}>Phone :</p>
                  </td>
                  <td>
                    <p style={{ fontSize: 12 }}>{userNumber || ''}</p>
                  </td>
                </tr>:null}
                <tr>
                  <td>
                    <p style={{ fontSize: 12 }}>Expire :</p>
                  </td>
                  <td>
                    <p style={{ fontSize: 12 }}>{'30/06/2024'}</p>
                  </td>
                </tr>
              </table>
            </div>
          </td>
        </tr>
        <tr>
          <td style={{ width: '100%', padding: '0 6mm', marginTop: '1mm' }}>
            <ul>
              <li style={{ fontSize: 12 }}>
                This digital ID serves as your official RSVR Remote Services Verification (RSVR) card, valid for 1 year
                from the date of issue. For detailed policies and guidelines, please refer to the policy document
                provided
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td style={{ width: '100%', position: 'relative' }}>
            <img
              src={imagesAll.signatureRSVR}
              alt="Company Logo"
              className="signature"
              style={{
                position: 'absolute',
                width: '100px',
                height: 'auto',
                bottom: 25,
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            />
            <div style={{ width: '30mm', margin: 'auto', marginBottom: 15 }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                <div
                  style={{
                    width: '100%',
                    height: 1,
                    background: 'black',
                    borderRadius: 5,
                    margin: 'auto',
                    marginTop: 80,
                  }}
                />
              </div>
              <p style={{ fontSize: 7, textAlign: 'center', fontWeight: 900 }}>Authorized Signature</p>
            </div>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default EmployeeProfileCard;
