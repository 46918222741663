/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @mui
import { Checkbox, MenuItem, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
// eslint-disable-next-line import/no-unresolved
import { useLocation, useNavigate } from 'react-router';
// eslint-disable-next-line import/no-unresolved
import moment from 'moment';
import { useSelector } from 'react-redux';
import ReviewDialog from 'src/components/dailogs/AssignDialog';
import CompleteDialog from 'src/components/dailogs/CompleteDialog';
import StartDialog from 'src/components/dailogs/StartDialog';
import TimeDailogs from 'src/components/dailogs/Timelogdailogs';
import Iconify from '../../../../components/Iconify';
import Label from '../../../../components/Label';
import { TableMoreMenu } from '../../../../components/table';

// ----------------------------------------------------------------------

TaskTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onAddSub: PropTypes.func,
  onSelectRow: PropTypes.func,
  setDeleteDialog: PropTypes.func,
  setDeleteId: PropTypes.func,
  setMultipleDeleteMode: PropTypes.func,
  teamTask: PropTypes.bool
};

export default function TaskTableRow({
  row,
  selected,
  onEditRow,
  setMultipleDeleteMode,
  onSelectRow,
  setDeleteDialog,
  setDeleteId,
  onAddSub,
  teamTask,
  getTasks,
  addToFavourite,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { title, task_priority, project, due_date, isVerified, parent_task, spend_time_format, task_status, is_favorite, planned_time_format, is_due, created_by_user } = row;
  const { userDetails } = useSelector((state) => state.loginReducer);
  const [isLead, setIsLead] = useState(false);
  const [openMenu, setOpenMenuActions] = useState(null);
  const { pathname } = useLocation();
  const isTeamTasks = pathname.includes('team-tasks');
  const { user } = userDetails;

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
    setMultipleDeleteMode(false);
  };
  useEffect(() => {
    // const isAdmin = userDetails?.user?.roles?.some((role) => role.id === 1);
    // setIsAdmin(isAdmin);
    const isLead = userDetails?.user?.roles?.some((role) => role.id === 3);

    setIsLead(isLead);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails?.user?.roles]);

  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>
      {/* 'default', 'primary', 'secondary', 'info', 'success', 'warning', 'error' */}
      {task_priority?.id === 1 && task_status?.id !== 5 ?
        <TableCell
          align="left"
          variant="subtitle2"
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate(isTeamTasks ? `/team-tasks/details/${row?.id}` : `/tasks/details/${row?.id}`)}
          noWrap>
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={'error'}
            sx={{ textTransform: 'capitalize', height: 'auto', whiteSpace: 'normal', lineHeight: '1.5' }}
          >
            {title}
          </Label>
        </TableCell>
        : is_due ? <TableCell
          align="left"
          variant="subtitle2"
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate(isTeamTasks ? `/team-tasks/details/${row?.id}` : `/tasks/details/${row?.id}`)}
          noWrap>
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={'primary'}
            sx={{ textTransform: 'capitalize', height: 'auto', whiteSpace: 'normal', lineHeight: '1.5' }}
          >
            {title}
          </Label>
        </TableCell> : <TableCell
          align="left"
          variant="subtitle2"
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate(isTeamTasks ? `/team-tasks/details/${row?.id}` : `/tasks/details/${row?.id}`)}
          noWrap>
          {title}
        </TableCell>}

      {user?.roles[0]?.id !== 1 ? <TableCell align="left">{project?.title}</TableCell> : null}

      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {moment(due_date).format("D MMM")}
      </TableCell>

      <TableCell align="center">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={task_priority?.color}
          sx={{ textTransform: 'capitalize' }}
        >
          {task_priority?.name}
        </Label>
      </TableCell>

      {task_status?.id === 4 ? <TableCell align="left">
        <Tooltip title={row?.reviewer?.full_name}>

          <Typography
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={task_status?.color}
            sx={{ textTransform: 'capitalize' }}
          >
            {/* {task_status?.name} */}
            <Label
              variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
              color={task_status?.color}
              sx={{ textTransform: 'capitalize' }}
            >
              {task_status?.name}
            </Label>
          </Typography>
        </Tooltip>
      </TableCell> :
        <TableCell align="left">
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={task_status?.color}
            sx={{ textTransform: 'capitalize' }}
          >
            {task_status?.name}
          </Label>
        </TableCell>
      }
      {row?.task_users.length > 0 ? (
        <TableCell align="left">{`${row?.task_users[0]?.user?.first_name} ${row?.task_users[0]?.user?.last_name}`}</TableCell>
      ) : (
        <TableCell>Pseudo User</TableCell>
      )}

      {user?.roles[0]?.id !== 1 ? <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {created_by_user?.full_name}
      </TableCell> : null}

      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {planned_time_format}
      </TableCell>
      {/* <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={row?.task_assigned[0]?.assigned_status?.color}
          sx={{ textTransform: 'capitalize' }}
        >
          {row?.task_assigned[0]?.assigned_status?.name}
        </Label></TableCell> */}

      {/* {(row?.parent_task_id !== null && row?.parent_task_id !== 0) ? (
        <TableCell
          align="left"
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate(`/tasks/details/${row?.parent_task_id}`)}
          noWrap
        >
          {parent_task?.title}
        </TableCell>
      ) : (
        <TableCell />
      )} */}
      <TableCell align='left'>{spend_time_format}</TableCell>

      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  // onDeleteRow();
                  setDeleteDialog(true);
                  setDeleteId(row.id);
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                Delete
              </MenuItem>
              {/* {row?.has_siblings === 1 && (
                <MenuItem
                  onClick={() => {
                    onAddSub();
                    handleCloseMenu();
                  }}
                >
                  <Iconify icon={'eva:plus-circle-outline'} />
                  Add sub task
                </MenuItem>
              )} */}
              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Edit
              </MenuItem>
              <MenuItem
                onClick={() => {
                  addToFavourite();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'ic:round-star-border'} />
                {is_favorite === 1 ? "Unfavourite" : "Favourite"}
              </MenuItem>

              {/* Lead can start comple and review his/her tasks, by jayesh 06/12 */}
              {isLead ?
                <>
                  {task_status?.id === 1 &&
                    <MenuItem>
                      <StartDialog task={row} getTasks={getTasks} />
                    </MenuItem>}

                  {task_status?.id === 2 &&
                    <MenuItem>
                      <ReviewDialog task={row} getTasks={getTasks} />
                    </MenuItem>}

                  {task_status?.id === 2 &&
                    <MenuItem>
                      <CompleteDialog task={row} getTasks={getTasks} />
                    </MenuItem>}
                  {/* //Finalise by rupesh sir 30/11 */}
                  {task_status?.id !== 1 && row?.has_siblings !== 1 && !teamTask && (
                    <MenuItem>
                      <TimeDailogs task={row} getTasks={getTasks} />
                    </MenuItem>
                  )}
                </>
                : null}
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
