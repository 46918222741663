import { useState } from 'react';
// @mui
import { Avatar, Box, Divider, MenuItem, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import InstallButton from 'src/components/installApp';

import { useDispatch, useSelector } from 'react-redux';
// components
import { useNavigate } from 'react-router';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/services/axiosAll';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { deleteLogin, getProject, getUser, getpriority, updateLastUpdateTime } from '../../../redux/actions/login';
import UseFullLinkPopover from './UsefulLink';
import NotificationsPopover from './NotificationsPopover';
// import InstallButton from 'src/components/installApp';


// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Profile',
    linkTo: 'profile',
  },
  // {
  //   label: 'Settings',
  //   linkTo: '/',
  // },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogOut = async () => {
    await AxiosAll('post', '/logout', {}, token);
    dispatch(deleteLogin());
    dispatch(getpriority([]));
    dispatch(getUser([]));
    dispatch(getProject([]));
    dispatch(updateLastUpdateTime(''));
    navigate('/login');
    localStorage.removeItem('user');
    // localStorage.removeItem('projectList');
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar
          src={`${userDetails?.user?.profile_picture}`}
          alt={`${userDetails?.user?.first_name} ${userDetails?.user?.last_name}`}
        />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {`${userDetails?.user?.first_name} ${userDetails?.user?.last_name}`}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {`${userDetails?.user?.email}`}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              onClick={() => {
                navigate(`/${option.linkTo}`);
                handleClose();
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem>
          <UseFullLinkPopover />
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem>
          <NotificationsPopover />
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem>
          <InstallButton />
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem sx={{ m: 1 }} onClick={handleLogOut}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
